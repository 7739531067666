import React, { Component, Fragment } from 'react'
import { Paper, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalGetService } from '../../utils/globalApiServices';
import { checkApiStatus } from '../../utils'
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios'

class CountryStateSelector extends Component{
  constructor(props){
    super(props)
    this.state = {
      countries: [],
      states: [],
      stateFlag:false,
      city:''
    }
  }

  getCountriesList = () =>{
    globalGetService('/console/country-list/')
    .then(response => {
      if(checkApiStatus(response)){
        this.setState({
          countries: response.data.data
        })
        if(this.props.regionDetails && Object.keys(this.props.regionDetails).length && response.data.data.filter(country => country.alpha_code_two === this.props.regionDetails.country_iso_2).length){
          this.getStatesList(response.data.data.filter(country => country.alpha_code_two === this.props.regionDetails.country_iso_2)[0], 'componentDidMount')
          this.setState({city: this.props.regionDetails.city_name ? this.props.regionDetails.city_name : ''})
        }
      }
    })
  }

  getStatesList = (country, callType) =>{
    this.setState({city: ''})
    if(country && country.is_state_needed){
      globalGetService(`/console/state-list/${country.id}/`)
      .then(response => {
        if(checkApiStatus(response)){
          this.setState({
            states:response.data.data,
            stateFlag:true
          })
        }
      })
      this.props.updateLocationWithoutGoogle('country', country, true)
      setTimeout(() => {
        if(callType !== 'componentDidMount'){
          this.props.updateLocationWithoutGoogle('city', {}, true)
        }
      })
    }else {
      this.props.updateLocationWithoutGoogle('country', country, false)
      setTimeout(() => {
        if(callType !== 'componentDidMount'){
          this.props.updateLocationWithoutGoogle('city', {}, false)
        }
      })
      this.setState({
        stateFlag:false
      })
    }
    this.setState({states: []})
  }
  updateStates = (state) => {
    this.props.updateLocationWithoutGoogle('state', state, true)
  }

  componentDidMount(){
    this.getCountriesList()
  }

  render(){
    const { error, updateRegionErrorField, updateErrorField, regionDetails } = this.props
    const { countries, states, stateFlag, city } = this.state
    return(
      <Paper className="mr-initiate-card">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h4 className="mr-initiate-card-title" style={{color:'#bd10e0'}}><strong>Region of Operation</strong></h4>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              id="aircraftType"
              options={countries}
              value={regionDetails ? {name: regionDetails.country_name} : ''}
              getOptionLabel={(option) => option.name}
              onChange={(...args) => this.getStatesList(args[1])}
              renderInput={(params) => <TextField {...params} required label="Country" placeholder="Select Country" margin="normal" fullWidth InputLabelProps={{shrink: true}} error={ (error && error.country) ? true : false} helperText={error && error.country ? error.country : '' } onFocus={() =>  {updateRegionErrorField('country', ''); updateErrorField('regionOfOperation', '')}}  />}
            />
          </Grid>
          {
            this.state.states.length ?
              <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                  id="aircraftType"
                  options={this.state.states}
                  getOptionLabel={(option) => option.name}
                  value={regionDetails ? {name: regionDetails.state_name} : ''}
                  onChange={(...args) => this.updateStates(args[1])}
                  renderInput={(params) => <TextField {...params} required label="State / Province" placeholder="Select State" margin="normal" fullWidth InputLabelProps={{shrink: true}} error={error && error.state ? true : false} helperText={error && error.state ? error.state : ""} onFocus={() => updateRegionErrorField('state', '')} /> }
                />
              </Grid>
            :null
          }
              <Grid item xs={12} sm={6} md={4}>
                <ul className="list-inline">
                  <li className="list-inline-item" style={{width: '83%'}}>
                    <TextField
                      label="City/Airport"
                      placeholder="Enter City or Airport"
                      margin="normal" fullWidth
                      value={city}
                      onChange={(e) =>  {this.setState((prevState => ({ ...prevState, city:e.target.value})));this.props.updateLocationWithoutGoogle('city', {city_name: e.target.value}, stateFlag)}}
                      helperText={<div>
                      </div>
                    }
                    />
                  </li>



                </ul>
              </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default CountryStateSelector
