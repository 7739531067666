import React, { Fragment } from 'react';
import config from '../../config';
import { Link } from "@material-ui/core" ;
import { getLocalStorageInfo } from '../../utils';
export default function MainNavLink(){
  let baseUrl = config.api.appUrl + '/';
  let is_saas = false
  const userDetail = JSON.parse(localStorage.getItem('userInfo'));
  Object.keys(userDetail.user.permission).map(permission => {
      if(userDetail.user.permission[permission].is_saas == true){
        is_saas = true
      }
      return permission
    })
    if(getLocalStorageInfo()) {
      return(
        <Fragment>
          <ul className="list-unstyled" style={{marginTop:'20px', marginBottom:'20px'}}>
            <li>
              <a href={`${baseUrl}`} target="_blank">
                <img src={`${config.api.s3ImageUrl}Home_Icon.svg`} alt="Home"/>
                <p>Home</p>
              </a>
            </li>
            <li>
              <a href={`${baseUrl}assets-listing`} target="_blank">
                <img src={`${config.api.s3ImageUrl}asset_list_icon.png`} alt="Assets" />
                <p>Assets</p>
              </a>
            </li>
          </ul>

          <ul className="list-unstyled">
            {
              getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.projects ?
              <li>
                <a href={`${baseUrl}technical/projects?project_status=all`} target="_blank">
                  <img src={`${config.api.s3ImageUrl}phase-2/technical_projects.png`} alt="Projects"/>
                  <p>Projects</p>
                </a>
              </li> : null
            }
            {
              (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && (getLocalStorageInfo().user.permission.technical.project_tracker.length ==1 && getLocalStorageInfo().user.permission.technical.project_tracker.includes('U')?false:true)) ?
              <li>
                <a href={`${baseUrl}technical/projects/tracker`} target="_blank">
                  <img src={`${config.api.s3ImageUrl}phase-2/project_tracker.png`} alt="tracker"/>
                  <p>Trackers</p>
                </a>
              </li> : null
            }
            {
              (getLocalStorageInfo().user.permission.program_planner && getLocalStorageInfo().user.permission.program_planner.dashboard) ?
              <li>
                <a href={`${baseUrl}technical/program-planner`} target="_blank">
                  <img src={`${config.api.s3ImageUrl}Bitmap.png`} alt="program_planner"/>
                  <p>Program Planner</p>
                </a>
              </li> : null
            }

              <li className="active" style={{background: '#3f4b88', margin: '3px', borderRadius: '2px'}}>
                <Link href="/mr-calculator/initiate">
                  <img src={`${config.api.s3ImageUrl}mr_cal_icon.png`} alt="MR Calculator" />
                  <p>MR Calculator</p>
                </Link>
              </li>
            {
              getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type != 3 ?
              <li>
                <Link href={`${baseUrl}fleet-cashflow/portfolios/`} target="_blank">
                  <img src={`${config.api.s3ImageUrl}fleet_cashflow_icon.png`} alt="Fleet Cashflow" />
                  <p>Fleet Cashflow</p>
                </Link>
              </li>: null
            }
            {
               getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type != 3 ?
               <li>
                 <Link href={`${baseUrl}export/reports/`} target="_blank">
                   <img src={`${config.api.s3ImageUrl}bulk_report-icon.svg`} alt="Reports" />
                   <p>Reports</p>
                 </Link>
               </li> : null
            }
            {
              getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type != 3 ?
              <li>
                <Link href={`${baseUrl}analytics/`} target="_blank" >
                  <img src={`${config.api.s3ImageUrl}tableau_analytics.svg`} alt="analytics" />
                  <p>Analytics</p>
                  <sup style={{color: '#ff0000', fontSize: '10px', fontFamily: 'Conv_IBMPlexSans-Bold', padding: '0 2px'}}>BETA</sup>
                </Link>
              </li> : null

            }
            {
              getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user ?
              <li>
                <Link href={`${baseUrl}users/manage/active`} target="_blank">
                  <img src={`${config.api.s3ImageUrl}phase-2/users.png`} alt="users" />
                  <p>Users</p>
                </Link>
              </li> : null
            }
            {
              getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.storage ?
              <li>
                <Link href={`${baseUrl}storage-listing`} target="_blank">
                  <img src={`${config.api.s3ImageUrl}phase-2/storage.png`} alt="storage" />
                  <p>Storage</p>
                </Link>
              </li> : null
            }
            {
              is_saas && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.license ?
              <li>
                <Link href={`${baseUrl}licenses/user`} target="_blank">
                  <img src={`${config.api.s3ImageUrl}phase-2/licenses.png`} alt="licenses" />
                  <p>Licenses</p>
                </Link>
              </li> : null
            }
            {
              is_saas && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.billing ?
              <li>
                <Link href={`${baseUrl}billing/invoices`} target="_blank">
                  <img src={`${config.api.s3ImageUrl}phase-2/billing.png`} alt="billing"/>
                  <p>Billing</p>
                </Link>
              </li> : null
            }
            {
              getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.lessor_configuration ?
              <li>
                <Link href={`${baseUrl}configure`} target="_blank">
                  <img src={`${config.api.s3ImageUrl}settings.png`} alt="configure" />
                  <p>Configure</p>
                </Link>
              </li> : null
            }
          </ul>
          </Fragment>
      )
    }else {
      return null
    }

}
