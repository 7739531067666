import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getLocalStorageInfo } from '../../../utils';
import config from '../../../config';
const LicenseFailModal = ({history, match, licenseDesc, licenseModal, toggleModalFn}) => {
  return(
    <Dialog
      open={licenseModal}
      aria-labelledby="scroll-dialog-title"
      onClose={toggleModalFn}
      classes={{paperScrollPaper: "license-modal"}}
    >
      <DialogTitle id="scroll-dialog-title" classes={{root:"license-title"}} style={{position: 'relative'}} >
        License Expired
      </DialogTitle>
      <CloseIcon onClick={toggleModalFn} style= {{position: 'absolute', right: '20px', top: '20px', cursor: 'pointer', fontSize: '30px'}}/>
      <DialogContent dividers={true}>
        <div className="licence-description">
          <p align="center">{licenseDesc}</p>
        </div>
          <div>
          { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && (getLocalStorageInfo().user.permission.console.cart == undefined || getLocalStorageInfo().user.permission.console.cart == null || getLocalStorageInfo().user.permission.console.cart.indexOf('R') == -1) ?
            <Fragment>
              <Button color="primary" variant='contained'>Buy Now</Button>
              <p>Please contact Lessor Administrator to Buy License</p>
            </Fragment>:
            <span align="center" className="licence-action">
              <Button color="primary" variant='contained'><Link className="licence-action-link" href={`${config.api.appUrl}/licenses/mr-calculator`} target="_blank">Buy Now</Link></Button>
            </span>
          }
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default withRouter(LicenseFailModal);
