import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { AuthHeader, Footer } from '../components';
const TermsConditions = () => {
  return(
    <section className="termsHd">
      <div className="auth-section">
        <AuthHeader />
        <Container maxWidth="lg">
          <Grid container spacing={0} alignItems="center">
            <Grid item md={6}>
              <div className="auth-help-text">
                <h1>Terms and Conditions</h1>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="termsAndCondition">
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12}>
              <h2 className="intro-heading">Introduction</h2>
              <p>Welcome to SPARTA, herein after referred to as SPARTA.<br/><br/> These terms of use govern your use of Acumen’s SPARTA and by using Acumen’s SPARTA, you accept these terms of use in full. If you disagree with these terms of use or any part of these terms of use, please do not use Acumen’s SPARTA.</p>
              <h2>License To Use SPARTA</h2>
              <p>Unless otherwise stated, Acumen Aviation group or its licensors, owns the intellectual property rights in the SPARTA application and material on the SPARTA application. All these intellectual property rights are reserved. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>
              <h2>Acceptable Use</h2>
              <p>You must not use Acumen’s SPARTA in any way that causes, or may cause, damage to the SPARTA or impairment of the availability or accessibility of the SPARTA; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.<br/><br/>You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to Acumen’s SPARTA without Acumen Aviation express written consent.</p>
              <br/>
              <p>You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to Acumen’s SPARTA application without Acumen Aviation express written consent.</p>
              <h2>Data Auditing and Censorship</h2>
              <p>It is important to note that SPARTA is a professional system, created specifically for Aviation Asset Management activities. The professional intent of the system necessitates that the data or information being uploaded into the system is of professional nature and will also be subjected to applicable checks and balances. The user and/or license owner(s) are therefore fully responsible to ensure that the data or information being uploaded is having only professional content. If any illicit content (including but not limited to pornographic or explicit content, ransomware, malware, viruses and personal content such as photos, videos or other such media content) is detected in the system, Acumen Aviation reserves complete rights to remove such content from the system or deactivate the user account without any refund of license fees or apply both remedies. Such remedies may be implemented without any requirement of notification to the user and/or license owner(s) and will be solely at the discretion of Acumen Aviation.</p>
              <h2>Restricted Access</h2>
              <p>Access to certain areas of Acumen’s SPARTA is restricted. Acumen Aviation reserves the right to restrict access to other areas of Acumen’s SPARTA, or the whole SPARTA, at the discretion of Acumen Aviation.</p>
              <br/>
              <p>
              If Acumen Aviation provides you with a user ID and password to enable you to access the restricted areas of Acumen’s SPARTA, you must ensure that the user ID and password is kept confidential.
              </p>
              <br/>
              <p>
              You must notify Acumen Aviation in writing immediately if you become aware of any unauthorised use of your account or password.
              </p>
              <br/>
              <p>
              You are responsible for any activity on Acumen’s SPARTA arising out of any failure to keep your password confidential, and may be held liable for any losses arising out of such a failure.
              </p>
              <br/>
              <p>
              You must not use any other person's user ID and password to access Acumen’s SPARTA, unless you have that person's express permission to do so.
              </p>
              <br/>
              <p>
              Acumen Aviation may disable your user ID and password at its sole discretion without notice or explanation.
              </p>
              <h2>Transaction Information</h2>
              <p>All the payments are processed through our third-party payment processor- STRIPE Payment Gateway, which will have access to your financial information submitted by you to complete the transaction. You will be subject to their additional <a style={{color:'#2F8CFE'}} href="https://stripe.com/privacy" rel="noreferrer" target="_blank">terms</a> when making any transaction.</p>

              <h2>Non-Refundable</h2>
              <p>Payment towards license to use a SPARTA Module purchased are NON-CANCELLABLE and the payments made are NON-REFUNDABLE. The license purchased cannot be downgraded during the Subscription term.</p>
              <br/>
              <p>Users or Customers are encouraged to fully evaluate the SPARTA prior to purchasing a licence to any module of SPARTA.</p>
              <br/>
              <p>Acumen Aviation offers you a free trial period (“Free Plan”), during which you can try out the SPARTA to a certain usage limit as defined for the Free Plan by Acumen Aviation. The Free Plan will be available till the time you wish or till you place an order to purchase the license. However, if your account remains inactive for a period of 120 days or more, we reserve the right to de-activate your account, without any liability to you.</p>

              <h2>Fee</h2>
              <p>As an express condition of your use and subscription to the paid SPARTA modules, you agree to pay all the fee applicable to your subscription and other fee for additional SPARTA module’s that you may purchase, and any applicable taxes in connection with your use of the paid subscription. </p>

              <h2>Support</h2>
              <p>During the paid Subscription period and provided you are in compliance with all the terms of usage of the SPARTA, Acumen Aviation will render support in connection with the concerned module of SPARTA for issues reported by you via e-mail at <a style={{color:'#2F8CFE'}} href="mailto:support@sparta.aero" rel="noreferrer" target="_blank">support@sparta.aero</a>.</p>

              <h2>Personal Information and Privacy</h2>
              <p>Personal Information that you provide for SPARTA is governed by Acumen Aviation’s ‘Data Privacy Policy’. Your use of the SPARTA indicates your acceptance of the Acumen Aviation’s ‘Data Privacy policy’.</p>

              <h2>Limited Warranties</h2>
              <p>Acumen Aviation do not warrant the completeness or accuracy of the information published on this SPARTA; nor commit to ensuring that the SPARTA remains available or that the material on the SPARTA is kept up-to-date.</p>
              <br/>
              <p>To the maximum extent permitted by applicable law Acumen Aviation excludes all representations, warranties and conditions relating to this SPARTA and the use of this SPARTA (including, without limitation, any warranties implied by law of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).</p>

              <h2>Confidentiality</h2>
              <p>The SPARTA contains information that is commercially sensitive and proprietary. By accessing the Portal, each user agrees to maintain the confidentiality of such information.</p>


              <h2>Indemnity</h2>
              <p>You hereby, undertake to keep Acumen Aviation, its officers, directors, employees, suppliers and affiliates indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by Acumen Aviation to a third party in settlement of a claim or dispute on the advice of the legal advisers) incurred or suffered by Acumen Aviation arising out of any breach by you of any provision of these terms of use or arising out of any claim that you have breached any provision of these terms of use.</p>

              <h2>Breaches of These Terms of Use</h2>
              <p>Without prejudice to Acumen Aviation’s other rights under these terms of use, if you breach these terms of use in any way, Acumen Aviation may take such action as it deems appropriate to deal with the breach, including suspending or terminating your access to the SPARTA (including any subscription available), prohibiting you from accessing the SPARTA, blocking computers using your IP address from accessing the SPARTA, contacting your internet service provider to request that they block your access to the SPARTA, without any refund of fee, and/or bringing court proceedings against you.</p>
              <br/>
              <p>In the event that your account is terminated or cancelled or expires or de-activated, except as otherwise provided by applicable law, you will no longer be able to access any of your account information, the information that you upload or receive through your use of the SPARTA or other personal data that we hold on our records.</p>

              <h2>Variation</h2>
              <p>Acumen Aviation may revise these terms of use from time-to-time. Revised terms of use will apply to the use of Acumen’s SPARTA from the date of the publication of the revised terms of use. Please check regularly to ensure you are familiar with the current version.</p>

              <h2>Assignment</h2>
              <p>Acumen Aviation may transfer, sub-contract or otherwise deal with the rights and/or obligations under these terms of use without notifying you or obtaining your consent.</p>
              <br/>
              <p>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms of use.</p>

              <h2>Severability</h2>
              <p>If a provision of these terms of use is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>

              <h2>Exclusion of Third Party Rights</h2>
              <p>These terms of use are for the benefit of Acumen Aviation and you, and are not intended to benefit any third party or be enforceable by any third party. The exercise of Acumen Aviation’s rights and your rights in relation to these terms of use is not subject to the consent of any third party.</p>


              <h2>Limitation and Exclusions of Liability</h2>
              <p>Nothing in these terms of use will: (a) limit or exclude Acumen Aviation’s liability or your liability for fraud or fraudulent misrepresentation; (b) limit any of Acumen Aviation’s liability or your liabilities in any way that is not permitted under applicable law; or (d) exclude any of Acumen Aviation’s liability or your liabilities that may not be excluded under applicable law.</p>
              <br/>
              <p>Acumen Aviation will not be liable to you in respect of any losses arising out of any event or events beyond its reasonable control.</p>
              <br/>
              <p>You agree that Acumen Aviation or its affiliates, in no event shall be liable (except for the above-mentioned in this Section), whether in contract, tort (including negligence) or otherwise, for any indirect, incidental, consequential or punitive damages (including without limitation, lost savings, profit or business interruption, business opportunity or lost data, even if notified in advance of such possibility) arising out of or pertaining to the use of SPARTA.</p>
              <br/>
              <p>Also, in agreeing to these terms and conditions enlisted in this agreement in its entirety, the user consents Acumen to use and / or reuse the technical and commercial data for generating general trends and reports. In turn, Acumen Aviation and its affiliates agree to abide by the data protection and privacy laws as applicable and warrants that that no user specific technical and commercial data will be disclosed in SPARTA.</p>

              <h2>Entire Agreement</h2>
              <p>These terms of use, together with Acumen Aviation’s privacy policy, constitute the entire agreement between you and Acumen Aviation in relation to your use of Acumen’s SPARTA, and supersede all previous agreements in respect of your use of this SPARTA.</p>

              <h2>Law and Jurisdiction</h2>
              <p>These terms of use will be governed by and construed in accordance with the Irish Laws and any disputes relating to these terms of use will be subject to the exclusive jurisdiction of the courts of Dublin, Ireland</p>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </section>
  )
}
export default TermsConditions
