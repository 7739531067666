import { regexConstants, errorMsg1 } from '../../constants/regEx';
export const errorCode = {
    company_name:{
        0:'',
        1: 'Please enter Company Name',
        4: errorMsg1
      },
      company_nameObj:{
        required: true,
        regexPattern: regexConstants.alphanumericWithHyphenSpace
      },
      company_domain:{
        0:'',
        1: 'Please enter Website',
        4: errorMsg1
      },
      company_domainObj: {
        required: true,
      },
      email:{
        0:'',
        1: 'Please enter Email',
        4: 'Please enter Email in correct format'
      },
      emailObj: {
        required: true,
        regexPattern: regexConstants.email
      },
      full_name:{
        0:'',
        1: 'Please enter Full Name',
        4: errorMsg1
      },
      full_nameObj: {
        required: true,
        regexPattern: regexConstants.alphanumericWithHyphenSpace
      },
      password:{
        0:'',
        1: 'Please enter Password',
        4: 'Password does not meet the policy requirements'
      },
      passwordObj: {
        required: true,
        regexPattern: regexConstants.password
      },
      confirm_password:{
        0:'',
        1: 'Please enter Confirm Password',
      },
      confirm_passwordObj: {
        required: true,
        regexPattern: regexConstants.password
      },
      contact_no:{
        0:'',
        4: 'Please enter valid Mobile Number'
      },
      contact_noObj: {
        regexPattern: regexConstants.phoneNumber
      },

}
export const dummy = {
	"refresh": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTYwOTQ5NDQ0MSwianRpIjoiOWJkMjUwN2U3ZDljNGVmZjkzYjdiZThkMWQ1Zjk4MmEiLCJ1c2VyX2lkIjoxfQ.--_6t3VPekLZjIwR80N619pRKNoBYCUOgEoKUvlBEXU",
	"access": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjA4ODg5NjQxLCJqdGkiOiIxZDViZWE1NzI3ZWQ0N2MzOTY5MmNiYmIxYzYxMmM0NiIsInVzZXJfaWQiOjF9.B6UUunYl7VMQFbTeE9a9SqosBFjTqwIbjEjt0R-kvbE$$itZfxl",
	"user": {
		"id": 1,
		"name": "Murugan Lessor D",
		"slug": "LgHvZf",
		"designation": "Admin",
		"profile_pic": "https://ams3.digitaloceanspaces.com/aims-dev/media-dev/users/murugan1-284x3001579880907.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=4MFZU7TBCN3FWTR7ZZLJ%2F20201222%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20201222T094731Z&X-Amz-Expires=259200&X-Amz-SignedHeaders=host&X-Amz-Signature=e75c48e029a92b983968ac68b79748c0aedf16954e5e4014c699036fefc59a9a",
		"default_dashboard_url": null,
		"accept_old_dashboard": true,
		"type": {
			"label": "Acumen",
			"value": 1
		},
		"permission": {
			"contracts": {
				"is_saas": false,
				"dashboard": ["R"],
				"parties": ["R", "C", "U", "D"],
				"cash_flow": ["R", "C", "U", "D"],
				"delivery_conditions": ["C", "R", "D", "U"],
				"subleases": ["U", "C", "R", "D"],
				"mr_accruals": ["C", "R", "U", "D"],
				"contract_history": ["C", "R", "U", "D", "A"],
				"security_deposit": ["C", "R", "U", "D"],
				"headlines": ["R", "C", "U", "D"],
				"working_group": ["R", "C", "U", "D"],
				"invoices": ["R", "C", "U", "D"],
				"insurance": ["R", "C", "U", "D"],
				"eol_options": ["R", "C", "U", "D"],
				"induction_conditions": ["R", "C", "U", "D"],
				"return_conditions": ["R", "C", "U", "D"],
				"data_extract": ["C", "R", "U", "D"],
				"utilization": ["R", "C", "U", "D"],
				"half_life_adjustment": ["R", "C", "U", "D"],
				"mr_rates": ["C", "U", "D", "R", "BNK"],
				"lease_rental_rate": ["C", "U", "D", "R", "BNK"],
				"qualified_maintenance_event": ["C", "U", "D", "R"],
				"cape_town_convention": ["C", "R", "U", "D"],
				"obligations": ["C", "R", "U", "D"]
			},
			"maintenance": {
				"is_saas": false,
				"claims": ["R", "C", "U", "D"],
				"engines": ["R", "C", "U", "D"],
				"airframe": ["R", "C", "U", "D"],
				"dashboard": ["R", "C", "U", "D"],
				"apu": ["R", "C", "U", "D"],
				"landing_gears": ["R", "C", "U", "D"]
			},
			"fleet_cash_flow": {
				"is_saas": false,
				"dashboard": ["R"]
			},
			"mr_calculator": {
				"is_saas": false,
				"dashboard": ["R"],
				"analyze_mr_cash_flow": ["C", "R", "U", "D"]
			},
			"technical_specs": {
				"is_saas": false,
				"apu": ["C", "U", "D", "R"],
				"overview": ["C", "U", "D", "R"],
				"engine": ["C", "U", "D", "R"],
				"landing_gears": ["C", "U", "D", "R"],
				"avionics": ["C", "U", "D", "R"],
				"interior": ["C", "U", "D", "R"],
				"asset": ["D", "TFR"]
			},
			"records": {
				"is_saas": false,
				"dashboard": ["R"],
				"data_room": ["SH", "R", "DN", "D", "M", "UP", "CAT", "C", "INS_R", "DC_R", "MA_R", "CT_R", "TE_R", "SHU", "MPR", "MPC", "MPD"],
				"action_dock": ["REN", "R", "OCR", "P", "RES", "REJ", "OCRTAB", "RENTAB", "REJTAB", "NPWC", "D", "INQTAB"],
				"resources": ["DN", "R"],
				"report": ["EXP", "R"],
				"recycle_bin": ["RES", "R", "REJ"]
			},
			"console": {
				"is_saas": false,
				"manage_user": ["C", "R", "U", "D"],
				"user_invite": ["C", "R", "U", "D"],
				"cart": ["C", "R", "U", "D"],
				"license": ["R"],
				"billing": ["R"],
				"payment": ["R"],
				"lessor_configuration": ["R"],
				"storage": ["R"],
				"dashboard": ["R"]
			},
			"technical": {
				"is_saas": false,
				"projects": ["C", "R", "U", "D"],
				"work_orders": ["C", "R", "U", "D"],
				"project_lead": ["AD", "AS", "RM"],
				"project_engineer": ["AD", "AS", "RM"],
				"third_party": ["AD", "AS", "RM"],
				"project_tracker": ["R"],
				"t011_import": ["IMP"]
			},
			"ad_validation": {
				"is_saas": false,
				"ad_validator": ["R"]
			},
			"program_planner": {
				"is_saas": false,
				"dashboard": ["C", "R", "U", "D"]
			},
			"auto_bulk_organizer": {
				"is_saas": false,
				"auto_bulk_organizer": ["C", "R", "U", "D"]
			},
			"reports": {
				"is_saas": false,
				"bulk_download": ["D"],
				"custom_reports": ["DN"]
			},
			"news_feed": {
				"is_saas": false,
				"dashboard": ["R"]
			}
		},
		"security_groups": [],
		"is_root_user": true,
		"is_super_admin": false,
		"user_role": ""
	},
	"defaultLessor": {
		"id": 13,
		"logo": "https://ams3.digitaloceanspaces.com/aims-dev/media-dev/lessors/test_lessor.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=4MFZU7TBCN3FWTR7ZZLJ%2F20201222%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20201222T094732Z&X-Amz-Expires=259200&X-Amz-SignedHeaders=host&X-Amz-Signature=293758aa5d47a56a874fc2e172e14be5e68406a4c85d47e0b3b3a6ae877cc76f",
		"name": "Test Lessor",
		"slug": "itZfxl",
		"contact_address": "test 1",
		"country": "India",
		"company_domain": "https://designstringtest.com",
		"is_active": true,
	}
}
