import React from 'react'
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps"
const Map = withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={props.address === '' ? 1 : 8}
    defaultCenter={props.location}
    onClick={(map) => props.getAddressFromLatLng(map)}
  >
  {
    props.showMarker && props.address.trim() !== '' ?
      <Marker position={props.location} >
      </Marker>
    :null
  }
  </GoogleMap>
)

export default Map
