import React, {  Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { getLocalStorageInfo, getGlobalCookie, setGlobalCookie } from '../utils';
import { isMobile } from 'react-device-detect';
import { trackActivity } from '../utils/mixpanel'
import  PageLoader  from '../shared_elements/components/PageLoader'
export const lockImgPath = 'https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/permissions/';
export default function UserRoleAccess(HocComponent, extraProps=[]){
  return class extends Component{
    constructor(props){
      super(props)
      this.state = {
        videoDialog: false
      }
    }

    componentDidMount(){
      if(getGlobalCookie('MRCalcVideo') === undefined || getGlobalCookie('MRCalcVideo') === null){
        this.setState({videoDialog: true})
      }
    }

    render(){
      if(getLocalStorageInfo()){
        let permission = getLocalStorageInfo().user.permission;
        let baseUrl = lockImgPath +extraProps[0]+ '_' +extraProps[1]+'.jpg';
        if(permission[extraProps[0]] && permission[extraProps[0]][extraProps[1]] && permission[extraProps[0]][extraProps[1]].indexOf(extraProps[2]) != -1){
          return(
            <React.Fragment>
              <HocComponent {...this.props} />
              <Dialog
                open={this.state.videoDialog}
                onClose={() => this.setState({videoDialog: false}, () => setGlobalCookie('MRCalcVideo', true, 730))}
                aria-labelledby = "scroll-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>Introduction</DialogTitle>
                <DialogContent style={{padding:'8px 10px'}}>
                  <video id="promoVideo" style={{width: '100%'}} src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/videos/mr_calculator.mp4" autoPlay={true} muted controls/>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" variant="contained"  onClick={() => this.setState({videoDialog: false}, () => setGlobalCookie('MRCalcVideo', true, 730))}>Okay</Button>
                </DialogActions>
                </Dialog>
            </React.Fragment>
          )
        }else{
          trackActivity('MR Permission Denied')
          return(
            <div>
                <span className="warning-info-text" style={{textAlign: 'center', marginBottom: '20px', width: '100%', fontSize: '16px'}}>You don't have access to MR Calculator. Please contact <a href="mailto:support@sparta.aero">support@sparta.aero</a> for access.</span>
                <video style={isMobile ? {width: '100%'} : {width: '100%', height: window.innerHeight - 180 + 'px'}} src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/videos/mr_calculator.mp4" controls/>
            </div>
          )
        }
      }else{
        return <PageLoader />
      }

    }
  }
}
