import React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { imgStoragePath } from '../../../constants';
import {  removeDuplicates } from '../../../utils';
require('highcharts/modules/exporting')(Highcharts);
const SummaryGraph = ({simulationType, financeRptInfo, simulateFinanceRptInfo}) => {
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  function customToolTip(points){
    if(points.length === 1){
      return '<b>Date:</b> ' + moment(points[0].x).format('MMM YYYY')+'<br/><b>Fund</b>: $'+points[0].y.toFixed(2)
    }else{
      let tlTip = '';
      points.map(point => {
        tlTip += '<b>' + point.series.name + '</b>:<br/>';
        tlTip += '<b>Date</b>: ' + moment(point.x).format('MMM YYYY') + '<br/>';
        tlTip += '<br/><b>Fund</b>: $' + point.y.toFixed(2) + '<br/>'
      });
      return tlTip
    }
  }
  function getSummeryGrahp(obj, category, flag){
    let graphData = [];
    Object.keys(obj).map(summeryType => {
      if(summeryType === 'airframe'){
        Object.keys(obj[summeryType]).map(airframe => {
          if(flag){
            Object.keys(obj[summeryType][airframe].events).map(event => {
              eventFlags.push({type:'airframe', eventDate: moment(obj[summeryType][airframe].events[event].eventDate).format('YYYYMM')})
            });
          }
          Object.keys(obj[summeryType][airframe].plots).map(year => {
            if(flag){
              graphData.push(year);
            }else{
              const arrayIndex = category.indexOf(year);
              graphData[arrayIndex] = (graphData[arrayIndex] ? graphData[arrayIndex] :0 ) + obj[summeryType][airframe].plots[year];
            }
          });
        });
      }
      if(summeryType === 'engines'){
        let engineData = obj[summeryType].engine_data ? obj[summeryType].engine_data:[];
        Object.keys(engineData).map(engine => {
          if(flag){
            Object.keys(engineData[engine].shop_visits).map(sv => {
              eventFlags.push({type:'engines', eventDate: moment(engineData[engine].shop_visits[sv][0].eventDate).format('YYYYMM')})
            });
          }
          Object.keys(engineData[engine].plots).map(year => {
            if(flag){
              graphData.push(year);
            }else{
              const arrayIndex = category.indexOf(year);
              graphData[arrayIndex] = (graphData[arrayIndex] ? graphData[arrayIndex]:0) + engineData[engine].plots[year];
            }
          });
        });
      }
      if(summeryType === 'apu'){
        if(flag){
          Object.keys(obj[summeryType].events).map(event => {
            eventFlags.push({type:'apu', eventDate: moment(obj[summeryType].events[event].eventDate).format('YYYYMM')})
          });
        }
        Object.keys(obj[summeryType].plots).map(year => {
          if(flag){
            graphData.push(year);
          }else{
            const arrayIndex = category.indexOf(year);
            graphData[arrayIndex] = (graphData[arrayIndex] ? graphData[arrayIndex]:0) + obj[summeryType].plots[year];
          }
        });
      }
      if(summeryType === 'landing_gear'){
        if(flag){
          Object.keys(obj[summeryType].events).map(event => {
            eventFlags.push({type:'landing_gear', eventDate: moment(obj[summeryType].events[event].eventDate).format('YYYYMM')})
          });
        }
        Object.keys(obj[summeryType].plots).map(year => {
          if(flag){
            graphData.push(year);
          }else{
            const arrayIndex = category.indexOf(year);
            graphData[arrayIndex] = (graphData[arrayIndex] ? graphData[arrayIndex]:0) + obj[summeryType].plots[year];
          }
        });
      }
    });
    return graphData;
  }
  let categories=[], data=[], xAxisData=[], eventFlags=[], newcategories=[], newdata = [], newxAxisData = [];
  categories = getSummeryGrahp(financeRptInfo, categories, true).filter( onlyUnique ).sort();
  data = getSummeryGrahp(financeRptInfo, categories, false);
  eventFlags = removeDuplicates(eventFlags,'eventDate');
  let eventdateArr = [...eventFlags];
  eventdateArr = eventdateArr.map(eventFlag => eventFlag.eventDate);
  categories.map((date,index) => {
    let d = {}, url =  '';
    let airframeMarker
    if(eventdateArr.indexOf(date) !== -1){
      let type = eventFlags[eventdateArr.indexOf(date)].type;
      console.log('type', type)
      switch (type) {
        case 'airframe':
          url = `url(${imgStoragePath}airframe_marker.png)`;
          break;
        case 'engines':
          url = `url(${imgStoragePath}engines_marker.png)`;
          break;
        case 'apu':
          url = `url(${imgStoragePath}mr-calculator/apu_marker.svg)`;
          break;
        case 'landing_gear':
          url = `url(${imgStoragePath}mr-calculator/lg_marker.svg)`;
          break;
        default:
          url = `url(${airframeMarker})`;
      }
      d = {x: new Date(date.slice(4,6) + '/01/' + date.slice(0,4)).getTime(), y: data[index], marker:{symbol: url, width: 30, height: 30, enabled: true}}
    }else{
      d = {x: new Date(date.slice(4,6) + '/01/' + date.slice(0,4)).getTime(), y: data[index]}
    }
    xAxisData.push(d);
  });
  if( simulationType === '3'){
    newcategories = getSummeryGrahp(simulateFinanceRptInfo, newcategories, true).filter( onlyUnique ).sort();
    newdata = getSummeryGrahp(simulateFinanceRptInfo, newcategories, false);
    newcategories.map((date,index) => {
      let d = {}, url =  '';
      let airframeMarker
      if(eventdateArr.indexOf(date) !== -1){
        let type = eventFlags[eventdateArr.indexOf(date)].type;
        console.log('type', type)
        switch (type) {
          case 'airframe':
            url = `url(${imgStoragePath}airframe_marker.png)`;
            break;
          case 'engines':
            url = `url(${imgStoragePath}engines_marker.png)`;
            break;
          case 'apu':
            url = `url(${imgStoragePath}mr-calculator/apu_marker.svg)`;
            break;
          case 'landing_gear':
            url = `url(${imgStoragePath}mr-calculator/lg_marker.svg)`;
            break;
          default:
            url = `url(${airframeMarker})`;
        }
        d = {x: new Date(date.slice(4,6) + '/01/' + date.slice(0,4)).getTime(), y: newdata[index], marker:{symbol: url, width: 30, height: 30, enabled: true}}
      }else{
        d = {x: new Date(date.slice(4,6) + '/01/' + date.slice(0,4)).getTime(), y: newdata[index]}
      }
      newxAxisData.push(d);
    });
  }
  const options = {
    chart:{
      type: 'area',
      zoomType: 'x',
      marginTop: 60,
    },
    title: { text: null },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Asset Life ( Year )'
      }
    },
    tooltip: {
      formatter: function(){
        return customToolTip(this.points)
      },
      shared: true
    },
    yAxis:{
      title: {
        text: 'Maintenance Reserve Fund `000 (USD)'
      },
      labels: {
        formatter: function () {
          return  '$' + this.value/1000;
        }
      },
      gridLineDashStyle: 'longdash'
    },
    series: [
      {
        boostThreshold: 0,
        turboThreshold:0,
        showInLegend: true,
        name: 'Calculated Fund Accruals',
        data: xAxisData,
        color:'rgba(241,202,249,1)',
        lineColor:'#BD10E0',
        style: {opacity: 0.3},
        marker: {
          fillColor: '#BD10E0'
        }
      },
      {
        boostThreshold: 1,
        turboThreshold:1000,
        showInLegend: newdata.length ? true:false,
        name: 'Simulated Fund Accruals',
        data: newxAxisData,
        color: '#BAB9D3',
        lineColor: '#150F78',
        style: {opacity: 0.5},
        marker: {
          fillColor: '#BD10E0'
        }
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          text: 'Export',
          menuItems: [
            {
              text: 'Export as PNG',
              onclick: function () {
                this.exportChart();
              }
            },
            {
              text: 'Export as SVG',
              onclick: function () {
                this.exportChart({ type: 'image/svg+xml' });
              }
            },
            {
              text: 'Export as PDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' });
              }
            }
          ]
        }
      }
    },
    credits: {
      enabled: false
    },
  }
  return(
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}
export default SummaryGraph;
