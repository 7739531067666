import React from 'react';
import { Link } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import config from '../../../config';
export default function Footer(){
  return(
    <footer>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={2} xs={6}>
            <h4>Media Center</h4>
            <ul className="list-unstyled">
              {[
                {title:'Blog', path:'https://www.acumen.aero/resources/blog/'},
                {title:'Events', path:'https://www.acumen.aero/resources/events/'},
                {title:'Webinar', path:'https://www.acumen.aero/resources/webinars/'},
                {title:'Case Studies', path:'https://www.acumen.aero/resources/case-studies/'},
                {/* {title:'Video & Podcast', path:'https://www.acumen.aero/resources/webinars/'} */}
              ].map(({title, path}, index) =>
                <li key={index}>
                  <Link href={path} className="links-footer" target="_blank">{title}</Link>
                </li>
              )}
            </ul>
          </Grid>
          <Grid item md={2} xs={6}>
            <h4>About Us</h4>
            <ul className="list-unstyled">
              {[
                {title:'Our Team', path:'https://www.acumen.aero/company/leadership/'},
                {title:'Clients', path:'https://www.acumen.aero/company/clients/'},
                {title:'Careers', path:'https://www.linkedin.com/company/sparta-aero/jobs/'},
              ].map(({title, path}, index) =>
                <li key={index}>
                  <Link href={path} className="links-footer" target="_blank">{title}</Link>
                </li>
              )}
            </ul>
          </Grid>
          <Grid item md={2} xs={6}>
            <h4>Features</h4>
            <ul className="list-unstyled">
            {[
                {title:'Contracts', path:`${config.api.appUrl}/signup#contractsFeatures`},
                {title:'Cashflow & Analysis', path:`${config.api.appUrl}/signup#cashflowAnalysis`},
                {title:'Records', path:`${config.api.appUrl}/signup#recordFeatures`},
                {title:'Technical', path:`${config.api.appUrl}/signup#techFeatures`}
              ].map(({title, path}, index) =>
                <li key={index}>
                  <Link href={path} target="_blank" className="links-footer">{title}</Link>
                </li>
              )}
            </ul>
          </Grid>
          <Grid item md={2} xs={6}>
            <h4>Others</h4>
            <ul className="list-unstyled">
            {[
                {title:'Terms & Conditions', path:'/termscondition'},
                {title:'Data Policy', path:'/datapolicy'},
                {title:'Privacy Policy', path:'https://www.acumen.aero/privacy-policy/"'},
                {title:'Security', path:'https://www.acumen.aero/privacy-policy/?security=true'}
              ].map(({title, path}, index) =>
                <li key={index}>
                  <Link href={path} target="_blank" className="links-footer">{title}</Link>
                </li>
              )}
            </ul>
          </Grid>
          <Grid item md={4} xs={12}>
            <h4>Contact with Us</h4>
            <ul className="list-inline">
              {[
                {path:'https://www.facebook.com/SPARTAaero-354530545100281/', img:'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/facebook.svg'},
                {path: 'https://www.instagram.com/sparta.aero/', img:'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/instagram.svg'},
                {path: 'https://www.linkedin.com/company/sparta-aero/', img:'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/icon_LinkedIn.svg'},
                {path: 'https://twitter.com/aero_sparta', img:'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/twitter.svg'},
              ].map(({path, img}, index) =>
                <li className="list-inline-item" key={index}>
                  <Link href={path} target="_blank" className="links-footer">
                    <img src={img} alt="" />
                  </Link>
                </li>
              )}
            </ul>
            <h4>Contact Us</h4>
            <Link href="mailto:support@sparta.aero" style={{color:'#bec0c7', textDecoration:'none'}} target="_blank">support@sparta.aero</Link>
          </Grid>
        </Grid>
      </Container>
      <div className="text-center footer-copyright">
        <Link to="">
          <img src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/acumen.png" alt="" />
        </Link>
        <p>© 2021 Acumen Aviation Europe Limited. All rights reserved. <a href="https://www.acumen.aero" target="_blank">www.acumen.aero</a></p>
      </div>
    </footer>
  )
}
