import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import MomentUtils from '@date-io/moment';
import { withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import moment from 'moment';
import { Tabs, Tab, Button, Grid, Paper, Breadcrumbs, Typography, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { TableListComp, PageLoader, DPMonthToolbar, CountryStateSelector } from '../../../shared_elements';
import { AssemblyEventHD, AssemblyEventInfo, SummaryGraph, AssemblyGraph, FundCard, EngineEventInfo, EngineEventList, EngineBasicInfo, LOICalModal, LOWGraph, MrCashflow } from '../components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel'
import { generateSimulateResultApi, mrFinanceGraphApi, mrFinanceLifeOfWingsApi, updateRegionMapsApi, graphWidgetSum } from '../apiServices';
import { engineEventHd } from '../';
class MrGraphicalReps extends Component {
  constructor(props){
    super(props);
    this.state = {
      apuErrorFlag:false,
      lgErrorFlag:false,
      airframeErrorFlag:false,
      engineErrorFlag:false,
      address:'',
      pageLoader:false,
      tabIndex:'summary',
      regionModal: false,
      engineIndex:0,
      prsvLlpIndex:0,
      airframeIndex:0,
      simulationType:'1', // 1:Read Mode, 2: Edit Mode, 3: Simulated Mode
      financeRptInfo:{},
      totalFundWidget:null,
      lifeOnWings:{},
      copyfinanceRptInfo:{},
      simulateFinanceRptInfo:{},
      financeError:{},
      fundAsOf:moment(),
      regionError:{},
      stateFlagCheck:false,
      regionDetails: {}
    }
    this.mrFinanceGraphApi = mrFinanceGraphApi.bind(this);
    this.mrFinanceLifeOfWingsApi = mrFinanceLifeOfWingsApi.bind(this);
    this.generateSimulateResultApi = generateSimulateResultApi.bind(this);
    this.updateRegionMapsApi = updateRegionMapsApi.bind(this);
  }
  componentDidMount(){
    this.mrFinanceGraphApi(this.props);
    trackActivity('Page Visited', {page_title: `Analyze MR Cashflow`, log_id: this.props.match.params.id})
  }
  handleChange = (event, newValue) => {
    this.setState({tabIndex:newValue})
  }
  handleAFChange = (event, newValue) => {
    this.setState({airframeIndex:newValue})
  }
  handleEngineChange = (event, newValue) => {
    this.setState({engineIndex:newValue, prsvLlpIndex:0})
  }
  handlePrsvLlpChange = (event, newValue) => {
    this.setState({prsvLlpIndex:newValue})
  }
  updateErrorField = (assemblyType, key, message, label) => {
    if(assemblyType == "airframe" && this.state.financeError.airframe){
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          [assemblyType]:{
            ...prevState.financeError[assemblyType],
            [label]:{
              ...prevState.financeError[assemblyType][label],
              [key]:message
            }
          }
        }
      }))
    }else {
      this.setState(prevState =>({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          [assemblyType]:{
            ...prevState.financeError[assemblyType],
            [key]:message
          }
        }
      }))
    }
  }
  updateRegionErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      regionError:{
        ...prevState.regionError,
        [key]:message
      }
    }))
  }
  updateEventsErrorField = (assemblyType, key, message, label, eventLabel) => {
    if((assemblyType == "apu" && this.state.financeError.apu) || (assemblyType == "landing_gear" && this.state.financeError.landing_gear)){
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          [assemblyType]:{
            ...prevState.financeError[assemblyType],
            ['events']:{
              ...prevState.financeError[assemblyType]['events'],
              [eventLabel]:{
                ...prevState.financeError[assemblyType]['events'][eventLabel],
                [key]:message
              }
            }
          }
        }
      }))
    }
    if(assemblyType == "airframe" && this.state.financeError.airframe){
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          [assemblyType]:{
            ...prevState.financeError[assemblyType],
            [label]:{
              ...prevState.financeError[assemblyType][label],
              ['events']:{
                ...prevState.financeError[assemblyType][label]['events'],
                [eventLabel]:{
                  ...prevState.financeError[assemblyType][label]['events'][eventLabel],
                  [key]:message
                }
              }
            }
          }
        }
      }))
    }
  }
  updateErrorEngineField = (key, message, primaryLabel = "", secondaryLabel = "", tertiaryLabel = "") => {
    if(this.state.financeError.engines && secondaryLabel){
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          engines:{
            ...prevState.financeError.engines,
            [primaryLabel]:{
              ...prevState.financeError.engines[primaryLabel],
              ['shop_visits']:{
                ...prevState.financeError.engines[primaryLabel]['shop_visits'],
                [secondaryLabel]:{
                  ...prevState.financeError.engines[primaryLabel]['shop_visits'][secondaryLabel],
                  [tertiaryLabel]:{
                    ...prevState.financeError.engines[primaryLabel]['shop_visits'][secondaryLabel][tertiaryLabel],
                    [key]:message
                  }
                }
              }
            }
          }
        }
      }))
    }if(['hourly_utilization', 'cycle_utilization', 'utilization_ratio', 'regionOfOperation'].includes(key)){
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          general_info:{
            ...prevState.financeError.general_info,
            [key]:''
          }
        }
      }))
    }else {
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          engines:{
            ...prevState.financeError.engines,
            [primaryLabel]:{
              ...prevState.financeError.engines[primaryLabel],
              [key]:message
            }
          }
        }
      }))
    }
  }

  onFieldChange = (event, keyParam, value, tableType, svType, eventId, prsvllpId) => {
    const { financeRptInfo } = this.state;
    if(tableType === 'airframe'){
      if(eventId){
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo:{
            ...prevState.financeRptInfo,
            [tableType]:{
              ...prevState.financeRptInfo[tableType],
              [svType]:{
                ...prevState.financeRptInfo[tableType][svType],
                events:{
                  ...prevState.financeRptInfo[tableType][svType].events,
                  [eventId]:{
                    ...prevState.financeRptInfo[tableType][svType].events[eventId],
                    [keyParam]:value
                  }
                }
              }
            }
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo: {
            ...prevState.financeRptInfo,
            [tableType]:{
              ...prevState.financeRptInfo[tableType],
              [svType]:{
                ...prevState.financeRptInfo[tableType][svType],
                [keyParam]:value
              }
            }
          }
        }))
      }
    }else if (tableType === 'engines') {
      if(svType === 'general_info'){
        if(keyParam === 'cycle_utilization' || keyParam === 'hourly_utilization'){
          let ratio = 0;
          if(keyParam === 'cycle_utilization'){
            ratio = value && value !==0 ? financeRptInfo[tableType].general_info.hourly_utilization/value:0
          }else{
            ratio = value ? value/financeRptInfo[tableType].general_info.cycle_utilization:0
          }
          this.setState(prevState => ({
            ...prevState,
            financeRptInfo: {
              ...prevState.financeRptInfo,
              [tableType]:{
                ...prevState.financeRptInfo[tableType],
                general_info:{
                  ...prevState.financeRptInfo[tableType].general_info,
                  utilization_ratio:ratio && ratio !== Infinity? ratio.toFixed(1):0
                }
              }
            }
          }));
        }
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo: {
            ...prevState.financeRptInfo,
            [tableType]:{
              ...prevState.financeRptInfo[tableType],
              general_info:{
                ...prevState.financeRptInfo[tableType].general_info,
                [keyParam]:value
              }
            }
          }
        }));
      }
      if(eventId === 'engine'){
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo:{
            ...prevState.financeRptInfo,
            [tableType]:{
              ...prevState.financeRptInfo[tableType],
              engine_data: {
                ...prevState.financeRptInfo[tableType].engine_data,
                [svType]: {
                  ...prevState.financeRptInfo[tableType].engine_data[svType],
                  [keyParam]:value
                }
              }
            }
          }
        }))
      }
      if(eventId && prsvllpId){
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo: {
            ...prevState.financeRptInfo,
            [tableType]: {
              ...prevState.financeRptInfo[tableType],
              engine_data:{
                ...prevState.financeRptInfo[tableType].engine_data,
                [svType]: {
                  ...prevState.financeRptInfo[tableType].engine_data[svType],
                  shop_visits:{
                    ...prevState.financeRptInfo[tableType].engine_data[svType].shop_visits,
                    [eventId]: {
                      ...prevState.financeRptInfo[tableType].engine_data[svType].shop_visits[eventId],
                      [prsvllpId]:{
                        ...prevState.financeRptInfo[tableType].engine_data[svType].shop_visits[eventId][prsvllpId],
                        [keyParam]:value
                      }
                    }
                  }
                }
              }
            }
          }
        }))
      }
    }else{
      if(eventId){
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo: {
            ...prevState.financeRptInfo,
            [tableType]:{
              ...prevState.financeRptInfo[tableType],
              events:{
                ...prevState.financeRptInfo[tableType].events,
                [eventId]:{
                  ...prevState.financeRptInfo[tableType].events[eventId],
                  [keyParam]:value
                }
              }
            }
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo: {
            ...prevState.financeRptInfo,
            [tableType]: {
              ...prevState.financeRptInfo[tableType],
              [keyParam]:value
            }
          }
        }));
      }
    }
  }
  handleSelect = address => {
    this.setState({address, showMarker: false}, () => {
      geocodeByAddress(address)
        .then(results => {this.getAddressDetails(results[0])})
    });
  };

  updateLocationWithoutGoogle = (key, value, stateFlag) => {
    let regionObj = this.state.regionDetails ? Object.assign({}, this.state.regionDetails) : {}
    this.setState({stateFlagCheck:stateFlag })
    switch (key) {
      case 'country':
        regionObj = {
          ...regionObj,
          country_name: value ? value.name : '',
          country_iso_2: value ? value.alpha_code_two : ''
        }
        break;
      case 'state':
        regionObj = {
          ...regionObj,
          state_name: value ? value.name : '',
          state_code: value ? value.state_code : ''
        }
        break;
      case 'city':
        regionObj = {
          ...regionObj,
          city_name: value ? value.city_name : '',
          city_code: value ? value.city_name : ''
        }
        break;
    }
    trackActivity('Region Selected', {source: 'Custom Dropdowns', page_title: 'Analyze MR Cashflow', log_id: this.props.match.params.id})
    this.setState(prevState => ({
      ...prevState,
      regionDetails:{...regionObj, google_api: false, address: stateFlag ? `${regionObj.city_name ? regionObj.city_name + ', ' : ''}${regionObj.state_name ? regionObj.state_name + ', ' : ''}${regionObj.country_name ? regionObj.country_name : ''}` :`${regionObj.city_name ? regionObj.city_name + ', ' : ''}${regionObj.country_name ? regionObj.country_name : ''}`},
    }), () => {
      if(key === 'save'){
        if(!stateFlag && regionObj.country_name){
          this.setState({regionModal: false})
          this.updateRegionMapsApi(this.props, {location:{...regionObj, state_name:'',...this.state.location}, engine_type:160})
          .then(response => {
            if(checkApiStatus(response)){
              this.setState(prevState => ({
                ...prevState,
                financeRptInfo: {
                  ...prevState.financeRptInfo,
                  engines:{
                    ...prevState.financeRptInfo.engines,
                    general_info:{
                      ...prevState.financeRptInfo.engines.general_info,
                      region: response.data.data.current_region
                    }
                  },
                  location:regionObj
                }
              }))
            }else{
              this.props.enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
            }
          })
          this.setState(prevState => ({
            ...prevState,
            regionDetails:regionObj,
            error:{
              ...prevState.error,
              regionOfOperation:''
            }
          }));
        }
        if(stateFlag && regionObj.country_name && regionObj.state_name){
          this.updateRegionMapsApi(this.props, {location:{...regionObj,...this.state.location}, engine_type:160})
          .then(response => {
            if(checkApiStatus(response)){
              this.setState(prevState => ({
                ...prevState,
                financeRptInfo: {
                  ...prevState.financeRptInfo,
                  engines:{
                    ...prevState.financeRptInfo.engines,
                    general_info:{
                      ...prevState.financeRptInfo.engines.general_info,
                      region: response.data.data.current_region
                    }
                  },
                  location:regionObj
                }
              }))
              this.setState({regionModal: false})
            }else{
              this.props.enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
              if(!regionObj.country_name) {
                this.setState(prevState => ({
                  ...prevState,
                  regionError:{
                    ...prevState.regionError,
                    country:'Please Select Country'
                  }
                }))
              }
              if(!regionObj.state_name){
                this.setState(prevState => ({
                  ...prevState,
                  regionError:{
                    ...prevState.regionError,
                    state:'Please select State'
                  }
                }))
              }
            }
          })
          this.setState(prevState => ({
            ...prevState,
            regionDetails:regionObj,
            error:{
              ...prevState.error,
              regionOfOperation:''
            }
          }));
        }else{
          // this.props.enqueueSnackbar('Not able to identify the location, please select again', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          if(!regionObj.country_name) {
            this.setState(prevState => ({
              ...prevState,
              regionError:{
                ...prevState.regionError,
                country:'Please Select Country'
              }
            }))
          }
          if(!regionObj.state_name){
            this.setState(prevState => ({
              ...prevState,
              regionError:{
                ...prevState.regionError,
                state:'Please select State'
              }
            }))
          }
        }
      }
    })
  }

  getAddressDetails = (address) => {
    const { financeRptInfo } = this.state
    if(address){
      const { financeRptInfo } = this.state;
      this.setState({address:address.formatted_address})
      let regionObj = {};
      address.address_components.map(location => {
        let types = location.types[0];
        switch (types) {
          case 'country':
            regionObj = {
              ...regionObj,
              country_name: location.long_name ? location.long_name : '',
              country_iso_2: location.short_name ? location.short_name : ''
            }
            return
          case "administrative_area_level_1":
            regionObj = {
              ...regionObj,
              state_name: location.long_name ? location.long_name : '',
              state_code: location.short_name ? location.short_name : ''
            }
            return
          case "administrative_area_level_2":
              regionObj = {
                ...regionObj,
                city_name: location.long_name ? location.long_name : '',
                city_code: location.short_name ? location.short_name : ''
              }
              return
          default:
            return
        }
      });
      regionObj = {
        ...regionObj,
        address: `${regionObj.city_name ? regionObj.city_name: ''}${regionObj.city_name ? ', ' : ''}${regionObj.state_name ? regionObj.state_name : ''}${regionObj.state_name ? ', ' :''}${regionObj.country_name}`
      }
      if(regionObj.country_iso_2){
        this.updateRegionMapsApi(this.props, {location:{...regionObj,...this.state.location}, engine_type:financeRptInfo.engines.general_info.engine_type.id})
        .then(response => {
          if(checkApiStatus(response)){
            this.setState(prevState => ({
              ...prevState,
              financeRptInfo: {
                ...prevState.financeRptInfo,
                engines:{
                  ...prevState.financeRptInfo.engines,
                  general_info:{
                    ...prevState.financeRptInfo.engines.general_info,
                    region: response.data.data.current_region
                  }
                },
                location:regionObj
              }
            }))
          }else{
            this.props.enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
          }
        })
        this.setState(prevState => ({
          ...prevState,
          regionDetails:{...regionObj, google_api: true},
          error:{
            ...prevState.error,
            regionOfOperation:''
          }
        }));
      }else{
        this.props.enqueueSnackbar('Not able to identify the location, please select again', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    }else{
      this.props.enqueueSnackbar('Not able to identify the location, please select again', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  }
  updateFundStatusCardFn = (date) => {
    const { financeRptInfo } = this.state;
    if(date){
      let widgetSum = graphWidgetSum(financeRptInfo, moment(date).format('YYYYMM'));
      this.setState({totalFundWidget:widgetSum, fundAsOf:date});
      trackActivity(`Fund Status Date Changed to ${moment(date).format('YYYYMM')}`, {page_title: `Analyze MR Cashflow`, log_id: this.props.match.params.id})
    }
  }
  render(){
    const { address, pageLoader, tabIndex, engineIndex, prsvLlpIndex, airframeIndex, fundAsOf, financeRptInfo, copyfinanceRptInfo, totalFundWidget, lifeOnWings, simulateFinanceRptInfo, simulationType, financeError, apuErrorFlag,airframeErrorFlag, lgErrorFlag, engineErrorFlag, regionError, stateFlagCheck } = this.state;
    return(
      <Fragment>
        { pageLoader ? <PageLoader /> : null }
          <div className="mr-calculator-section">
            <div className="mr-title">
              <Breadcrumbs aria-label="breadcrumb" style={{fontSize:'16px'}}>
                <Link color="inherit" to="/mr-calculator/logs" >
                  <Typography className="flex-centered" component="h1" style={{textDecoration:'underline'}}> MR Logs</Typography>
                </Link>
                {getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('C') != -1 ?
                  <Link color="inherit" to="/mr-calculator/initiate" >
                    <Typography className="flex-centered" component="h1" style={{textDecoration:'underline'}}>
                      Generate Finance Report
                    </Typography>
                  </Link> : null
                }
                <Link to={`/mr-calculator/intermediate/${this.props.match.params.id}`}>
                  <Typography className="flex-centered" component="h1" style={{textDecoration:'underline'}} >{this.props.match.params.id}</Typography>
                </Link>
                <h1 className="flex-centered" color="primary">MR Cashflow</h1>
            </Breadcrumbs>
          </div>
            <Paper className="mr-log-info">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={7} md={9} >
                  <ul className="list-inline flex-centered">
                    <li className="list-inline-item">
                      <p>Log ID</p>
                      <h6>{this.props.match.params.id}</h6>
                    </li>
                    { financeRptInfo.general_info ?
                      <Fragment>
                        <li className="list-inline-item">
                          <p>Aircraft Type</p>
                          <h6>{financeRptInfo.general_info.aircraft_type.name}</h6>
                        </li>
                        <li className="list-inline-item">
                          <p>Date of Manufacture</p>
                          <h6>{moment(financeRptInfo.general_info.dateOfManufacture).format(displayDateFormatShort)}</h6>
                        </li>
                      </Fragment>
                      :null
                    }
                    { financeRptInfo.apu && financeRptInfo.apu.apu_type && financeRptInfo.apu.apu_type.name ?
                      <li className="list-inline-item">
                        <p>APU Type</p>
                        <h6>{financeRptInfo.apu.apu_type.name}</h6>
                      </li>:null
                    }
                  </ul>
                </Grid>
                <Grid item xs={12} sm={5} md={3} align={isMobile ? 'center':''}>
                  <MrCashflow
                    copyfinanceRptInfo={copyfinanceRptInfo}
                    simulateFinanceRptInfo={simulateFinanceRptInfo}
                    simulationType={simulationType}
                  />

                </Grid>
              </Grid>
            </Paper>
            <div className="mr-graphical-projection">
              <div className="fund-status-card">
                <div style={{width:'200px'}}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="normal"
                       id="fundDate"
                       label="Fund Status as of"
                       format={'MMM YYYY'}
                       fullWidth
                       minDate={financeRptInfo.general_info  && financeRptInfo.general_info.dateOfManufacture ? moment(financeRptInfo.general_info.dateOfManufacture) : moment().subtract(50 ,'years')}
                       InputLabelProps={{shrink: true}}
                       value={fundAsOf}
                       views={['month']}
                       onChange={(data, value) => this.updateFundStatusCardFn(data ? moment(data).format(backendDateFormat):data)}
                       ToolbarComponent={DPMonthToolbar}
                     />
                  </MuiPickersUtilsProvider>
                </div>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <FundCard title="Total Fund" amount={totalFundWidget && totalFundWidget.totalSum?totalFundWidget.totalSum:0} />
                  </li>
                  { financeRptInfo.apu ?
                    <li className="list-inline-item">
                      <FundCard title="APU" amount={totalFundWidget.apuSum} />
                    </li>:null
                  }
                  { financeRptInfo.landing_gear ?
                    <li className="list-inline-item">
                      <FundCard title="Landing Gears" amount={totalFundWidget.landingGearSum} />
                    </li>:null
                  }

                  { financeRptInfo.airframe && totalFundWidget.airframeSumArray.map((prg) =>
                    <li className="list-inline-item">
                      <FundCard title={`${prg.name} Yr`} amount={prg.amount} />
                    </li>
                  )}
                  { financeRptInfo.engines && totalFundWidget.enginesSumArray.map((amt,index) =>
                    <li className="list-inline-item">
                      <FundCard title={`Engine #${index+1}`} amount={amt} />
                    </li>
                  )}
                </ul>
              </div>
              <Tabs
                value={tabIndex}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons={isMobile ? 'on':'auto'}
              >
                <Tab label={`Summary`} value={'summary'} />
                { financeRptInfo.airframe ?
                  <Tab label={`Airframe`} value={'airframe'} />:null
                }
                { financeRptInfo.engines ?
                  <Tab label={`Engines`} value={'engines'} />:null
                }
                { financeRptInfo.apu ?
                  <Tab label={`APU`} value={'apu'} />:null
                }
                { financeRptInfo.landing_gear ?
                  <Tab label={`Landing Gear`} value={'landing_gear'} />:null
                }
              </Tabs>
              { tabIndex === 'summary' ?
                <div>
                  <SummaryGraph
                    financeRptInfo={financeRptInfo}
                    simulateFinanceRptInfo={simulateFinanceRptInfo}
                    simulationType={simulationType}
                  />
                  { financeRptInfo.airframe ||  financeRptInfo.apu || financeRptInfo.landing_gear ?
                    <AssemblyEventHD />:null
                  }

                  { financeRptInfo.airframe ?
                    Object.keys(financeRptInfo.airframe).map((label) =>
                      <AssemblyEventInfo
                        assemblyType='airframe'
                        log_id={this.props.match.params.id}
                        assemblyInfo={financeRptInfo.airframe[label]}
                        assemblyInfoSimulated={simulationType === '3' ? simulateFinanceRptInfo.airframe[label]: {} }
                        simulationType={simulationType}
                        updateErrorField={this.updateErrorField}
                        updateEventsErrorField={this.updateEventsErrorField}
                        financeError={ financeError && financeError.airframe && financeError.airframe[label]}
                        onFieldChange={this.onFieldChange}
                        generalInfo={financeRptInfo.general_info}
                        checkYr={label}
                      />
                    ):null
                  }
                  {/* if your change any code below, pls change the same in line no. 821 - 845   */}
                  { financeRptInfo.apu ?
                    <AssemblyEventInfo
                      assemblyType='apu'
                      log_id={this.props.match.params.id}
                      assemblyInfo={financeRptInfo.apu}
                      assemblyInfoSimulated={ simulationType === '3' ? simulateFinanceRptInfo.apu:{}}
                      simulationType={simulationType}
                      updateErrorField={this.updateErrorField}
                      updateEventsErrorField={this.updateEventsErrorField}
                      financeError={financeError && financeError.apu}
                      onFieldChange={this.onFieldChange}
                      generalInfo={financeRptInfo.general_info}
                    />:null
                  }
                  {/* if your change any code below, pls change the same in line no. 849 - 868   */}
                  { financeRptInfo.landing_gear ?
                    <AssemblyEventInfo
                      assemblyType='landing_gear'
                      log_id={this.props.match.params.id}
                      assemblyInfo={financeRptInfo.landing_gear}
                      assemblyInfoSimulated={simulationType === '3' ? simulateFinanceRptInfo.landing_gear:{}}
                      simulationType={simulationType}
                      updateErrorField={this.updateErrorField}
                      updateEventsErrorField={this.updateEventsErrorField}
                      financeError={financeError && financeError.landing_gear}
                      onFieldChange={this.onFieldChange}
                      generalInfo={financeRptInfo.general_info}
                    />
                    :null
                  }
                  {/* if your change any code below, pls change the same in line no. 765 - 780  */}
                  { financeRptInfo.engines ?
                    <div>
                      <h2 style={{fontSize:'20px', marginBottom:'16px'}}>Engine(s)</h2>
                      <EngineBasicInfo
                        address={address}
                        financeRptInfo={financeRptInfo}
                        simulateFinanceRptInfo={simulationType === '3' ? simulateFinanceRptInfo:{}}
                        simulationType={simulationType}
                        financeError={financeError && financeError['general_info'] ? financeError['general_info'] : {}}
                        onFieldChange={this.onFieldChange}
                        updateErrorEngineField={this.updateErrorEngineField}
                        handleSelect={this.handleSelect}
                        onAddressChange={(address) => {this.setState({address:address}); this.updateErrorEngineField('regionOfOperation')}}
                        regionDetails={this.state.regionDetails}
                        updateLocationWithoutGoogle={(key, value) => this.updateLocationWithoutGoogle(key, value)}
                        setRegionModal={(flag) => this.setState({regionModal: flag})}
                      />
                      <Tabs
                        value={engineIndex}
                        onChange={this.handleEngineChange}
                        variant="scrollable"
                        scrollButtons={isMobile ? 'on':'auto'}
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        { Object.keys(financeRptInfo.engines.engine_data).map((label, index) =>
                          <Tab label={`Engine #${index+1}`}  />
                        )}
                      </Tabs>
                      { Object.keys(financeRptInfo.engines.engine_data).map((label, index) =>
                        engineIndex === index ?
                        <Fragment>
                          <div style={{marginBottom:'14px'}}>
                            <LOICalModal
                              engineBasicInfo={{
                                ratio:financeRptInfo.engines.general_info.utilization_ratio,
                                min_ratio:financeRptInfo.engines.general_info.utilization_min_ratio,
                                max_ratio:financeRptInfo.engines.general_info.utilization_max_ratio,
                                engineNo:label
                              }}
                            />
                          </div>

                          <Paper className="engine-events-card">
                            <EngineEventInfo
                              engine={financeRptInfo.engines.engine_data[label]}
                              simulateEngine={ simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label]:{}}
                              simulationType={simulationType}
                              financeError={financeError && financeError.engines && financeError.engines[label]}
                              updateErrorEngineField={this.updateErrorEngineField}
                              onFieldChange={this.onFieldChange}
                              label={label}
                            />
                            <div className="engine-events-table">
                              {/* if your change any code below, pls change the same in line no. 790 - 800  */}
                              <TableListComp
                                heads={engineEventHd}
                                data={Object.keys(financeRptInfo.engines.engine_data[label].shop_visits).map((sv, svIndex) =>
                                  <EngineEventList
                                    engineIndex={label}
                                    shopVisitIndex={sv}
                                    index={svIndex}
                                    simulationType={simulationType}
                                    shopVisit={financeRptInfo.engines.engine_data[label].shop_visits[sv]}
                                    financeError={financeError && financeError.engines && financeError.engines[label] && financeError.engines[label]['shop_visits'] && financeError.engines[label]['shop_visits'] &&  financeError.engines[label]['shop_visits'][sv] }
                                    updateErrorEngineField={this.updateErrorEngineField}
                                    simulateShopVisit={ simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label].shop_visits[sv]:{}}
                                    onFieldChange={this.onFieldChange}
                                  />
                                )}
                              />
                            </div>
                          </Paper>
                          <div className="life-on-wings">
                            <LOWGraph
                              simulationType={simulationType}
                              lowInfo={financeRptInfo.engines.engine_data[label].life_on_wing_plots}
                              lowInfoSimulated={simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label].life_on_wing_plots:{}}
                            />
                          </div>
                        </Fragment>
                        :null
                      )}
                    </div>:null
                  }
                </div>:null
              }
              { tabIndex === 'airframe' ?
                <div>
                  <Tabs
                    value={airframeIndex}
                    onChange={this.handleAFChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="normal"
                    aria-label="full width tabs example"
                  >
                    { Object.keys(financeRptInfo.airframe).map((label) =>
                      <Tab label={financeRptInfo.airframe[label].name} />
                    )}
                  </Tabs>
                  { Object.keys(financeRptInfo.airframe).map((label, index) =>
                    index === airframeIndex ?
                    <div>
                      <AssemblyGraph
                        simulationType={simulationType}
                        type="airframe"
                        color='rgba(255,218,218,1)'
                        lineColor="#FF5E5E"
                        events={financeRptInfo.airframe[label].events}
                        newevents={simulationType === '3' ? simulateFinanceRptInfo.airframe[label].events:{}}
                        plots={financeRptInfo.airframe[label].plots}
                        plotsSimulated={simulationType === '3' ? simulateFinanceRptInfo.airframe[label].plots:{}}
                      />
                      <AssemblyEventHD />
                      <AssemblyEventInfo
                        assemblyType='airframe'
                        log_id={this.props.match.params.id}
                        assemblyInfo={financeRptInfo.airframe[label]}
                        assemblyInfoSimulated={simulationType === '3' ? simulateFinanceRptInfo.airframe[label]:{}}
                        simulationType={simulationType}
                        updateErrorField={this.updateErrorField}
                        updateEventsErrorField={this.updateEventsErrorField}
                        financeError={financeError && financeError.airframe && financeError.airframe[label]}
                        onFieldChange={this.onFieldChange}
                        checkYr={label}
                      />
                    </div>:null
                  )}
                </div>:null
              }
              { tabIndex === 'engines' ?
                <div>
                  <Tabs
                    value={engineIndex}
                    onChange={this.handleEngineChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="normal"
                    aria-label="full width tabs example"
                  >
                    { Object.keys(financeRptInfo.engines.engine_data).map((label, index) =>
                      <Tab label={`Engine #${index+1}`} />
                    )}
                  </Tabs>
                  { Object.keys(financeRptInfo.engines.engine_data).map((label, index) =>
                    engineIndex === index ?
                    <div>
                      <Tabs
                        value={prsvLlpIndex}
                        onChange={this.handlePrsvLlpChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="normal"
                        aria-label="full width tabs example"
                      >
                        <Tab label='PRSV' />
                        <Tab label='LLP' />
                      </Tabs>
                      { prsvLlpIndex === 0 ?
                        <div>
                          <AssemblyGraph
                            simulationType={simulationType}
                            type="engines"
                            color='#F9CAE3'
                            lineColor="#E010A4"
                            events={financeRptInfo.engines.engine_data[label].shop_visits}
                            newevents={simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label].shop_visits:{}}
                            plots={financeRptInfo.engines.engine_data[label].prsv_plots}
                            plotsSimulated={simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label].prsv_plots:{}}
                          />
                        </div>:null
                      }
                      { prsvLlpIndex === 1 ?
                        <div>
                          <AssemblyGraph
                            simulationType={simulationType}
                            type="engines"
                            color='#F9CAE3'
                            lineColor="#E010A4"
                            events={financeRptInfo.engines.engine_data[label].shop_visits}
                            newevents={simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label].shop_visits:{}}
                            plots={financeRptInfo.engines.engine_data[label].llp_plots}
                            plotsSimulated={simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label].llp_plots:{}}
                          />
                        </div>:null
                      }
                      <Paper className="engine-events-card">
                        <div style={{padding:'15px'}}>
                          <EngineBasicInfo
                            address={address}
                            financeRptInfo={financeRptInfo}
                            simulateFinanceRptInfo={simulationType === '3' ? simulateFinanceRptInfo:{}}
                            simulationType={simulationType}
                            financeError={financeError && financeError['general_info'] ? financeError['general_info'] : {}}
                            onFieldChange={this.onFieldChange}
                            updateErrorEngineField={this.updateErrorEngineField}
                            label={label}
                            handleSelect={this.handleSelect}
                            onAddressChange={(address) => this.setState({address:address})}
                            regionDetails={this.state.regionDetails}
                            updateLocationWithoutGoogle={(key, value) => this.updateLocationWithoutGoogle(key, value)}
                            setRegionModal={(flag) => this.setState({regionModal: flag})}
                          />
                        </div>
                        <EngineEventInfo
                          engine={financeRptInfo.engines.engine_data[label]}
                          simulateEngine={ simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label]:{}}
                          simulationType={simulationType}
                          financeError={financeError && financeError.engines && financeError.engines[label]}
                          updateErrorEngineField={this.updateErrorEngineField}
                          onFieldChange={this.onFieldChange}
                          label={label}
                        />
                        <div className="engine-events-table">
                          <TableListComp
                            heads={engineEventHd}
                            data={Object.keys(financeRptInfo.engines.engine_data[label].shop_visits).map((sv, svIndex) =>
                              <EngineEventList
                                engineIndex={label}
                                shopVisitIndex={sv}
                                index={svIndex}
                                simulationType={simulationType}
                                shopVisit={financeRptInfo.engines.engine_data[label].shop_visits[sv]}
                                financeError={financeError && financeError.engines && financeError.engines[label] && financeError.engines[label]['shop_visits'] && financeError.engines[label]['shop_visits'][sv]}
                                updateErrorEngineField={this.updateErrorEngineField}
                                simulateShopVisit={ simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label].shop_visits[sv]:{}}
                                onFieldChange={this.onFieldChange}
                              />
                            )}
                          />
                        </div>
                        <div className="life-on-wings">
                          <LOWGraph
                            simulationType={simulationType}
                            lowInfo={financeRptInfo.engines.engine_data[label].life_on_wing_plots}
                            lowInfoSimulated={simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label].life_on_wing_plots:{}}
                          />
                        </div>
                      </Paper>
                    </div>:null
                  )}
                </div>:null
              }
              { tabIndex === 'apu' ?
                <div>
                  <AssemblyGraph
                    simulationType={simulationType}
                    type="apu"
                    color='#D5E8FF'
                    lineColor="#2F8CFF"
                    events={financeRptInfo.apu.events}
                    newevents={simulationType === '3' ? simulateFinanceRptInfo.apu.events:{}}
                    plots={financeRptInfo.apu.plots}
                    plotsSimulated={simulationType === '3' ? simulateFinanceRptInfo.apu.plots:{}}
                  />
                  <AssemblyEventHD />
                  <AssemblyEventInfo
                    assemblyType='apu'
                    log_id={this.props.match.params.id}
                    assemblyInfo={financeRptInfo.apu}
                    assemblyInfoSimulated={simulationType === '3' ? simulateFinanceRptInfo.apu:{}}
                    simulationType={simulationType}
                    updateErrorField={this.updateErrorField}
                    updateEventsErrorField={this.updateEventsErrorField}
                    financeError={financeError && financeError.apu}
                    onFieldChange={this.onFieldChange}
                  />
                </div>:null
              }
              { tabIndex === 'landing_gear' ?
                <div>
                  <AssemblyGraph
                    simulationType={simulationType}
                    type="landing_gear"
                    color='#D4F5F1'
                    lineColor="#41D6C3"
                    events={financeRptInfo.landing_gear.events}
                    newevents={simulationType === '3' ? simulateFinanceRptInfo.landing_gear.events:{}}
                    plots={financeRptInfo.landing_gear.plots}
                    plotsSimulated={ simulationType === '3' ? simulateFinanceRptInfo.landing_gear.plots:{}}
                  />
                  <AssemblyEventHD />
                  <AssemblyEventInfo
                    assemblyType='landing_gear'
                    log_id={this.props.match.params.id}
                    assemblyInfo={financeRptInfo.landing_gear}
                    assemblyInfoSimulated={simulationType === '3' ? simulateFinanceRptInfo.landing_gear:{}}
                    simulationType={simulationType}
                    updateErrorField={this.updateErrorField}
                    updateEventsErrorField={this.updateEventsErrorField}
                    financeError={financeError && financeError.landing_gear}
                    onFieldChange={this.onFieldChange}
                  />
                </div>:null
              }
              <div className="projection-simulations-cta">
                <ul className="list-inline flex-centered">
                  { simulationType === '1' ?
                    <li className="list-inline-item">
                      <Button onClick={() => {this.setState({simulationType:'2'});trackActivity(`Log Simulation`, {page_title: 'Analyze MR Cashflow', log_id:this.props.match.params.id})}} color="primary" variant="contained">Simulate Scenarios</Button>
                    </li>:null
                  }
                  { simulationType === '2' ?
                    <Fragment>
                      <li className="list-inline-item" onClick={() => {this.setState({simulationType:'1',simulateFinanceRptInfo:null, financeError:{}});this.mrFinanceGraphApi(this.props) }}>
                        Reset to Default
                      </li>
                      <li className="list-inline-item">
                        <Button onClick={() => this.generateSimulateResultApi(this.props, financeRptInfo)} color="primary" variant="contained">Apply</Button>
                      </li>
                    </Fragment>:null
                  }
                  { simulationType === '3' ?
                    <Fragment>
                      <li className="list-inline-item" onClick={() => {this.setState({simulationType:'1',simulateFinanceRptInfo:null, financeError:{}}); this.mrFinanceGraphApi(this.props)}}>
                        Reset to Default
                      </li>
                      <li className="list-inline-item">
                        <Button onClick={() => this.setState({simulationType:'2', simulateFinanceRptInfo:null, address:copyfinanceRptInfo.location && copyfinanceRptInfo.location.address ? copyfinanceRptInfo.location.address: copyfinanceRptInfo.engines ? copyfinanceRptInfo.engines.general_info.region.name:''})} color="primary" variant="contained">Simulate Another Scenarios</Button>
                      </li>
                    </Fragment>:null
                  }
                </ul>
              </div>
            </div>
          </div>
          <Dialog
            open={this.state.regionModal}
            onClose={() => this.setState({regionModal: false})}
            aria-labelledby = "scroll-dialog-title"
            fullWidth={true}
          >
          <DialogContent style={{padding:'8px 10px'}}>
            <CountryStateSelector
              regionDetails={this.state.regionDetails}
              updateLocationWithoutGoogle={(key, value, stateFlag) => this.updateLocationWithoutGoogle(key, value, stateFlag)}
              updateRegionErrorField={this.updateRegionErrorField}
              error={regionError}
              updateErrorField={() => console.log('')  }
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({regionModal: false})}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={() => this.updateLocationWithoutGoogle('save', null, stateFlagCheck)}>Okay</Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}
export default withSnackbar(MrGraphicalReps);
