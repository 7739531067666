import React from 'react';
import { Paper, Grid, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import InfoIcon from '@material-ui/icons/Info';
const AirframeInitiate = ({mrLogInfo, aircraftTypes, error, mntGroups, onFieldChange, updateErrorField, onFieldAutopulate}) => {
  return(
    <Paper className="mr-initiate-card">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <h4 className="mr-initiate-card-title" style={{color:'#bd10e0'}}><strong>Airframe</strong></h4>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            options = {aircraftTypes}
            getOptionLabel={option => option.name}
            id="aircraftType"
            value={mrLogInfo.airframeAndGen.aircraftType}
            onChange={(e, value) => {onFieldChange(e, 'aircraftType', value);}}
            renderInput={params => <TextField required error={error.aircraftType ? true:false} helperText={error.aircraftType ? error.aircraftType:''} onFocus={() => updateErrorField('aircraftType','')} {...params} label="Aircraft Type" placeholder="Select Aircraft Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            options = {mntGroups}
            getOptionLabel={option => option.label}
            id="maintenance_group"
            value={mrLogInfo.airframeAndGen.maintenanceProgram}
            onChange={(e, value) => {onFieldChange(e, 'maintenanceProgram', value);}}
            renderInput={params => <TextField required error={error.maintenanceProgram ? true:false} helperText={error.maintenanceProgram ? error.maintenanceProgram:''} onFocus={() => updateErrorField('maintenanceProgram','')} {...params} label="Maintenance Program" placeholder="Select Maintenance Program" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              required
               margin="normal"
               id="dom"
               label="Date of Manufacture"
               format={fieldDateFormat}
               fullWidth
               disableFuture
               minDate={moment().subtract(50, 'years')}
               InputLabelProps={{shrink: true}}
               value={mrLogInfo.airframeAndGen.dateOfManufacture ?mrLogInfo.airframeAndGen.dateOfManufacture:null}
               error={error.dateOfManufacture ? true:false}
               helperText={error.dateOfManufacture ? error.dateOfManufacture:''}
               onChange={(data, value) => {onFieldChange(value, 'dateOfManufacture', data ? moment(data).format(backendDateFormat):data); updateErrorField('dateOfManufacture','')}}
               onFocus={() => updateErrorField('dateOfManufacture','')}
               onBlur={(e) =>  {onFieldAutopulate(e, 'autopopulate', true, 'apuAndGen');onFieldAutopulate(e, 'autopopulate', true, 'lgAndGen')}}
               onAccept={(e) =>  {onFieldAutopulate(e, 'autopopulate', true, 'apuAndGen');onFieldAutopulate(e, 'autopopulate', true, 'lgAndGen')}}
             />
            </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default AirframeInitiate;
