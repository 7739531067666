import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Button, TextField, CircularProgress, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import config from '../../../config';
export default function LoginForm({error, authInfo, handleChange, onLogin, formSubmitLoader}){
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  let baseUrl = config.api.appUrl + '/';
  return(
    <form onSubmit={(e) => {e.preventDefault();onLogin()}}>
      <TextField
        id="email"
        label="Registered Email Address"
        fullWidth
        margin="normal"
        value={authInfo.email}
        error={error.email ? true:false }
        helperText={error.email}
        InputLabelProps={{shrink: true}}
        onChange={(e) => handleChange(e.target.value, 'email')}
      />
      <TextField
        id="password"
        type={values.showPassword ? 'text' : 'password'}
        label="Password"
        fullWidth
        margin="normal"
        value={authInfo.password}
        error={error.password ? true:false }
        helperText={error.password}
        InputLabelProps={{shrink: true}}
        InputProps={{
            endAdornment: <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
          }}
        onChange={(e) => handleChange(e.target.value, 'password')}
      />
      { config.api.networkInterface.includes('dev.beta') ? null:
        <Fragment>
          <a className="forgot-pwd-link" href={`${baseUrl}forgot-password`}>Forgot Password?</a>
        </Fragment>
      }
      <div className="text-center" style={{marginTop:'30px'}}>
        <p className="terms-and-condition">
          By clicking Sign In, you agree to our&nbsp;
          <a className="url" href="/termscondition" > Terms, </a>
          <a className="url" href="/datapolicy" >&nbsp;Data Policy </a> and <br/>
          <a className="url" href="https://acumenaviation.ie/privacy-policy/" target="_blank" rel="noreferrer"> Privacy Policy</a>.
          </p>
      </div>
      <div className="text-center" style={{marginTop:'10px'}}>
        <Button style={{width:'120px'}} variant="contained" color="primary" type="submit" disabled={formSubmitLoader}>{ formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Sign In' }</Button>
      </div>
    </form>
  )
}
