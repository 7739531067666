import React, { Fragment, Component } from 'react';
import { MixpanelConsumer } from 'react-mixpanel';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import { getLocalStorageInfo, getGlobalCookie, authorizeAutoLogin, getLessorsList } from '../utils';
import { lessorUpdateApi } from '../application/auth/apiServices';
import { DesktopHeader, MobileHeader, DeskTopSidebar } from '../shared_elements';
import config from '../config';
window.addEventListener('visibilitychange', checkLogin)
function checkLogin(){
  if(document.visibilityState || document.hasFocus){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let location = window.location.href
    let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
    if(!lessorAccess){
        localStorage.clear();
        if(!location.includes('login')){
           // window.location.reload()
        }
      }else{
        if(lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment) ){
          if(!getLocalStorageInfo()){
              authorizeAutoLogin({lessor_id:lessorAccess.id})
           }else if(!location.includes('select-lessor') && !location.includes('otp')){
             if(lessorAccess.id !== userInfo.defaultLessor.id){
               authorizeAutoLogin({lessor_id:lessorAccess.id})
             }else{
               //window.location.assign('/')
             }
           }
        }else {
          if(userInfo && userInfo.defaultLessor == undefined){
            // window.location.assign("/select-lessor");
          }
        }
      }
       getLessorsList()
  }

}
export default function PageLayout(HocComponent, extraProps={}){
  return class extends Component{
    layoutMarginProps = () => {
      if(extraProps.layoutPhase){
        switch (extraProps.layoutPhase) {
          case 0:
            // Desktop with Primary Header Only
            return '50px 0 0 0'
          case 1:
            // Desktop with Primary Header + Primary Sidenav
            return '15px 0 0 70px'
          case 2:
            // Desktop with Primary Header + Primary Sidenav + Secondary Sidenav
            return '53px 0 0 270px';
          default:
            return '0'
        }
      }else{
        return '0'
      }
    }
    constructor(props){
      super(props);
      this.state = {
        flag:false
      }
      this.lessorUpdateApi = lessorUpdateApi.bind(this);
    }
    componentDidMount(){
       checkLogin()
    }
    render(){
      const lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
      // Check for Local Storage and Token Issue
      const { flag } = this.state;
      if(lessorAccess && lessorAccess.access && lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment) ){
        return (
          <section>
            { getLocalStorageInfo() ?
              <Fragment>
                { isMobile ? <MobileHeader /> :
                  <Fragment>
                    <DesktopHeader />
                    <DeskTopSidebar />
                  </Fragment>
                }
              </Fragment>:null
            }

            <main style={{margin: isMobile ? '10px 0 0 0' : this.layoutMarginProps() , padding: '20px 20px 58px 20px'}}>
              <div>
                <MixpanelConsumer>
                  {mixpanel => <HocComponent mixpanel={mixpanel} {...this.props} />}
                </MixpanelConsumer>
              </div>
            </main>
          </section>
        );
      }else{
        return <Redirect to="/login" />
      }

    }
  }
}
