import React from 'react';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
export default function OTPVerificationForm({error, resendOtpfn, authInfo, handleChange, onForgot, formSubmitLoader}){
  return(
    <form onSubmit={(e) => {e.preventDefault(); onForgot()}}>
      <TextField
        id="otp"
        fullWidth
        margin="normal"
        value={authInfo.email}
        error={error.otp ? true:false }
        helperText={error.otp}
        InputLabelProps={{shrink: true}}
        onChange={(e) => handleChange(e.target.value, 'otp')}
      />
      <p className="form-para otp text-center">OTP not received yet? <Link onClick={() => resendOtpfn()}>Resend OTP</Link></p>
      <div className="text-center" style={{marginTop: '20px'}}>
        <Button style={{width:'120px'}} variant="contained" type="submit" disabled={formSubmitLoader} color="primary">{ formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Submit OTP' }</Button>
      </div>
    </form>
  )
}
