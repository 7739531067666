import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Paper, Grid, TextField, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile, isTablet } from 'react-device-detect';
import CashflowList from './CashflowList';
import { ExportManu, PageLoader } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getCashflowListApi, exportCashflowListApi } from '../apiServices';
import { removeEmptyKey } from '../../../utils';
class MrCashflow extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      cashflowList:[],
      perPage:40,
      currentPage:1,
      totalCount:0,
      resultCopy:[],
      originalCashflowList:[],
      years: [],
      nameFilter:[],
      hasMore:true,
      filter:{
        name:'',
        month:''
      },
    };
    this.getCashflowListApi = getCashflowListApi.bind(this);
    this.exportCashflowListApi = exportCashflowListApi.bind(this);
  }
  onFieldChange = (event, keyParam, value) => {
    const { originalCashflowList, filter } = this.state;
    let filterRes = [];
    let filterParams = removeEmptyKey({
      month: keyParam === 'month' ? value : filter.month,
      name: keyParam === 'name' ? value:filter.name,
    });
    if(filterParams.month && filterParams.name){
      filterRes = originalCashflowList.filter((item) => item.name === filterParams.name && item.month.slice(0,4) === filterParams.month.toString())
    }else if (filterParams.month) {
      filterRes = originalCashflowList.filter((item) => item.month.slice(0,4) === filterParams.month.toString())
    }else if (filterParams.name) {
      filterRes = originalCashflowList.filter((item) => item.name === filterParams.name)
    }else{
      filterRes = originalCashflowList;
    }
    filterRes.sort((a,b) => parseInt(a.month) - parseInt(b.month));
    this.setState(prevState => ({
      ...prevState,
      cashflowList:filterRes.slice(0,40),
      resultCopy:filterRes,
      currentPage:1,
      totalCount:filterRes.length,
      filter:{
        ...prevState.filter,
        [keyParam]:value
      }
    }))
  }
  moveNextPage = () => {
    const { cashflowList, resultCopy, currentPage, perPage, totalCount } = this.state;
    if (cashflowList.length >= totalCount) {
      this.setState({ hasMore: false });
      return;
    }
    let scrollData = JSON.parse(JSON.stringify(resultCopy));
    scrollData = scrollData.sort((a,b) => parseInt(a.month) - parseInt(b.month)).slice((currentPage*perPage), (currentPage*perPage)+perPage);
    this.setState(prevState => ({
      ...prevState,
      cashflowList: [...prevState.cashflowList, ...scrollData ],
      currentPage: (prevState.currentPage + 1)
    }));
  }
  render(){
    const { skeletonLoader, pageLoader, modal, years, nameFilter, filter, cashflowList, hasMore } = this.state;
    const { copyfinanceRptInfo, simulateFinanceRptInfo, simulationType } = this.props;
    return(
      <Fragment>
        <Button className="generate-report-button" onClick={() => this.getCashflowListApi(this.props, simulationType === '3' ? simulateFinanceRptInfo: copyfinanceRptInfo, 'skeletonLoader')} color="primary" variant="contained" style={ !isMobile ?  {float:"right"} : {align:'center'}}>Generate Monthly Cashflow</Button>
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
            fullScreen
          >
            <DialogTitle id="scroll-dialog-title">
            <Link style={{verticalAlign:'sub'}} onClick={() => this.setState({modal:false, filter:{}})}><ArrowBackIcon /></Link> Monthly Cashflow <ExportManu title="Export" files={[{title:'Excel', extension: 'xls', key:''}]} exportReportFn={(file) => this.exportCashflowListApi(this.props,simulationType === '3' ? simulateFinanceRptInfo: copyfinanceRptInfo, 'xls', filter)} />
            </DialogTitle>
            <DialogContent dividers={true} id="scrollableDiv">
              <div className="mr-calculator-section">
                { skeletonLoader ? <STableLoader count={5} />:
                  <div className="mr-cashflow-listing">
                    <Paper>
                      <div>
                        <ul className="list-inline cashflow-filters">
                          <li className="list-inline-item">
                            <Autocomplete
                              options = {years}
                              getOptionLabel={option => option.label}
                              id="month"
                              value={filter.month ? years.find(item => item.value === filter.month):null}
                              onChange={(e, value) => this.onFieldChange(e, 'month', value ? value.value:null)}
                              renderInput={params => <TextField {...params} label="Year" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                            />
                          </li>
                          <li className="list-inline-item">
                            <Autocomplete
                              options = {nameFilter}
                              getOptionLabel={option => option.label}
                              id="name"
                              value={filter.name ? nameFilter.find(item => item.value === filter.name) :null}
                              onChange={(e, value) => this.onFieldChange(e, 'name', value ? value.value:null)}
                              renderInput={params => <TextField {...params} label="Maintenance Reserve Account" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                            />
                          </li>
                        </ul>
                        {isMobile || isTablet ? null :
                          <div className="cashflow-header">
                            <Grid container spacing={0}>
                              <Grid item md={3}>
                                <span>Month</span>
                              </Grid>
                              <Grid item md={3}>
                                <span>Maintenance Reserve Account</span>
                              </Grid>
                              <Grid item md={2}>
                                <span>Credit</span>
                              </Grid>
                              <Grid item md={2}>
                                <span>Debit</span>
                              </Grid>
                              <Grid item md={2}>
                                <span style={{float:'right'}}>Balance</span>
                              </Grid>
                            </Grid>
                          </div> }
                      </div>
                      <InfiniteScroll
                        dataLength={ cashflowList.length}
                        next={() => this.moveNextPage()}
                        hasMore={hasMore}
                        loader={<h4 style={{textAlign: 'center'}}>Loading...</h4>}
                        endMessage={
                          <p style={{textAlign: 'center', padding:'5px'}}>
                            <b>Yay! You have seen it all</b>
                          </p>
                        }
                        scrollableTarget="scrollableDiv"
                      >
                        <div>
                          { cashflowList.map((item, index) =>
                            <CashflowList
                              cashflowList={cashflowList}
                              item={item}
                              index={index}
                            />
                          )}
                        </div>
                      </InfiniteScroll>
                    </Paper>
                  </div>
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({modal:false, filter:{}})}>Okay</Button>
            </DialogActions>
          </Dialog>:null
        }
        {pageLoader ? <PageLoader /> : null}
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(MrCashflow));
