 import React, { useState, Fragment } from 'react';
 import NumericLabel from 'react-pretty-numbers';
import { Grid, TextField, Paper, InputAdornment, Tooltip } from '@material-ui/core';
import { isMobile, isTablet } from 'react-device-detect';
import { TableListComp } from '../../../shared_elements';
import ProjectionEvent from './ProjectionEvent';
import { projectionEventHd } from '../';
import moment from 'moment';
import { displayDateFormatShort } from '../../../constants';
import { getCurrencyFormat, getNumberDecimalFormat } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel'
import { regexConstants } from '../../../constants/regEx';
import InfoIcon from '@material-ui/icons/Info';
const AssemblyEventInfo = ({assemblyType,log_id, assemblyInfo, assemblyInfoSimulated, checkYr='', simulationType, onFieldChange, financeError, updateErrorField, updateEventsErrorField, generalInfo}) => {
  const [flag, setFlag] = useState(true);
  let events = Object.assign({}, assemblyInfo.events);
  if(flag){
    Object.keys(events).map((label, index) => {
      if(index !== 0){
        delete events[label]
      }
    })
  }

  function toggleViewMore(){
    if(!flag){
      trackActivity(`View More Clicked of ${assemblyType.toUpperCase()}`, {page_title: 'Analyze MR Cashflow', log_id:log_id})
    }
    setFlag(flag ? false:true);
  }

  return(
    <div className="mnt-table-list">
      { Object.keys(assemblyInfo.events).length > 1 ?
        <span size="small" className="view-more-events" onClick={() => toggleViewMore()}>{ flag ? 'View More Events':'View Less'}</span>:null
      }
      <Paper className="table-mn-hd">
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} sm={3}>{isMobile || isTablet ?<h6 className="assembly-event-hd-mobile">Maintenance Events</h6>:null}{assemblyInfo.name}</Grid>
          <Grid item xs={12} md={3} sm={3}>{isMobile || isTablet ?<h6 className="assembly-event-hd-mobile">MR Rate</h6>:null}
            { simulationType === '1' ?
              <Fragment>
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{assemblyInfo.mr}</NumericLabel> / {assemblyInfo.mr_interval == "Monthly" ? "Month" :assemblyInfo.mr_interval == "FH" ? "APU H" :assemblyInfo.mr_interval }
              </Fragment>:null
            }
            { simulationType === '2' ?
              <div style={{maxWidth:'200px'}}>
                <TextField
                  id="mr"
                  fullWidth
                  margin="none"
                  error={ financeError && financeError.mr ? true : false}
                  helperText={ financeError && financeError.mr ? financeError.mr : ''}
                  value={assemblyInfo.mr}
                  onFocus={(e) => financeError && financeError.mr ?  updateErrorField(assemblyType, 'mr', '', checkYr) : e.preventDefault()}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'mr', e.target.value, assemblyType, checkYr):e.preventDefault()}}
                  InputLabelProps={{shrink: true}}
                  InputProps={{
                    startAdornment: <InputAdornment  position="start">$</InputAdornment>,
                    endAdornment: <InputAdornment position="end">/ {assemblyInfo.mr_interval == "Monthly" ? "Month" :assemblyInfo.mr_interval == "FH" ? "APU H" :assemblyInfo.mr_interval }</InputAdornment>
                  }}
                />
              </div>:null
            }
            { simulationType === '3' ?
              <Fragment>
                { assemblyInfo.mr === assemblyInfoSimulated.mr ?
                  <Fragment>
                    <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{assemblyInfo.mr}</NumericLabel> / {assemblyInfo.mr_interval == "Monthly" ? "Month" : assemblyInfo.mr_interval == "FH" ? "APU H" :assemblyInfo.mr_interval}
                  </Fragment>:
                  <div className="differentiation-values">
                    <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{assemblyInfoSimulated.mr}</NumericLabel> / {assemblyInfo.mr_interval =="Monthly" ? "Month" : assemblyInfo.mr_interval == "FH" ? "APU H":assemblyInfo.mr_interval}</h6>
                    <p><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{assemblyInfo.mr}</NumericLabel> / {assemblyInfo.mr_interval == "Monthly" ? "Month" : assemblyInfo.mr_interval == "FH" ? "APU H":assemblyInfo.mr_interval}</p>
                  </div>
                }
              </Fragment>:null
            }
          </Grid>
          <Grid item xs={6} md={3}>{isMobile?<h6 className="assembly-event-hd-mobile">MR Rate Esc.(Annual)</h6>:null}
            { simulationType === '1' ?
              <Fragment><NumericLabel params={getNumberDecimalFormat()}>{assemblyInfo.escalation_percentage}</NumericLabel>%</Fragment>:null
            }
            { simulationType === '2' ?
              <div style={{maxWidth:'200px'}}>
                <TextField
                  id="escalation_percentage"
                  fullWidth
                  margin="none"
                  value={assemblyInfo.escalation_percentage}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ?  onFieldChange(e, 'escalation_percentage', e.target.value, assemblyType, checkYr):e.preventDefault()}}
                  InputLabelProps={{shrink: true}}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </div>:null
            }
            { simulationType === '3' ?
              <Fragment>
                { assemblyInfo.escalation_percentage === assemblyInfoSimulated.escalation_percentage ?
                  <Fragment><NumericLabel params={getNumberDecimalFormat()}>{assemblyInfo.escalation_percentage}</NumericLabel>%</Fragment>:
                  <div className="differentiation-values">
                    <h6><NumericLabel params={getNumberDecimalFormat()}>{assemblyInfoSimulated.escalation_percentage}</NumericLabel>%</h6>
                    <p><NumericLabel params={getNumberDecimalFormat()}>{assemblyInfo.escalation_percentage}</NumericLabel>%</p>
                  </div>
                }
              </Fragment>:null
            }
          </Grid>
          <Grid item xs={6} md={3}>{isMobile?<h6 className="assembly-event-hd-mobile">Event Cost Esc.(Annual)</h6>:null}
            { simulationType === '1' ?
              <Fragment><NumericLabel params={getNumberDecimalFormat()}>{assemblyInfo.cost_escalation}</NumericLabel>%</Fragment>:null
            }
            { simulationType === '2' ?
              <div style={{maxWidth:'200px'}}>
                <TextField
                  id="cost_escalation"
                  fullWidth
                  margin="none"
                  value={assemblyInfo.cost_escalation}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'cost_escalation', e.target.value, assemblyType, checkYr):e.preventDefault()}}
                  InputLabelProps={{shrink: true}}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </div>:null
            }
            { simulationType === '3' ?
              <Fragment>
                { assemblyInfo.cost_escalation === assemblyInfoSimulated.cost_escalation ?
                  <Fragment><NumericLabel params={getNumberDecimalFormat()}>{assemblyInfo.cost_escalation}</NumericLabel>%</Fragment>:
                  <div className="differentiation-values">
                    <h6><NumericLabel params={getNumberDecimalFormat()}>{assemblyInfoSimulated.cost_escalation}</NumericLabel>%</h6>
                    <p><NumericLabel params={getNumberDecimalFormat()}>{assemblyInfo.cost_escalation}</NumericLabel>%</p>
                  </div>
                }
              </Fragment>:null
            }
          </Grid>
        </Grid>
      </Paper>
      <div>
        <TableListComp
          heads={projectionEventHd}
          Tooltip={true}
          tooltipContent={
            <div>
              Balance as of:<br/>
              Fund Date : {assemblyInfo.fund_date ? moment(assemblyInfo.fund_date).format(displayDateFormatShort):'--' } <br/>
            Fund Amount: {<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{assemblyInfo.fund_amt}</NumericLabel> }
            </div>
          }
          data={Object.keys(events).map((label, index) =>
            <ProjectionEvent
              label={label}
              assemblyType={assemblyType}
              index={index}
              checkYr={checkYr}
              updateEventsErrorField={updateEventsErrorField}
              eventsInfo={assemblyInfo.events}
              eventsInfoSimulated={assemblyInfoSimulated.events}
              simulationType={simulationType}
              financeError={financeError && financeError.events}
              generalInfo={generalInfo}
              onFieldChange={(e, keyParam, value, eventId) => onFieldChange(e, keyParam, value, assemblyType, checkYr, eventId)}
            />
          )}
        />
      </div>
    </div>
  )
}
export default AssemblyEventInfo;
