import React from 'react';
import { Button, TableRow, TableCell, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { isMobile, isTablet } from 'react-device-detect';
import { TableListComp, ExportManu } from '../../../shared_elements';
import { getCurrencyFormat, getLocalStorageInfo, getNumberDecimalFormat } from '../../../utils';
import { workScopeLLPHd } from '../'
import { regexConstants } from '../../../constants/regEx';
const WorkScopeLLP = ({workScopeLLPInfo, name, toggleModalFn, updateLLPToReplaceFn, replacedTheLLPSelectedFn, exportReportFn, onLLPCycleChange, engine, showToastMessage, isLocked}) => {
  let cyclesRemaining =  workScopeLLPInfo.llps.map(item =>  item.cycles_remaining);
  let sortWorkscopeLLPs = workScopeLLPInfo.llps.sort((a,b) => (a.module_sort > b.module_sort) ? 1 : ((b.module_sort > a.module_sort) ? -1 : 0))
  return(
    <Dialog
      open={workScopeLLPInfo.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
      fullScreen
    >
      <DialogTitle id="scroll-dialog-title">
        {isMobile?<h5 style={{fontWeight:'500'}}>Life Limited Part(s) Replacement</h5>:'Life Limited Part(s) Replacement'}<ExportManu title="Export" files={[{title:'Excel', extension: 'xlsx', key:''}]} exportReportFn={(file) => exportReportFn(file.extension)} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <TableListComp
          heads={workScopeLLPHd}
          data={sortWorkscopeLLPs.map((item,index) =>  {
            let style = {}
            if(item.replaced != 1){
              if(item.cycles_remaining < item.cycles_for_next_run )
              style = {
                color:'rgb(189, 34, 56)'
              }
            }
            return (
              <TableRow hover tabIndex={-1} style={Math.min(...cyclesRemaining) == item.cycles_remaining ? {background:'rgb(249 216 216)'}:{}} >
                <TableCell style={{width:'40px'}}>
                  <Checkbox
                    color="primary"
                    disabled={isLocked}
                    checked={item.replaced === 1 ? true:false}
                    onChange={(e) => getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1  ?
                              updateLLPToReplaceFn(e.target.checked ? 1:0, index)
                              : e.preventDefault()
                    }
                   value="LLPStatus" />
                </TableCell>
                <TableCell style={style}>
                  {item.part_name} <br/>
                {item.replaced !=1 && item.cycles_remaining < item.cycles_for_next_run ? <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>Next Run Life will be Restricted </span> : null}
                </TableCell>
                <TableCell style={style}>
                  {item.csn}
                </TableCell >
                <TableCell style={style}>
                  {item.max_limit}
                </TableCell>
                <TableCell style={style}>
                  {item.cycles_remaining}
                </TableCell>
                <TableCell style={style}>
                  { item.cycles_for_next_run}
                </TableCell >
                <TableCell style={style}>{(item.cycles_remaining/item.max_limit*100).toFixed(2)}</TableCell>
                <TableCell align="right" style={style}>
                  { !getLocalStorageInfo().user.permission.mr_calculator.is_saas ?
                    <div>
                      <NumericLabel params={getCurrencyFormat()} >{item.price}</NumericLabel>
                      { item.year_of_cost && workScopeLLPInfo.cost_of_year.length > 1 ?
                        <span> ( as per { item.year_of_cost ?  item.year_of_cost : '2018' } )</span>:null
                      }
                    </div>:null
                  }
                </TableCell>
              </TableRow>
            )
          }
          )}
        />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6}>
            { !getLocalStorageInfo().user.permission.mr_calculator.is_saas ?
              <div>
                {isMobile?<p style={{fontSize:'13px'}}>Total Replacement Cost</p>:<p>Total Replacement Cost</p>}
                <NumericLabel params={getCurrencyFormat({shortFormat:false})} style={isMobile?{fontSize:'14px'}: {}}>
                  { workScopeLLPInfo.llps.reduce((total, next) =>
                    total + (next.replaced === 1 ? next.price:0),0)
                  }
                </NumericLabel>
              </div>:null
            }
          </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <div style={{float:'right'}}>
                  {
                    getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked ?
                    <Button color="primary" onClick={replacedTheLLPSelectedFn} variant="contained">Save</Button>  : null
                  }
                  <Button color="primary" onClick={toggleModalFn}>{isLocked ? 'Close': 'Cancel'}</Button>
                </div>
              </Grid>

        </Grid>
      </DialogActions>
    </Dialog>
  )
}
export default WorkScopeLLP;
