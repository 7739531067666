import React from 'react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { isMobile, isTablet } from 'react-device-detect';
import { Grid } from '@material-ui/core';
import { getNumberDecimalFormat, getCurrencyFormat } from '../../../utils';
import { displayDateFormatShort, monthList } from '../../../constants';
const CashflowList = ({cashflowList, item, index}) => {
  function getmonthsTotal(month){
    let total =  cashflowList.filter(cashflow => cashflow.month === month).reduce((sum, list) => {
      return (sum + list.credit - list.debit)
    } , 0);
    return(
      <div className="monthly-total">
        <p>Monthly Cashflow Total</p>
        <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{total}</NumericLabel>
      </div>
    )
  }
  return(
    <div className="cashflow-list">
      <Grid container spacing={0} className="flex-not-centered cashflow-row">
        <Grid item md={3} sm={12} xs={12} className={`${index !== 0 ? cashflowList[index].month !== cashflowList[index-1].month ? ' para-ui month-block first-card':'first-card':' para-ui month-block first-card'}`}>
          {index !== 0 ? cashflowList[index].month !== cashflowList[index-1].month ? monthList[item.month.slice(4,6)] + ' ' + item.month.slice(0,4):'' : monthList[item.month.slice(4,6)] + ' ' + item.month.slice(0,4)}
          {index !== 0 ? cashflowList[index].month !== cashflowList[index-1].month ? getmonthsTotal(item.month):'' : getmonthsTotal(item.month)}
        </Grid>
        <Grid item md={3} sm={4} className="cash-blk cash-info border-col para-ui flex-centered">
          {isMobile || isTablet?<h6 className="cashflow-event-hd-mobile">Maintenance Reserve Account</h6>:null}
          {item.name}
          {item.date ?
            <p className="event-date">{' Event On: '+ moment(item.date).format(displayDateFormatShort)}</p>:null
          }
        </Grid>
        <Grid item md={2} sm={4} className="cash-blk cash-info para-ui flex-centered">
          {isMobile || isTablet?<h6 className="cashflow-event-hd-mobile">Credit</h6>:null}
          <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{item.credit}</NumericLabel>
        </Grid>
        <Grid item md={2} sm={4} className="cash-blk cash-info para-ui flex-centered">
          {isMobile || isTablet?<h6 className="cashflow-event-hd-mobile">Debit</h6>:null}
          <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{item.debit}</NumericLabel>
        </Grid>
        <Grid item md={2} sm={4} className="cash-blk cash-info para-ui flex-centered">
          {isMobile || isTablet?<h6 className="cashflow-event-hd-mobile">Balance</h6>:null}
          <span style={{marginLeft: "auto",order: "2"}}>
            <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{item.balance}</NumericLabel>
          </span>
        </Grid>
      </Grid>
    </div>
  )
}
export default CashflowList;
