import React from 'react';
import { Button, TextField, CircularProgress, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
export default function ResetPwdForm({error, authInfo, handleChange, onReset, formSubmitLoader}){
  const [values, setValues] = React.useState({
    showPassword: false,
    showConfirmPassword: false
  });
  const handleClickShowPassword = (type) => {
    setValues({ ...values, [type]: !values[type] });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return(
    <form onSubmit={(e) => {e.preventDefault();onReset()}}>
      <TextField
        id="password"
        type={values.showPassword ? 'text' : 'password'}
        label="New Password"
        fullWidth
        margin="normal"
        value={authInfo.password}
        error={error.password ? true:false }
        helperText={error.password}
        InputLabelProps={{shrink: true}}
        InputProps={{
            endAdornment: <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword('showPassword')}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
          }}
        onChange={(e) => handleChange(e.target.value, 'password')}
      />
      <TextField
        id="new-password"
        type={values.showConfirmPassword ? 'text' : 'password'}
        label="Confirm Password"
        fullWidth
        margin="normal"
        value={authInfo.confirm_password}
        error={error.confirm_password ? true:false }
        helperText={error.confirm_password}
        InputLabelProps={{shrink: true}}
        InputProps={{
            endAdornment: <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword('showConfirmPassword')}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
          }}
        onChange={(e) => handleChange(e.target.value, 'confirm_password')}
      />
      <div className="text-center">
        <Button style={{width:'160px', marginTop:'12px'}} variant="contained" color="primary" type="submit" disabled={formSubmitLoader}>{ formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Reset Password' }</Button>
      </div>
    </form>
  )
}
