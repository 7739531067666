import React, { Fragment } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import { LabelValueCard } from '../../../shared_elements';
import { getCurrencyFormat, getNumberDecimalFormat } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const EngineEventInfo = ({engine, simulateEngine, simulationType, label, onFieldChange, financeError, updateErrorEngineField}) => {
  return(
    <div className="engine-basic-info">
      <Grid container spacing={2}>
        <Grid item md={6} sm={6}>
          <h4>Performance Restoration Shop Visit ( PRSV )</h4>
          <Grid container spacing={3}>
            { simulationType === '1' ?
              <LabelValueCard xs={6} md={4} label='MR Rate'
                value={
                  <Fragment>
                    <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.engine_mr}</NumericLabel> / {engine.prsv_mr_interval}
                  </Fragment>
                }
              />: null
            }
            { simulationType === '2' ||  simulationType === '3' ?
              <Grid item xs={12} md={4}>
                { simulationType === '2' ?
                  <TextField
                    id="engine_mr"
                    label="MR Rate"
                    fullWidth
                    margin="none"
                    value={engine.engine_mr}
                    InputLabelProps={{shrink: true}}
                    error={financeError && financeError.engine_mr ? true : false}
                    helperText={financeError && financeError.engine_mr ? financeError.engine_mr :''}
                    onFocus={(e) => financeError && financeError.engine_mr ?  updateErrorEngineField('engine_mr','',label) : e.preventDefault()}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'engine_mr', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: <InputAdornment position="end">/ {engine.prsv_mr_interval}</InputAdornment>
                    }}
                  />:null
                }
                { simulationType === '3' ?
                <Fragment>
                  <label className="simulate-label">MR Rate</label><br/>
                  { engine.engine_mr === simulateEngine.engine_mr ?
                    <Fragment>
                      <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.engine_mr}</NumericLabel> / {engine.prsv_mr_interval}
                    </Fragment>
                    :
                    <div className="differentiation-values">
                      <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{simulateEngine.engine_mr}</NumericLabel> / {engine.prsv_mr_interval}</h6>
                      <p><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.engine_mr}</NumericLabel>/ {engine.prsv_mr_interval}</p>
                    </div>
                  }
                </Fragment>:null
                }
              </Grid>:null
            }
            { simulationType === '1' ?
              <LabelValueCard
                md={4}
                xs={6}
                label='MR Rate Esc.(Annual)'
                value={
                  <Fragment>
                    <NumericLabel params={getNumberDecimalFormat()}>{engine.engine_escalation}</NumericLabel>%
                  </Fragment>
                }
              />
              : null
            }
            { simulationType === '2' || simulationType === '3' ?
              <Grid item xs={12} md={4}>
                { simulationType === '2' ?
                  <TextField
                    id="engine_escalation"
                    label="MR Rate Esc.(Annual)"
                    fullWidth
                    margin="none"
                    value={engine.engine_escalation}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'engine_escalation', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    <label className="simulate-label">MR Rate Esc.(Annual)</label><br/>
                    { engine.engine_escalation ===  simulateEngine.engine_escalation ?
                      <Fragment>
                        <NumericLabel params={getNumberDecimalFormat()}>{engine.engine_escalation}</NumericLabel>%
                      </Fragment>
                       :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getNumberDecimalFormat()}>{simulateEngine.engine_escalation}</NumericLabel>%</h6>
                        <p><NumericLabel params={getNumberDecimalFormat()}>{engine.engine_escalation}</NumericLabel>%</p>
                      </div>
                    }
                  </Fragment>:null
                }
              </Grid>:null
            }
            { simulationType === '1' ?
              <LabelValueCard
                md={4}
                label='Cost Esc.(Annual)'
                value={
                  <Fragment>
                    <NumericLabel params={getNumberDecimalFormat()}>{engine.prsv_cost_escalation}</NumericLabel>%
                  </Fragment>
                }
              />
              : null
            }
            { simulationType === '2' || simulationType === '3' ?
              <Grid item xs={12} md={4}>
                { simulationType === '2' ?
                  <TextField
                    id="prsv_cost_escalation"
                    label="Cost Esc.(Annual)"
                    fullWidth
                    margin="none"
                    value={engine.prsv_cost_escalation}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'prsv_cost_escalation', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    <label className="simulate-label">Cost Esc.(Annual)</label><br/>
                    { engine.prsv_cost_escalation ===  simulateEngine.prsv_cost_escalation ?
                      <Fragment>
                        <NumericLabel params={getNumberDecimalFormat()}>{engine.prsv_cost_escalation}</NumericLabel>%
                      </Fragment> :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getNumberDecimalFormat()}>{simulateEngine.prsv_cost_escalation}</NumericLabel>%</h6>
                        <p><NumericLabel params={getNumberDecimalFormat()}>{engine.prsv_cost_escalation}</NumericLabel>%</p>
                      </div>
                    }
                  </Fragment>:null
                }
              </Grid>:null
            }
          </Grid>
        </Grid>
        <Grid item md={6} sm={6}>
          <h4>LLP Replacement Shop Visit</h4>
          <Grid container spacing={3}>
            { simulationType === '1' ?
              <LabelValueCard xs={6} md={4} label='MR Rate'
                value={
                  <Fragment>
                    <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.llp_mr}</NumericLabel> / {engine.llp_mr_interval}
                  </Fragment>
                }
            /> :null
          }
            { simulationType === '2' || simulationType === '3' ?
              <Grid item xs={6} md={4}>
                { simulationType === '2' ?
                  <TextField
                    id="llp_mr"
                    label="MR Rate"
                    fullWidth
                    margin="none"
                    value={engine.llp_mr}
                    InputLabelProps={{shrink: true}}
                    error={financeError && financeError.llp_mr ? true : false}
                    helperText={financeError && financeError.llp_mr ? financeError.llp_mr : ''}
                    onFocus={() => financeError && financeError.llp_mr && updateErrorEngineField('llp_mr','',label)}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'llp_mr', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: <InputAdornment position="end">/ {engine.llp_mr_interval}</InputAdornment>
                    }}
                  />:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    <label className="simulate-label">MR Rate</label><br/>
                    { engine.llp_mr ===  simulateEngine.llp_mr ?
                      <Fragment>
                        <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.llp_mr}</NumericLabel> / {engine.llp_mr_interval}
                      </Fragment>
                       :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{simulateEngine.llp_mr}</NumericLabel> / {engine.llp_mr_interval}</h6>
                        <p><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.llp_mr}</NumericLabel> / {engine.llp_mr_interval}</p>
                      </div>
                    }
                  </Fragment>:null
                }
              </Grid>:null
            }
            { simulationType === '1' ?
              <LabelValueCard
                md={4}
                xs={6}
                label='MR Rate Esc.(Annual)'
                value={
                  <Fragment>
                    <NumericLabel params={getNumberDecimalFormat()}>{engine.llp_escalation}</NumericLabel>%
                  </Fragment>
                }
              />
              :null }
            { simulationType === '2' || simulationType === '3' ?
              <Grid item xs={12} md={4}>
                { simulationType === '2' ?
                  <TextField
                    id="llp_escalation"
                    label="LLP Esc.(Annual)"
                    fullWidth
                    margin="none"
                    value={engine.llp_escalation}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'llp_escalation', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    <label className="simulate-label">LLP Esc.(Annual)</label><br/>
                    { engine.llp_escalation ===  simulateEngine.llp_escalation ?
                      <Fragment>
                        <NumericLabel params={getNumberDecimalFormat()}>{engine.llp_escalation}</NumericLabel>%
                      </Fragment>
                       :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getNumberDecimalFormat()}>{simulateEngine.llp_escalation}</NumericLabel>%</h6>
                        <p><NumericLabel params={getNumberDecimalFormat()}>{engine.llp_escalation}</NumericLabel>%</p>
                      </div>
                    }
                  </Fragment>:null
                }
              </Grid>:null
            }
            { simulationType === '1' ?
              <LabelValueCard
                md={4}
                xs={6}
                label='Cost Esc.(Annual)'
                value={
                  <Fragment>
                    <NumericLabel params={getNumberDecimalFormat()}>{engine.llp_cost_escalation}</NumericLabel>%
                  </Fragment>
                }
              />
              :null
            }
            { simulationType === '2' || simulationType === '3' ?
              <Grid item xs={12} md={4}>
                { simulationType === '2' ?
                  <TextField
                    id="llp_cost_escalation"
                    label="Cost Esc.(Annual)"
                    fullWidth
                    margin="none"
                    value={engine.llp_cost_escalation}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'llp_cost_escalation', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    <label className="simulate-label">Cost Esc.(Annual)</label><br/>
                    { engine.llp_cost_escalation ===  simulateEngine.llp_cost_escalation ?
                      <Fragment>
                        <NumericLabel params={getNumberDecimalFormat()}>{engine.llp_cost_escalation}</NumericLabel>%
                      </Fragment>
                       :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getNumberDecimalFormat()}>{simulateEngine.llp_cost_escalation}</NumericLabel>%</h6>
                        <p><NumericLabel params={getNumberDecimalFormat()}>{engine.llp_cost_escalation}</NumericLabel>%</p>
                      </div>
                    }
                  </Fragment>:null
                }
              </Grid>:null
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default EngineEventInfo;
