import React, { Fragment } from 'react';
import { Grid, Button,Tooltip } from '@material-ui/core';
import 'react-rangeslider/lib/index.css'
import Slider from 'react-rangeslider';
const RegionCustomVariable = ({regionCondition, onChangeRegionCond, updateRegionVariables, adjustRegionVar, toggleAdjustRagionVarFn}) => {
  function handleOnChange(value, label) {
    const selectedValue = regionCondition[label]['ranges'].filter(item => item.order === value);
    onChangeRegionCond(selectedValue[0].id, label);
  }
  return(
    <div>
      <ul className="list-inline action-cta" style={{padding:'8px 0'}}>
        { !adjustRegionVar ?
          <li className="list-inline-item">
            <Button color="primary" variant="outlined" size="small" onClick={toggleAdjustRagionVarFn}>Adjust Region Variables</Button>
          </li>:null
        }
        { adjustRegionVar ?
          <Fragment>
            <li className="list-inline-item">
              <Button color="primary" variant="contained" size="small" onClick={updateRegionVariables}>Apply Customize</Button>
            </li>
            <li className="list-inline-item">
              <Button color="primary" variant="outlined" size="small" onClick={toggleAdjustRagionVarFn}>Cancel</Button>
            </li>
          </Fragment>:null
        }
      </ul>
      <div>
        <Grid container spacing={2}>
          { Object.keys(regionCondition).map((label, index) =>
            <Grid item md={6} sm={12} xs={12} style={{cursor:!adjustRegionVar?'not-allowed':'default'}} className="slider">

                {(() => {
                  const statLabels = regionCondition[label]['ranges'].sort(function(a,b) {return a.order - b.order})
                  var labels = {}
                  statLabels.map(item => {
                    labels = {...labels, [item.order]: item.value}
                  });
                  const value = regionCondition[label]['default'] ? statLabels.filter(item => item.id === regionCondition[label]['default'])[0].order: Object.keys(labels)[0];
                  return(
                      <Fragment >
                        <h6>
                          <span style={{display:'inline-block',maxWidth: window.safari ? "53%":'50%'}}>{regionCondition[label].name}</span>
                          <span style={{float: 'right',display:'inline-block',maxWidth:'40%'}}>{labels[value] ? labels[value] : ''}</span>
                        </h6>
                        <Tooltip title={!adjustRegionVar ?"Please click on 'Adjust Region Variables', to modify." : '' } placement="top" arrow>
                          <span aria-label="add">
                            <Slider
                              value={parseInt(value)}
                              orientation="horizontal"
                              min = {parseInt(Object.keys(labels)[0])}
                              max = {parseInt(Object.keys(labels)[Object.keys(labels).length - 1])}
                              tooltip={false}
                              labels={labels}
                              onChange={(value) => handleOnChange(value, label)}
                              format={regionCondition[label]['ranges'][value]}
                            />
                          </span>
                      </Tooltip>
                      </Fragment>

                  )
                })()}
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  )
}
export default RegionCustomVariable;
