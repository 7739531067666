import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Footer, BackBanner, OTPVerificationForm } from '../components';
import { OTPResendApi, updateAuthInfoState, OTPVerifyApi } from '../apiServices';
import { fieldValidation } from '../../../utils/formValidation';
class Forgot extends Component {
  constructor(props){
    super(props);
    this.OTPResendApi = OTPResendApi.bind(this);
    this.OTPVerifyApi = OTPVerifyApi.bind(this);
    this.updateAuthInfoState = updateAuthInfoState.bind(this);
    this.state = {
      formSubmitLoader: false,
      authInfo: {
        otp:''
      },
      error: {},
      errorCode: {
        otp:{
          0:'',
          1: 'Please enter valid OTP'
        },
        otpObj: {
          required: true,
        }
      }
    }
  }
  onForgot = () => {
    const { errorCode, authInfo } = this.state;
    let validationInputs = {
      otp:errorCode['otp'][fieldValidation({...errorCode['otpObj'], fieldval: authInfo.otp})],
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.OTPVerifyApi(this.props, this.state.authInfo);
    }else{
      this.setState({error: validationInputs});
    }
  }
  handleChange = (value, key) => {
    this.updateAuthInfoState(value, key);
  }
  render(){
    const { error, authInfo, formSubmitLoader } = this.state;
    return(
      <Fragment>
        <BackBanner>
          <div className="auth-form-wrapper">
            <Link to="/login">← Back to Login</Link>
            <h2 className="form-title">2-Step Verification</h2>
            <p className="form-para">A one time password has been sent to your email address</p>
            <OTPVerificationForm
              error={error}
              authInfo={authInfo}
              handleChange={this.handleChange}
              onForgot={this.onForgot}
              formSubmitLoader={formSubmitLoader}
              resendOtpfn={() => this.OTPResendApi(this.props, {email: localStorage.getItem('userEmail')})}
            />
          </div>
        </BackBanner>
        <Footer />
      </Fragment>
    )
  }
}

export default withSnackbar(Forgot);
