const env = process.env.REACT_APP_ENV
export const appConfig = {
  api: {
    networkInterface: ({
      development: 'https://dev.beta.sparta.aero:8222/',
      staging: 'https://qa.mr-derate-test.sparta.aero:8227/',
      production: 'https://api.sparta.aero/',
    })[env],
    // add more here
    s3ImageUrl: ({
      development: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
      staging: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
      production: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
    })[env],
    appUrl: ({
      development: 'https://develop.sparta.aero',
      staging: 'https://qa.beta.sparta.aero',
      production: 'https://sparta.aero',
    })[env]
  },
  domain:{
    subDomian:({
      development: 'https://dev.beta.sparta.aero/',
      staging: 'https://qa.beta.sparta.aero/',
      production: 'https://mr-calculator.sparta.aero/',
    })[env]
  }
}

export default appConfig
