import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
const AppliedFilter = ({filter, filterMenu, removeFilter}) => {
  const getFilterValue = (keyParam) => {
    if(filterMenu[keyParam].inputType === 'text'){
      return (
        <li className="list-inline-item flex-centered">
          <span className="filter-label">{filterMenu[keyParam].title}:</span>
          <span className="filter-data">{filter[keyParam]}</span>
          <span className="remove-filter" onClick={() => removeFilter(keyParam)}>
            <CancelIcon fontSize="small" />
          </span>
        </li>
      )
    }else if (filterMenu[keyParam].inputType === 'dropdown') {
      if(filterMenu[keyParam].multiple){
        let option = filterMenu[keyParam].options.filter(item =>   filter[keyParam].split(',').includes(item[filterMenu[keyParam].valueKey].toString()));
        return(
          <li className="list-inline-item flex-centered">
            <span className="filter-label">{filterMenu[keyParam].title}:</span>
            {option.map((item, index) =>
              <span className="filter-data"> {item[filterMenu[keyParam].labelKey]} { index === option.length-1 ? '':','}</span>
            )}
            <span className="remove-filter" onClick={() => removeFilter(keyParam)}>
              <CancelIcon fontSize="small" />
            </span>
          </li>
        )
      }else{
        let option = filterMenu[keyParam].options.find(item =>  item[filterMenu[keyParam].valueKey] === '1');
        return (
          <li className="list-inline-item flex-centered">
            <span className="filter-label">{filterMenu[keyParam].title}:</span>
            <span className="filter-data">
              {option[filterMenu[keyParam].labelKey]}
            </span>
            <span className="remove-filter" onClick={() => removeFilter(keyParam)}>
              <CancelIcon fontSize="small" />
            </span>
          </li>
        )
      }
    }else if (filterMenu[keyParam].inputType === 'date') {
      return(
        <li className="list-inline-item flex-centered">
          <span className="filter-label">{filterMenu[keyParam].title}:</span>
          <span className="filter-data">
            {filter[keyParam][filterMenu[keyParam].firstKey]}-
            {filter[keyParam][filterMenu[keyParam].lastKey]}
          </span>
          <span className="remove-filter" onClick={() => removeFilter(keyParam)}>
            <CancelIcon fontSize="small" />
          </span>
        </li>
      )
    }
  }
  return(
    <div className="filter-applied">
      <ul className="list-inline">
        {Object.keys(filter).map((keyParam, index) =>  getFilterValue(keyParam))}
      </ul>
    </div>
  )
}
export default AppliedFilter;
