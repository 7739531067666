import React, {Component, Fragment} from 'react';
import { Drawer, Grid, Container, Avatar, Paper, TextField, Tooltip, IconButton, Link } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MainNavLink from './MainNavLink';
import ReportAnIssue from './ReportAnIssue';
import PageLoader from './PageLoader';
import { lessorUpdateApi } from '../../application/auth/apiServices';
import { getLocalStorageInfo,eraseGlobalCookie, clearCookie } from '../../utils';
import { getFaqsListApi } from '../apiServices';
import FAQsList from './FAQsList';
import { trackLogOut } from '../../utils/mixpanel'
import Cookies from 'universal-cookie'
import config from '../../config';
const cookies = new Cookies();
class MobileHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      left:false,
      formSubmitLoader:false,
      faqsList:[],
      faqsModal:false
    }
    this.lessorUpdateApi = lessorUpdateApi.bind(this)
    this.getFaqsListApi = getFaqsListApi.bind(this)
  }
  logout = () => {
    localStorage.clear();
    clearCookie('lessorAccess', '.sparta.aero', '/')
    eraseGlobalCookie('lessorAccess')
    eraseGlobalCookie('domain')
    cookies.remove('lessorAccess' , {path: "/", domain: "sparta.aero"})
    trackLogOut()
    window.location.reload()
  }
  render() {
    const { left, formSubmitLoader, faqsModal, faqsList } = this.state
    let baseUrl = config.api.appUrl;
    return (
      <div>
        {formSubmitLoader ? <PageLoader /> : null}
        <header className="mobile-header">
          <Container maxWidth={false}  style={{padding:'0px 15px'}}>
            <Grid container  spacing={0} alignItems="center" justify="center">
              <Grid item xs={12}>
                <MenuIcon
                  color="primary"
                  onClick={() =>  this.setState({left:true})}
                />
              <Link onClick={() => this.props.history.push('/mr-calculator/logs')}>
                    <img src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png" alt="Sparta" style={{width:'35px', paddingLeft:'10px'}} />
                </Link>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Tooltip title="What/'s New'" arrow>
                      <a href={`${baseUrl}/whats-new`} target="_blank">
                        <IconButton size="small" color="primary">
                          <FiberNewIcon />
                        </IconButton>
                    </a>
                    </Tooltip>
                  </li>
                  <li className="list-inline-item">
                    <ReportAnIssue type="head" />
                  </li>
                  <li className="list-inline-item">
                    <Tooltip title="FAQs" arrow>
                        <IconButton className="faq-icon" size="small" color="primary" onClick={() => this.getFaqsListApi(this.props) }>
                          <LiveHelpIcon />
                        </IconButton>
                    </Tooltip>
                  </li>
                  <li className="list-inline-item">
                    <Tooltip title="Notifications" arrow>
                      <a href={`${baseUrl}/notifications`} target="_blank">
                        <IconButton size="small" color="primary">
                          <NotificationsActiveIcon />
                        </IconButton>
                      </a>
                    </Tooltip>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </header>
        <Drawer open={left} onClose={() =>  this.setState({left: false})}>
          <div className="mobile-main-navs" style={{width: '280px'}}>
            <Paper className="user-info">
              <div className="user-card">
                <Avatar className="user-avatar" alt={getLocalStorageInfo().user.name} src={getLocalStorageInfo().user.profile_pic} />
                <div>
                  <h3>
                    {getLocalStorageInfo().user.name}
                    <span onClick={() => this.logout()  } style={{float:'right'}}><SettingsPowerIcon color="primary" /></span>
                  </h3>
                  <span>{getLocalStorageInfo().user.designation?getLocalStorageInfo().user.designation:''}</span>
                </div>
              </div>
              <Autocomplete
                options = {getLocalStorageInfo().lessor_list}
                clearOnBlur={false}
                getOptionLabel={option => option.name}
                disableClearable={true}
                filterSelectedOptions={true}
                value={{id:getLocalStorageInfo().defaultLessor.id, name: getLocalStorageInfo().defaultLessor.name}}
                onChange={(e, data) => {data ? this.lessorUpdateApi(this.props, {lessor_id:data.id}) :e.preventDefault()}}
                renderInput={params => <TextField {...params} label="Accessing Lessor" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
              />
            </Paper>
            <Paper className="mobile-py-link" elevation={0}>
              <MainNavLink type="mobile" />
            </Paper>
          </div>
        </Drawer>
        { faqsModal ?
            <FAQsList medialLink={this.state.medialLink} toggleModalFn={() => this.setState({faqsModal:false})} modal={faqsModal} faqsList={faqsList} />:null
          }
        <div style={{height:'50px'}}></div>

      </div>
    );
  }
}
export default withRouter(MobileHeader);
