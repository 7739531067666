import React from 'react';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils';
const FundCard = ({title, amount}) => {
  return(
    <div className="fund-card">
      <p>{title}</p>
      <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{amount}</NumericLabel></h4>
    </div>
  )
}
export default FundCard;
