import React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { imgStoragePath } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
require('highcharts/modules/exporting')(Highcharts);
const AssemblyGraph = ({type, color, lineColor, plots, plotsSimulated, events, newevents, simulationType, graphLabel}) => {
  let data = [], newdata = [], eventFlags = [], neweventFlags = [],newMarker=[];
  let url = ''
  if(type === 'airframe'){
    url = `url(${imgStoragePath}airframe_marker.png)`;
  }else if(type === 'landing_gear'){
    url = `url(${imgStoragePath}mr-calculator/lg_marker.svg)`;
  }else if(type === 'apu'){
    url = `url(${imgStoragePath}mr-calculator/apu_marker.svg)`;
  }else if(type === 'engines'){
    url = `url(${imgStoragePath}engines_marker.png)`;
  }else if(type === 'low'){
    url = `url(${imgStoragePath}engines_marker.png)`;
  }
  if(type === 'engines'){
    Object.keys(events).map(event => {
      let firstKey = Object.keys(events[event])[0]
      eventFlags.push(moment(events[event][firstKey].eventDate).format('YYYYMM'));
    })
  }else{
    Object.keys(events).map(event => {
      eventFlags.push(moment(events[event].eventDate).format('YYYYMM'));
    });
  }
  Object.keys(plots).map(year => {
    let obj = {};
    if(eventFlags.indexOf(year) !== -1 ){
      obj = {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: plots[year], marker: {symbol: url, width: 30, height: 30, enabled: true}};
    }else{
      obj = {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: plots[year]};
    }
    data.push(obj);
  });
  if(simulationType === '3'){
    if(type === 'engines'){
      Object.keys(newevents).map(event => {
        let firstKey = Object.keys(newevents[event])[0]
        neweventFlags.push(moment(newevents[event][firstKey].eventDate).format('YYYYMM'));
      })
    }else{
      Object.keys(newevents).map(event => {
        neweventFlags.push(moment(newevents[event].eventDate).format('YYYYMM'));
      });
    }
    Object.keys(plotsSimulated).map(year => {
      let newobj = {};
      if(neweventFlags.indexOf(year) !== -1){
        newobj = {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: plotsSimulated[year], marker: {symbol: url, width: 30, height: 30, enabled: true}};
      }else{
        newobj = {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: plotsSimulated[year]};
      }
      newdata.push(newobj);
    });
  }
  let options = {
    chart: {
      type: 'area',
      zoomType: 'x',
      marginTop: 60,
      events: {
        load: function() {
          if(graphLabel){
            var label = this.renderer.label( graphLabel  + "<br/>Lessor: " + getLocalStorageInfo().defaultLessor.name)
            .css({
              width: '400px',
              fontSize: '9px'
            })
            .attr({
              'stroke': 'silver',
              'stroke-width': 1,
              'r': 2,
              'padding': 5,
            })
            .add();
            label.align(Highcharts.extend(label.getBBox(), {
              align: 'left',
              x: 0, // offset
              verticalAlign: 'bottom',
              y: -340 // offset
            }), null, 'spacingBox');
          }
        }
      }
    },
    title: { text: null },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Asset Life ( Year )'
      }
    },
    yAxis:{
      title: {
        text: 'Maintenance Reserve Fund `000 ( USD)'
      },
      labels: {
        formatter: function () {
          return  '$' + this.value/1000;
        }
      }
    },
    tooltip: {
      formatter: function(){
        return customToolTip(this.points)
      },
      shared: true
    },
    credits: {
      enabled: false
    },
    scrollbar: {
      enabled: true
    },
    reflow:true,
    turboThreshold: 10000,
    series:  [
      {
        boostThreshold: 0,
        turboThreshold:0,
        showInLegend: true,
        name: 'Calculated Fund Accruals',
        data: data,
        color: color,
        lineColor: lineColor,
      },
      {
        boostThreshold: 0,
        turboThreshold:0,
        showInLegend: newdata.length ? true:false,
        name: 'Simulated Fund Accruals',
        data: newdata,
        color: '#BAB9D3',
        lineColor: '#150F78',
        points:newMarker
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          text: 'Export',
          menuItems: [
            {
              text: 'Export as PNG',
              onclick: function () {
                this.exportChart();
              }
            },
            {
              text: 'Export as SVG',
              onclick: function () {
                this.exportChart({ type: 'image/svg+xml' });
              }
            },
            {
              text: 'Export as PDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' });
              }
            }
          ]
        }
      }
    }
  };
  function customToolTip(points){
    if(points.length === 1){
      return '<b>Date</b>: ' + moment(points[0].x).format('MMM YYYY') + '<br/><b>Fund</b>: $' + points[0].y;
    }else{
      let tlTip = '';
      points.map(point => {
        tlTip += '<b>' + point.series.name + '</b>:<br/>';
        tlTip += '<b>Date</b>: ' + moment(point.x).format('MMM YYYY') + '<br/>';
        tlTip += '<br/><b>Fund</b>: $' +  point.y + '<br/>'
      });
      return tlTip
    }
  }
  return(
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}
export default AssemblyGraph;
