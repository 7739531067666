import React from 'react'
import App from './App'
import ErrorPage from './shared_elements/components/ErrorPage'

export default class ErrorBoundary extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      error: false
    }
  }

  componentDidCatch(error){
    this.setState({
      error: true
    })

  }

  render(){
    if(this.state.error){
      return(
        <ErrorPage/>
      )
    }else{
      return(
        <App/>
      )
    }
  }
}
