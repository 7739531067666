import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { getLocalStorageInfo } from '../../../utils';
import { Tabs, Tab, Button, Grid, Paper, Tooltip, Breadcrumbs, Typography } from '@material-ui/core';
import { PageLoader, LabelValueCard } from '../../../shared_elements';
import { AirframeCard, APUCard, LGCard, EngineCard, ShopVisitCard, WorkScopeLLP, WorkScopeModal, LOICalModal, EngineBlendedMr } from '../components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { displayDateFormatShort } from '../../../constants';
import { trackActivity } from '../../../utils/mixpanel'
import lockIcon from '../../../assets/images/NewLockIcon.png';
import unlockIcon from '../../../assets/images/unlockicon.png';
import { getMrLogDetailApi, apuUpdateUsageApi, lgUpdateUsageApi, airframeUpdateUsageApi, cancelUsageUpdateApi, engineUsageCalApi, carryOverNSVApi, selectLLPToReplaceApi, replacedTheLLPSelectedApi, changeWorkScopeApi, exportLLPListApi, updateMrRateShopVisitApi, validateUsageApi, lgUpdateUsageFn, apuUpdateUsageFn, engineUsageCalFn, updateLLpCycleLimitApi, updateLockApi} from '../apiServices';
import { genericErrorCode } from '..';
class IntermediateMrCal extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      type:'view',
      typeEngine:'view',
      tabIndex:0,
      mrLogDetail:{},
      error:{},
      airEngineError:{},
      genericError:{},
      workScopeInfo:{
        modal:false,
        data:{}
      },
      workScopeLLPInfo:{
        modal:false,
        llps:[]
      },
      derate:{},
      airframeEdit:false,
      apuEdit:false,
      landingGearEdit:false,
      enginesEdit:false,
      ShopVisitCardEdit:'view',
      apuInfoPopup:false,
      lgInfoPopup:false,
      airframeInfoPopup:false,
      editedSvCardFlag:false,
      lockEdit:false,
      warningModal:false
    }
    this.getMrLogDetailApi = getMrLogDetailApi.bind(this);
    this.apuUpdateUsageApi = apuUpdateUsageApi.bind(this);
    this.apuUpdateUsageFn = apuUpdateUsageFn.bind(this);
    this.lgUpdateUsageApi = lgUpdateUsageApi.bind(this);
    this.lgUpdateUsageFn = lgUpdateUsageFn.bind(this);
    this.airframeUpdateUsageApi = airframeUpdateUsageApi.bind(this);
    this.cancelUsageUpdateApi = cancelUsageUpdateApi.bind(this);
    this.carryOverNSVApi = carryOverNSVApi.bind(this);
    this.selectLLPToReplaceApi = selectLLPToReplaceApi.bind(this);
    this.replacedTheLLPSelectedApi = replacedTheLLPSelectedApi.bind(this);
    this.changeWorkScopeApi = changeWorkScopeApi.bind(this);
    this.exportLLPListApi = exportLLPListApi.bind(this);
    this.engineUsageCalApi = engineUsageCalApi.bind(this);
    this.engineUsageCalFn = engineUsageCalFn.bind(this);
    this.updateMrRateShopVisitApi = updateMrRateShopVisitApi.bind(this);
    this.validateUsageApi = validateUsageApi.bind(this);
    this.updateLLpCycleLimitApi = updateLLpCycleLimitApi.bind(this);
    this.updateLockApi = updateLockApi.bind(this);
  }
  componentDidMount(){
    this.getMrLogDetailApi(this.props);
    trackActivity('Viewed Log', {page_title: `Intermediate`, log_id: this.props.match.params.id})
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  updateGenericErrorField = (key,message) => {
      this.setState(prevState => ({
        ...prevState,
        genericError:{
          ...prevState.genericError,
            [key]: message
        }
      }))
  }
  updateAirEngineError = (label, key, message) => {
    this.setState(prevState =>({
      ...prevState,
      airEngineError:{
        ...prevState.airEngineError,
        [label]:{
          ...prevState.airEngineError[label],
          [key]:message
        }
      }
    }))
  }
  onFieldChange = (e, keyParam, value, tableType, eventType) => {
    const { mrLogDetail } = this.state;
    if(['apu','landingGear'].includes(tableType)){
      if(keyParam == "nextShopvisitDate"){
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail:{
            ...prevState.mrLogDetail,
            [tableType]:{
              ...prevState.mrLogDetail[tableType],
              [keyParam]:value,
              nsv_update:true
            }
          }
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail: {
            ...prevState.mrLogDetail,
            [tableType]: {
              ...prevState.mrLogDetail[tableType],
              [keyParam]:value
            }
          }
        }));
      }

    }else if (['engines','airframe'].includes(tableType)) {
      if(tableType === 'engines' && keyParam === 'hour_util'){
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail: {
            ...prevState.mrLogDetail,
            [tableType]: {
              ...prevState.mrLogDetail[tableType],
              [eventType]: {
                ...prevState.mrLogDetail[tableType][eventType],
                [keyParam]:value,
                cycle_util: value ? Math.round(value/mrLogDetail.generalInfo.ratio) :0
              }
            }
          }
        }));
      }else if (tableType === 'engines' && keyParam ==='engineLLPStatus' && value) {
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail: {
            ...prevState.mrLogDetail,
            [tableType]: {
              ...prevState.mrLogDetail[tableType],
              [eventType]: {
                ...prevState.mrLogDetail[tableType][eventType],
                [keyParam]:value,
                llpLimits: []
              }
            }
          }
        }));
      }else if (keyParam == "nextShopvisitDate") {
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail:{
            ...prevState.mrLogDetail,
            [tableType]:{
              ...prevState.mrLogDetail[tableType],
              [eventType]:{
                ...prevState.mrLogDetail[tableType][eventType],
                [keyParam]:value,
                nsv_update:true
              }
            }
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail: {
            ...prevState.mrLogDetail,
            [tableType]: {
              ...prevState.mrLogDetail[tableType],
              [eventType]: {
                ...prevState.mrLogDetail[tableType][eventType],
                [keyParam]:value
              }
            }
          }
        }));
      }
    }
  }
  updateLLPToReplaceFn = (flag, index) => {
    this.setState(prevState => ({
      ...prevState,
      workScopeLLPInfo:{
        ...prevState.workScopeLLPInfo,
        llps: prevState.workScopeLLPInfo.llps.map((llp, llpIndex) => llpIndex === index ? {...llp, replaced: flag} :llp)
      }
    }))
  }

  handleChange = (event, newValue) => {
    this.setState({tabIndex:newValue, error:{}})
  }
  updateCustomLLPCyclesFn = (engine_no, customizeLLP, engine, customCycleRemain) => {
    let check = []
    Object.keys(customCycleRemain).map(cust => {
      let csnArr = Object.keys(customCycleRemain).map(item => customCycleRemain[item].csn)
      let LLPStatus = false
      check = csnArr.map(csn =>  csn == (engine.csn - engine.csn_at_lsv))
      return check
    })
    this.updateLLpCycleLimitApi(this.props, engine, engine_no, customizeLLP, check)
  }
  onLLPCycleChange = (id, keyParam, value) => {
    const { workScopeLLPInfo } = this.state;
    let llp = workScopeLLPInfo.llps.find(item => item.id === id);
    switch (keyParam) {
      case 'csn':
        llp = {
          ...llp,
          cycles_remaining:llp.max_limit-value,
          [keyParam]:value
        }
        break;
      case 'max_limit':
        llp = {
          ...llp,
          cycles_remaining:value-llp.csn,
          [keyParam]:value
        }
        break;
      case 'cycles_remaining':
        llp = {
          ...llp,
          csn:llp.max_limit-value,
          [keyParam]:value
        }
        break;
      default:

    }
    this.setState(prevState => ({
      ...prevState,
      workScopeLLPInfo: {
        ...prevState.workScopeLLPInfo,
        llps: prevState.workScopeLLPInfo.llps.map((item) => item.id === id ? llp:item)
      }
    }))
  }
  updateMrShopVisit = (e,key, value, engineIndex, mrType, shopVisit, wkIndex, workScopeParam) => {
    this.setState(prevState => ({
      ...prevState,
      mrLogDetail:{
        ...prevState.mrLogDetail,
        engines:{
          ...prevState.mrLogDetail.engines,
          [engineIndex]:{
            ...prevState.mrLogDetail.engines[engineIndex],
            sv_update:true,
            [mrType]:{
              shop_visit:shopVisit,
              mr:parseFloat(value),
            },
            workscopes:{
              ...prevState.mrLogDetail.engines[engineIndex]['workscopes'],
              [wkIndex]:{
                ...prevState.mrLogDetail.engines[engineIndex]['workscopes'][wkIndex],
                [workScopeParam]:value
              }
            }
          }
        }
      }
    }))
  }
  shopVisitWarningUpdate = (engine) => {
    const { mrLogDetail } = this.state
    this.setState(prevState => ({
      ...prevState,
      warningModal:false,
      mrLogDetail:{
        ...prevState.mrLogDetail,
        engines:{
          ...prevState.mrLogDetail.engines,
          [engine]:{
            ...prevState.mrLogDetail.engines[engine],
            edited_sv_card: false
          }
        }
      }
    }), () =>  this.engineUsageCalApi(this.props,{...mrLogDetail.engines[engine],edited_sv_card:false },engine ))
  }
  render(){
    const { tabIndex, airframeEdit, apuEdit, landingGearEdit, enginesEdit, type, mrLogDetail, workScopeInfo, workScopeLLPInfo, pageLoader, error, genericError, typeEngine, airEngineError, ShopVisitCardEdit, apuInfoPopup, lgInfoPopup, airframeInfoPopup, editedSvCardFlag, lockEdit, warningModal } = this.state;
    let engineNSVFlag = [];
    if(Object.keys(mrLogDetail) && mrLogDetail.engines){
      Object.keys(mrLogDetail.engines).map((label, index) => {
        if(!mrLogDetail.engines[label].workscopes[Object.keys(mrLogDetail.engines[label].workscopes)[0]].nsv){
          engineNSVFlag.push(index+1);
        }
      });
    }
    console.log('mrLogDetail', mrLogDetail);
    const flagCheck =`${[{type:'Airframe', flag:airframeEdit},{type:' APU',flag:apuEdit},{type:' Engine',flag:enginesEdit},{type:' Landing Gear', flag:landingGearEdit}].filter(item => item.flag == true).map(item => item.type).join()} informations are not updated`
    return(
      <div className="mr-calculator-section">
        <div className="mr-title">
          <Breadcrumbs aria-label="breadcrumb" style={{fontSize:'16px'}}>
            <Link color="inherit" to="/mr-calculator/logs" >
              <Typography className="flex-centered" component="h1" style={{textDecoration:'underline'}}>
                MR Logs
              </Typography>
            </Link>
            {getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('C') != -1 ?
              <Link color="inherit" to="/mr-calculator/initiate" >
                <Typography className="flex-centered" component="h1" style={{textDecoration:'underline'}}>
                  Generate Finance Report
                </Typography>
              </Link> : null
            }
              <h1 className="flex-centered" color="primary">{this.props.match.params.id}</h1>
          </Breadcrumbs>
        </div>
        <div className="mr-calculator-intermediate">
          <Paper className="mr-log-info">
            <Grid container spacing={0}>
              <Grid item xs={12} sm={8} md={8}>
                <ul className="list-inline">
                  <li className="list-inline-item" style={{cursor: 'pointer'}} >
                    {mrLogDetail.generalInfo &&  mrLogDetail.generalInfo.is_locked ?
                      <Tooltip title="Click to resume editing." placement="top" arrow>
                        <img src={lockIcon} width="35" onClick={() =>  this.updateLockApi(this.props,{log_id:this.props.match.params.id, is_locked:false})} />
                      </Tooltip>
                       :
                       <Tooltip title="Click to lock editing." placement="top" arrow>
                         <img src={unlockIcon} width="35" onClick={() =>  this.updateLockApi(this.props, {log_id:this.props.match.params.id, is_locked:true})}  />
                       </Tooltip>
                    }
                  </li>
                  <li className="list-inline-item">
                    <p>Log ID</p>
                    <h6>{this.props.match.params.id}</h6>
                  </li>
                  { mrLogDetail.generalInfo ?
                    <Fragment>
                      { mrLogDetail.generalInfo.aircraftType && mrLogDetail.generalInfo.aircraftType.name ?
                        <li className="list-inline-item">
                          <p>Aircraft Type</p>
                          <h6>{mrLogDetail.generalInfo.aircraftType.name}</h6>
                        </li>:null
                      }
                      { mrLogDetail && mrLogDetail.generalInfo && mrLogDetail.generalInfo.aircraft_manufacture_date ?
                        <li className="list-inline-item">
                          <p>Date of Manufacture</p>
                          <h6>{moment(mrLogDetail.generalInfo.aircraft_manufacture_date).format(displayDateFormatShort)}</h6>
                        </li>:null
                      }
                    </Fragment>
                    :null
                  }
                  { mrLogDetail.apu && mrLogDetail.apu.apu_type && mrLogDetail.apu.apu_type.name ?
                    <li className="list-inline-item">
                      <p>APU Type</p>
                      <h6>{mrLogDetail.apu.apu_type.name}</h6>
                    </li>:null
                  }
                  { mrLogDetail && mrLogDetail.generalInfo && mrLogDetail.generalInfo.created_at ?
                    <li className="list-inline-item">
                      <p>Created at</p>
                      <h6>{moment(mrLogDetail.generalInfo.created_at).format(displayDateFormatShort)}</h6>
                    </li>:null
                  }
                  { mrLogDetail && mrLogDetail.generalInfo && mrLogDetail.generalInfo.updated_at ?
                    <li className="list-inline-item">
                      <p>Updated at</p>
                      <h6>{moment(mrLogDetail.generalInfo.updated_at).format(displayDateFormatShort)}</h6>
                    </li>:null
                  }
                </ul>
              </Grid>
                <Grid item xs={12} sm={4} md={4} >
                  <ul className="list-inline flex centered" style={{float:'right'}}>
                    <li className="list-inline-item">
                      {Object.keys(mrLogDetail).length ? <Button color="primary" variant="contained" disabled={engineNSVFlag.length || apuEdit || airframeEdit || landingGearEdit || enginesEdit } onClick={() => this.validateUsageApi(this.props)} style={isMobile ? {float:'left', marginTop:'10px'}:{float:'right'}}>Analyze MR Cashflow</Button>:null}
                      <br/>
                      { engineNSVFlag.length ?
                        <span className="update-engine-label" style={{fontSize: '9px', color:'#ff0000', clear:'both', display:'block'}}>Update engine <b>{engineNSVFlag.map((label,index) => engineNSVFlag.length - 1 != index ? '#' + label + ' & ' :'#'+label )}</b> usage  details to Review MR Data</span>
                        : apuEdit || enginesEdit || airframeEdit || landingGearEdit ?
                        <span style={{fontSize: '10px', color:'#ff0000', clear:'both', display:'block', float:'right'}}>{flagCheck}</span>:null }
                    </li>
                  </ul>

                </Grid>

            </Grid>
          </Paper>
          <div className="mr-cal-intermediate-section">
            { mrLogDetail.engines ?
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={2}>
                    <LabelValueCard md={2} xs={6} sm={6} label='Engine Type' value={mrLogDetail.generalInfo.engineType && mrLogDetail.generalInfo.engineType.name ? mrLogDetail.generalInfo.engineType.name:'--' } />
                    <LabelValueCard md={2} xs={6} sm={6} label='Region Of Operation' value={
                      mrLogDetail.location && mrLogDetail.location.address ?
                      mrLogDetail.location.address.length > 20 ? <Tooltip title={mrLogDetail.location.address}><span>{`${mrLogDetail.location.address.substr(0, 20)}...`}</span></Tooltip>:
                      mrLogDetail.location.address:
                      (mrLogDetail.generalInfo.engineType && mrLogDetail.generalInfo.regionOfOperation.name ? mrLogDetail.generalInfo.regionOfOperation.name:'--') }
                    />
                  <LabelValueCard md={2} xs={6} sm={6} label='Utilization Ratio' value={`${mrLogDetail.generalInfo.ratio}:1`} />
                  </Grid>
                  <Tabs
                    value={tabIndex}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons={isMobile ? 'on':'auto'}
                  >
                    { Object.keys(mrLogDetail.engines).map((label, index) =>
                      <Tab label={`Engine #${index+1}`} />
                    )}
                  </Tabs>
                  { Object.keys(mrLogDetail.engines).map((label, index) =>
                    tabIndex === index ?
                    <div>
                      <div style={{marginBottom:'10px'}}>
                        <Grid container spacing={0}>
                          <Grid item xs={12} sm={12} md={6}>
                            <LOICalModal
                              log_id={this.props.match.params.id}
                              engineBasicInfo={{ratio:mrLogDetail.generalInfo.ratio, min_ratio:mrLogDetail.generalInfo.min_ratio, max_ratio:mrLogDetail.generalInfo.max_ratio, engineNo:label}}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <EngineBlendedMr
                        engine={mrLogDetail.engines[label]}
                      />
                    <div style={{marginTop:'10px'}}>
                      { Object.keys(mrLogDetail.engines[label].workscopes).map((wk, wkIndex) =>
                          <div>
                            <ShopVisitCard
                              workScope={mrLogDetail.engines[label].workscopes[wk]}
                              engine={mrLogDetail.engines[label]}
                              showWorkScopeInfoFn={() => this.setState({workScopeInfo:{modal:true, engine_no:label, data:mrLogDetail.engines[label].workscopes[wk]}})}
                              showLLPReplaceFn={() => this.selectLLPToReplaceApi(this.props, {shop_visit_no:Number(wk) + 1,log_id:this.props.match.params.id,engine_no:label}, mrLogDetail.engines[label].workscopes[wk] )}
                              changeWorkScopeFn={(wks, type, selected) => this.changeWorkScopeApi(this.props, {engine_no:label,shop_visit_no:Number(wk)+1, log_id:this.props.match.params.id,workscopes:wks}, type, selected, mrLogDetail.engines[label].workscopes[wkIndex].name)}
                              carryOverNSVFn={(data, type) => this.carryOverNSVApi(this.props, {engine_no:label, log_id:this.props.match.params.id, shop_visit_no:Number(wk) + 1 , ...data}, mrLogDetail.engines[label].workscopes[wk].name, type)}
                              wkIndex={wkIndex}
                              shopVisit={Number(wk)+1}
                              toggleCancelFn={() =>  this.setState({ShopVisitCardEdit:'view'})}
                              onFieldChange={this.updateMrShopVisit}
                              engineIndex={label}
                              updateMrRate={(data, engineIndex, mr, key, shopVisit, type) => this.updateMrRateShopVisitApi(this.props, data,engineIndex, mr, key, shopVisit, mrLogDetail.engines[label].workscopes[wkIndex].name, type)}
                              cancelUsageUpdateFn={(param) => this.cancelUsageUpdateApi(this.props,param)}
                              isLocked={mrLogDetail.generalInfo.is_locked}
                              lockEdit={lockEdit}
                            />
                            { workScopeLLPInfo.modal ?
                              <WorkScopeLLP
                                workScopeLLPInfo={workScopeLLPInfo}
                                engine={mrLogDetail.engines[label]}
                                toggleModalFn={() => this.setState({workScopeLLPInfo:{modal:false, llps:[]}})}
                                updateLLPToReplaceFn={this.updateLLPToReplaceFn}
                                name={mrLogDetail.engines[label].workscopes[wkIndex]}
                                replacedTheLLPSelectedFn={() => this.replacedTheLLPSelectedApi(this.props, workScopeLLPInfo)}
                                exportReportFn={(extension) => this.exportLLPListApi(this.props, {log_id:this.props.match.params.id,engine_no:workScopeLLPInfo.query.engine_no, shop_visit_no:workScopeLLPInfo.query.shop_visit_no, download:extension}, mrLogDetail.engines[label].workscopes[wkIndex].name)}
                                onLLPCycleChange={this.onLLPCycleChange}
                                shopVisit={Number(wk)+1}
                                isLocked={mrLogDetail.generalInfo.is_locked}
                              />:null
                            }
                          </div>
                        )}
                      </div>
                      <EngineCard
                        engine={mrLogDetail.engines[label]}
                        type={typeEngine}
                        onFieldChange={this.onFieldChange}
                        engineIndex={label}
                        updateEngineUsageFn={() => {this.engineUsageCalFn(this.props, mrLogDetail.engines[label], label); this.setState({warningModal:true})}}
                        updateEngineUsageApi={() => this.engineUsageCalApi(this.props, mrLogDetail.engines[label], label)}
                        shopVisitWarningUpdate={() => this.shopVisitWarningUpdate(label)}
                        error={genericError}
                        updateErrorField={this.updateGenericErrorField}
                        customCycleRemainFn={() => this.customCycleRemainApi(this.props)}
                        mrLogDetail={mrLogDetail}
                        updateCustomLLPCyclesFn={this.updateCustomLLPCyclesFn}
                        updateEngineUsageFlag={() => this.setState({enginesEdit:'edit'})}
                        enginesEdit={enginesEdit}
                        toggleEditFn={() => this.setState({enginesEdit:true})}
                        cancelUsageUpdateFn={() => {this.cancelUsageUpdateApi(this.props,'engines'); this.setState({genericError:{}})}}
                        editedSvCardFlag={editedSvCardFlag}
                        closeEditedCard={() =>  this.setState({editedSvCardFlag:false})}
                        viewLlpFn={() => this.setState({enginesEdit:false})}
                        isLocked={mrLogDetail.generalInfo.is_locked}
                        warningModal={warningModal}
                      />

                    </div>:null
                  )}
                </Grid>
                {
                  isMobile ?
                  <Grid item xs={12} >
                  <ul className="list-inline flex centered" style={{width: '100%'}}>
                    <li className="list-inline-item" style={{width: '100%'}}>
                      {Object.keys(mrLogDetail).length ? <Button color="primary" variant="contained" disabled={engineNSVFlag.length || apuEdit || airframeEdit || landingGearEdit || enginesEdit } onClick={() => this.validateUsageApi(this.props)} style={{width:'100%'}}>Analyze MR Cashflow</Button>:null}
                      <br/>
                      { engineNSVFlag.length ?
                        <span className="update-engine-label" style={{fontSize: '9px', color:'#ff0000', clear:'both', display:'block'}}>Update engine <b>{engineNSVFlag.map((label,index) => engineNSVFlag.length - 1 != index ? '#' + label + ' & ' :'#'+label )}</b> usage  details to Review MR Data</span>
                        : apuEdit || enginesEdit || airframeEdit || landingGearEdit ?
                        <span style={{fontSize: '10px', color:'#ff0000', clear:'both', display:'block', float:'right'}}>{flagCheck}</span>:null }
                    </li>
                  </ul>
                </Grid>: null
                }
              </Grid>:null
            }
            <Grid container spacing={2}>
              { mrLogDetail.apu ?
                <Grid item xs={12} sm={12} md={6}>
                  <APUCard
                    type={type}
                    mrLogDetail={mrLogDetail}
                    onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'apu')}
                    genericError={genericError}
                    updateGenericErrorField={this.updateGenericErrorField}
                    apuEdit={apuEdit}
                    toggleEditFn={() => this.setState({apuEdit:true})}
                    apuUpdateUsageFn={() => this.apuUpdateUsageFn(this.props, mrLogDetail.apu)}
                    apuUpdateUsageApi={() => this.apuUpdateUsageApi(this.props, mrLogDetail.apu)}
                    cancelUsageUpdateFn={() => this.cancelUsageUpdateApi(this.props,'apu')}
                    openInfoPopupFlag={() => this.setState({apuInfoPopup:true})}
                    closeInfoPopup={() =>  this.setState({apuInfoPopup:false})}
                    apuInfoPopup={apuInfoPopup}
                    isLocked={mrLogDetail.generalInfo.is_locked}
                  />
                </Grid>:null
              }
              { mrLogDetail.landingGear ?
                <Grid item xs={12} sm={12} md={6}>
                  <LGCard
                    type={type}
                    mrLogDetail={mrLogDetail}
                    onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'landingGear')}
                    genericError={genericError}
                    updateGenericErrorField={this.updateGenericErrorField}
                    landingGearEdit={landingGearEdit}
                    toggleEditFn={() => this.setState({landingGearEdit:true})}
                    lgUpdateUsageFn={() => this.lgUpdateUsageFn(this.props, mrLogDetail.landingGear)}
                    lgUpdateUsageApi={() => this.lgUpdateUsageApi(this.props, mrLogDetail.landingGear)}
                    cancelUsageUpdateFn={() => this.cancelUsageUpdateApi(this.props,'landingGear')}
                    openInfoPopupFlag={() => this.setState({lgInfoPopup:true})}
                    closeInfoPopup={() =>  this.setState({lgInfoPopup:false})}
                    lgInfoPopup={lgInfoPopup}
                    isLocked={mrLogDetail.generalInfo.is_locked}
                  />
                </Grid>:null
              }
              { mrLogDetail.airframe ?
                <Grid item xs={12} sm={12} md={6}>
                  <AirframeCard
                    type={type}
                    mrLogDetail={mrLogDetail}
                    onFieldChange={(e, keyParam, value, eventType) => this.onFieldChange(e, keyParam, value, 'airframe', eventType)}
                    airEngineError={airEngineError}
                    updateAirEngineError={this.updateAirEngineError}
                    airframeEdit={airframeEdit}
                    toggleEditFn={() => this.setState({airframeEdit:true})}
                    airframeUpdateUsageFn={() => this.airframeUpdateUsageApi(this.props, mrLogDetail.airframe, mrLogDetail)}
                    cancelUsageUpdateFn={() => this.cancelUsageUpdateApi(this.props,'airframe')}
                    isLocked={mrLogDetail.generalInfo.is_locked}
                  />
                </Grid>:null
              }
            </Grid>
          </div>
        </div>
        { workScopeInfo.modal ?
          <WorkScopeModal
            workScopeInfo={workScopeInfo}
            toggleModalFn={() => this.setState({workScopeInfo:{modal:false, data:{}}})}
            carryOverNSVFn={(data) => this.carryOverNSVApi(this.props, {engine_no:workScopeInfo.engine_no, log_id:this.props.match.params.id, shop_visit_no:workScopeInfo.data.run_id, ...data})}
          />:null
        }

        { pageLoader ? <PageLoader/>:null }
      </div>
    )
  }
}
export default withSnackbar(IntermediateMrCal);
