import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AuthHeader from './AuthHeader';
export default function BackBanner({children}) {
  return (
    <section className="auth-section">
      <AuthHeader />
      <Container maxWidth="lg">
        <Grid container spacing={0} alignItems="center">
          <Grid item md={6} sm={12}>
            <div className="auth-help-text">
              <h1>Simplifying <br/>Asset Management</h1>
              <p>SPARTA is a digital Asset Manager,<br/>an extension of the human workplace</p>
            </div>
          </Grid>
          <Grid item md={6} sm={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}
