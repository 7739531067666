import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core'
import { imgStoragePath } from '../../constants';
class ErrorPage extends Component{
  constructor(props){
    super(props);
  }
  render(){

    return(
      <Grid container className="error-page flex-centered">
        <Grid item xs={12}>
          <img className="error-image" src={imgStoragePath+'something_went_wrong.png'}/>
          <h2>Oops!! Something Went Wrong.</h2>
          <p>SPARTA has encountered an error. Please try again or contact support@sparta.aero.</p>
          <Button className="go-back-button"  color="primary"><a onClick={() => window.location.reload()}>Go Back</a></Button>
        </Grid>
      </Grid>
    )
  }
}
export default ErrorPage
