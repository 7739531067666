import Login from './containers/Login';
import Forgot from './containers/Forgot';
import Register from './containers/Register';
import Otp from './containers/Otp';
import Reset from './containers/Reset';
import ResetSuccess from './containers/ResetSuccess';
import LessorSelection from './containers/LessorSelection';
import TermsConditions from './containers/TermsConditions';
import DataPolicy from './containers/DataPolicy';
import PageLayout from '../../hocs/PageLayout';
import UserLogged from '../../hocs/UserLogged'
import { PageNotFound } from '../../shared_elements';
export const authRoutes = [
  {
    path: '/',
    component: UserLogged(Login),
    key: 'login',
    apps:'Auth'
  },
  {
    path: '/login',
    component: UserLogged(Login),
    key: 'login1',
    apps:'Auth'
  },
  {
    path: '/signup',
    component: UserLogged(Register),
    key: 'signup',
    apps:'Auth'
  },
  {
    path: '/otp-verification/:token',
    component: UserLogged(Otp),
    key: 'otp',
    apps:'Auth'
  },
  {
    path: '/forgot-password',
    component: UserLogged(Forgot),
    key:'forgot-password',
    apps:'Auth'
  },
  {
    path: '/select-lessor',
    component: UserLogged(LessorSelection),
    key: 'select-lessor',
    apps:'Auth'
  },
  {
    path: '/reset-successfully',
    component: PageLayout(ResetSuccess, {apps: 'Auth', layoutPhase: 0}),
    key: 'reset-successfully',
    apps:'Auth'
  },
  {
    path: '/create-successfully',
    component: PageLayout(ResetSuccess, {apps: 'Auth', layoutPhase: 0}),
    key: 'create-successfully',
    apps:'Auth'
  },
  {
    path: '/password/reset/:token',
    component: Reset,
    key: 'password/reset/:token',
    apps:'Auth'
  },
  {
    path: '/password/create/:token',
    component: PageLayout(Reset, {apps: 'Auth', layoutPhase: 0}),
    key: 'password/create/:token',
    apps:'Auth'
  },
  {
    path: '/termscondition',
    component: TermsConditions,
    key: 'TermsCondition',
    apps:'Auth'
  },
  {
    path: '/datapolicy',
    component: DataPolicy,
    key: 'datapolicy',
    apps:'Auth'
  },
]
