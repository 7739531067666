import React, { Fragment } from 'react';
import { Paper, Grid, TextField, Button, Tooltip, IconButton, InputAdornment } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { LabelValueCard } from '../../../shared_elements';
import { getCurrencyFormat, getNumberDecimalFormat, getLocalStorageInfo } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
const AirframeCard = ({airframeEdit, toggleEditFn, airframeUpdateUsageFn, cancelUsageUpdateFn, mrLogDetail, onFieldChange, airEngineError, updateAirEngineError, isLocked}) => {
  return(
    <Paper className="mr-intermediate-card">
      <div className="mr-intermediate-card-hd">
        <h4>Airframe</h4>
        <ul className="list-inline assembly-cta-list">
          {  !airframeEdit ?
            getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1  && !isLocked ?
            <Tooltip title="Edit Airframe" arrow>
             <IconButton color="primary" component="span" size="small">
               <EditIcon onClick={toggleEditFn} color="primary" />
             </IconButton>
           </Tooltip> : null
             : !isLocked ?
               <Fragment>
                 <li className="list-inline-item">
                   <Button size="small" color="primary" variant="contained" onClick={airframeUpdateUsageFn}>Save</Button>
                 </li>
                 <li className="list-inline-item">
                   <Button size="small" color="primary" variant="outlined" onClick={cancelUsageUpdateFn}>Cancel</Button>
                 </li>
               </Fragment> : null
          }
        </ul>
      </div>

      <div className="basic-info">
        <Grid container spacing={3}>
          <LabelValueCard xs={6} sm={6} md={4} label='Aircraft Type' value={mrLogDetail.generalInfo.aircraftType.name} />
          <LabelValueCard xs={6} sm={6} md={4} label='Maintenance Program' value={mrLogDetail.generalInfo.maintenance_program[Object.keys(mrLogDetail.generalInfo.maintenance_program)[0]].split(',').join('/')} />
          <LabelValueCard xs={6} sm={6} md={4} label='Date of Manufacture' value={moment(mrLogDetail.generalInfo.aircraft_manufacture_date).format(displayDateFormatShort)} />
        </Grid>
        {/*<Grid container spacing={3}>
          <LabelValueCard xs={6} sm={6} md={4} label='Time Since New' value={mrLogDetail.generalInfo.airframe_tsn} />
          <LabelValueCard xs={6} sm={6} md={4} label='Cycle Since New' value={mrLogDetail.generalInfo.airframe_csn} />
        </Grid>
        <Grid container spacing={3}>
          <LabelValueCard xs={6} sm={6} md={4} label='Assumed Utilization FH' value={`${mrLogDetail.generalInfo.hourlyUtlization}`} />
          <LabelValueCard xs={6} sm={6} md={4} label='Assumed Utilization FC' value={`${mrLogDetail.generalInfo.cycleUtlization} `} />
          <LabelValueCard xs={6} sm={6} md={4} label='Utilization Ratio' value={`${mrLogDetail.generalInfo.airframe_ratio.toFixed(1)}:1`} />
        </Grid> */}
      </div>
      <div className="event-info">
        { Object.keys(mrLogDetail.airframe).map((label, index) =>{
          const labelValue = label - 1
          return(
            <div className="event-card">
              <h4>{mrLogDetail.airframe[label].description.replace('s', '')} Check</h4>
              <Grid container spacing={2}>
                { !airframeEdit ?
                  <LabelValueCard xs={6} sm={6} md={4} label='Fund Balance' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{ mrLogDetail.airframe[label].currentFundValue ? mrLogDetail.airframe[label].currentFundValue:0}</NumericLabel>} />:
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      id="af_currentFundValue"
                      label="Fund Balance"
                      fullWidth
                      margin="normal"
                      value={mrLogDetail.airframe[label].currentFundValue}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'currentFundValue', e.target.value, label): e.preventDefault()}}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                }
                { !airframeEdit ?
                  <LabelValueCard xs={6} sm={6} md={4} label='Fund Balance as of' value={mrLogDetail.airframe[label].fundDate ? moment(mrLogDetail.airframe[label].fundDate).format(displayDateFormatShort):'--'} />:
                  <Grid item xs={6} sm={6} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         required
                         margin="normal"
                         id="af_fundDate"
                         label="Fund Balance as of"
                         format={fieldDateFormat}
                         fullWidth
                         disableFuture={true}
                         error={airEngineError && airEngineError[label] && airEngineError[label].fundDate ? true :false}
                         helperText={airEngineError && airEngineError[label] && airEngineError[label].fundDate ? airEngineError[label].fundDate : '' }
                         minDate={mrLogDetail && mrLogDetail.generalInfo && mrLogDetail.generalInfo.aircraft_manufacture_date && moment(mrLogDetail.generalInfo.aircraft_manufacture_date)}
                         maxDate={moment()}
                         InputLabelProps={{shrink: true}}
                         value={mrLogDetail.airframe[label].fundDate ? mrLogDetail.airframe[label].fundDate:null}
                         onChange={(data, value) => {onFieldChange(value, 'fundDate', data ? moment(data).format(backendDateFormat):data, label); }}
                         onAccept={(e) =>  airEngineError && airEngineError[label] && airEngineError[label].fundDate && updateAirEngineError(label, 'fundDate', '')}
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                }

                { !airframeEdit ?
                  <LabelValueCard xs={6} sm={6} md={4} label='MR Rate' value={<Fragment><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{mrLogDetail.airframe[label].actualMR ? mrLogDetail.airframe[label].actualMR:0}</NumericLabel> / Month</Fragment>} />:
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      required
                      id="af_actualMR"
                      label="MR Rate"
                      fullWidth
                      margin="normal"
                      error={airEngineError && airEngineError[label] && airEngineError[label].actualMR ? true :false}
                      helperText={airEngineError  && airEngineError[label] && airEngineError[label].actualMR ? airEngineError[label].actualMR :'' }
                      value={mrLogDetail.airframe[label].actualMR}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'actualMR', e.target.value, label): e.preventDefault()}}
                      onFocus={() => airEngineError && airEngineError[label] && airEngineError[label].actualMR &&  updateAirEngineError(label, 'actualMR', '')}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        endAdornment: <InputAdornment position="end">/ Month</InputAdornment>
                      }}
                    />
                  </Grid>
                }
                { !airframeEdit ?
                  <LabelValueCard xs={6} sm={6} md={4} label='MR Rate Esc.(Annual)'
                    value={
                      <Fragment>
                        <NumericLabel params={getNumberDecimalFormat()}>{mrLogDetail.airframe[label].escalation ? mrLogDetail.airframe[label].escalation : 0}</NumericLabel>%
                      </Fragment>
                    }
                  /> :
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      id="af_escalation"
                      label="MR Rate Esc.(Annual)"
                      fullWidth
                      margin="normal"
                      value={mrLogDetail.airframe[label].escalation}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'escalation', e.target.value, label): e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                    />
                  </Grid>
                }
                { !airframeEdit ?
                  <LabelValueCard xs={6} sm={6} md={4} label='Event Cost Esc.(Annual)'
                    value={
                      <Fragment>
                        <NumericLabel params={getNumberDecimalFormat()}>{ mrLogDetail.airframe[label].cost_escalation ? mrLogDetail.airframe[label].cost_escalation : 0}</NumericLabel>%
                      </Fragment>
                    }
                  /> :
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      id="af_cost_escalation"
                      label="Event Cost Esc.(Annual)"
                      fullWidth
                      margin="normal"
                      value={mrLogDetail.airframe[label].cost_escalation}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'cost_escalation', e.target.value, label): e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                    />
                  </Grid>
                }
                { !airframeEdit ?
                  <LabelValueCard xs={6} sm={6} md={4} label='Last Qualified Event Date' value={mrLogDetail.airframe[label].lastShopvisitDate ? moment(mrLogDetail.airframe[label].lastShopvisitDate).format(displayDateFormatShort):'--'} />:
                  <Grid item xs={6} sm={6} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         disableFuture={true}
                         margin="normal"
                         id="af_lastShopvisitDate"
                         label="Last Qualified Event Date"
                         format={fieldDateFormat}
                         fullWidth
                         minDate={mrLogDetail && mrLogDetail.airframe && mrLogDetail.airframe[labelValue]
                           && mrLogDetail.airframe[labelValue].lastShopvisitDate ?
                           moment(mrLogDetail.airframe[labelValue].lastShopvisitDate) :
                           mrLogDetail && mrLogDetail.generalInfo && mrLogDetail.generalInfo.aircraft_manufacture_date ?
                           moment(mrLogDetail.generalInfo.aircraft_manufacture_date) : '' }
                         InputLabelProps={{shrink: true}}
                         helperText={airEngineError.lastShopvisitDate ? airEngineError.lastShopvisitDate : '' }
                         value={mrLogDetail.airframe[label].lastShopvisitDate ? mrLogDetail.airframe[label].lastShopvisitDate:null}
                         onChange={(data, value) => {onFieldChange(value, 'lastShopvisitDate', data ? moment(data).format(backendDateFormat):data, label);}}
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                }
                { !airframeEdit ?
                  <LabelValueCard xs={6} sm={6} md={4} label='Next Qualified Event Date' value={mrLogDetail.airframe[label].nextShopvisitDate ? moment(mrLogDetail.airframe[label].nextShopvisitDate).format(displayDateFormatShort):'--'} />:
                  <Grid item xs={6} sm={6} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         disablePast={true}
                         id="af_nextShopvisitDate"
                         label="Next Qualified Event Date"
                         format={fieldDateFormat}
                         fullWidth
                         InputLabelProps={{shrink: true}}
                         value={mrLogDetail.airframe[label].nextShopvisitDate ? mrLogDetail.airframe[label].nextShopvisitDate:null}
                         onChange={(data, value) => {onFieldChange(value, 'nextShopvisitDate', data ? moment(data).format(backendDateFormat):data, label);}}
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                }
              </Grid>
            </div>
          )
        }

        )}
      </div>
      <div className="mr-info">
        <table>
          <thead>
            <tr>
              {['', 'Lower MR Rate', 'Target MR Rate', 'Upper MR Rate'].map((label, index) => <th align="left">{label}</th>)}
            </tr>
          </thead>
          <tbody>
            { Object.keys(mrLogDetail.airframe).map((label, index) =>
              <tr>
                <td>{mrLogDetail.airframe[label].description}</td>
                <td className="lower-rate"><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{mrLogDetail.airframe[label].minMR}</NumericLabel> / Month</td>
                <td className="target-rate"><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{mrLogDetail.airframe[label].targetMR}</NumericLabel> / Month</td>
                <td className="upper-rate"><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{mrLogDetail.airframe[label].maxMR}</NumericLabel> / Month</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Paper>
  )
}
export default AirframeCard;
