import React, { Fragment } from 'react';
import { Button, Paper, Grid, TextField, FormControlLabel, Checkbox, InputAdornment, Tooltip, IconButton, Dialog, DialogTitle, DialogActions,DialogContent } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { isMobile } from 'react-device-detect';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import CustomzieLLPModal from './CustomzieLLPModal';
import EditIcon from '@material-ui/icons/Edit';
import { LabelValueCard } from '../../../shared_elements';
import { getCurrencyFormat, getNumberDecimalFormat, getLocalStorageInfo } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const EngineCard = ({enginesEdit, engine, toggleEditFn, engineIndex, onFieldChange, customCycleRemainFn, mrLogDetail, updateCustomLLPCyclesFn, updateEngineUsageFlag, error, updateErrorField, updateEngineUsageFn,cancelUsageUpdateFn, closeEditedCard, updateEngineUsageApi, editedSvCardFlag, viewLlpFn, engineLLPCalFn, isLocked, shopVisitWarningUpdate, warningModal }) => {
  return(
    <Paper className="mr-intermediate-card">
      <div className="mr-intermediate-card-hd">
        <h4>Engine</h4>
        <ul className="list-inline assembly-cta-list">
          { !enginesEdit ?
            getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked  ?
            <Tooltip title={`Edit Engine ${parseInt(engineIndex)+1}`} arrow>
              <IconButton color="primary" component="span" size="small">
                <EditIcon onClick={toggleEditFn} color="primary" />
              </IconButton>
            </Tooltip> : null
            :!isLocked ?
               <Fragment>
                 <li className="list-inline-item">
                   <Button size="small" color="primary" variant="contained" onClick={updateEngineUsageFn}>Save</Button>
                 </li>
                 <li className="list-inline-item">
                   <Button size="small" color="primary" variant="outlined" onClick={cancelUsageUpdateFn} >Cancel</Button>
                 </li>
               </Fragment> : null
          }
        </ul>
      </div>
      <div className="basic-info">
        <Grid container spacing={3}>
          { !enginesEdit ? <LabelValueCard xs={6} sm={6} md={4} label='Fund Date' value={engine.fund_date ? moment(engine.fund_date).format(displayDateFormatShort):'--'} />:
            <Grid item xs={6} sm={6} md={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                   required
                   margin="normal"
                   id="fund_date"
                   label="Fund Date"
                   format={fieldDateFormat}
                   fullWidth
                   minDate={engine.lsv_date ? engine.lsv_date : moment("05/1/1969")}
                   error={error.fund_date ? true: false}
                   helperText={error.fund_date ? error.fund_date : ''}
                   InputLabelProps={{shrink: true}}
                   value={engine.fund_date ? engine.fund_date:null}
                   onChange={(data, value) => {onFieldChange(value, 'fund_date', data ? moment(data).format(backendDateFormat):data, 'engines', engineIndex)}}
                   onAccept={(e) => error && error.fund_date && updateErrorField('fund_date', '')}
                 />
              </MuiPickersUtilsProvider>
            </Grid>
          }
          { !enginesEdit ? <LabelValueCard xs={6} sm={6} md={4} label={`Time Since New`} value={engine.tsn} />:
            <Grid item xs={6} sm={6} md={4}>
              <TextField
                required={(engine.lsv_date || engine.tsn_at_lsv || engine.csn_at_lsv) ? true:false}
                id="tsn"
                label={`Time Since New`}
                fullWidth
                error={error.tsn ? true: false}
                helperText={error.tsn ? error.tsn : ''}
                onFocus={() =>  {updateErrorField('tsn', ''); updateErrorField('tsn_at_lsv', '')}}
                margin="normal"
                value={engine.tsn}
                inputProps={{ maxLength: 6 }}
                InputLabelProps={{shrink: true}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">FH</InputAdornment>
                }}
              />
            </Grid>
          }
          { !enginesEdit ? <LabelValueCard xs={6} sm={6} md={4} label={`Cycles Since New`} value={engine.csn} />:
            <Grid item xs={6} sm={6} md={4}>
              <TextField
                required={(engine.lsv_date || engine.tsn_at_lsv || engine.csn_at_lsv) ? true:false}
                id="csn"
                label={`Cycles Since New`}
                fullWidth
                error={error.csn ? true: false}
                helperText={error.csn ? error.csn : ''}
                onFocus={() =>{updateErrorField('csn', ''); updateErrorField('csn_at_lsv')}}
                margin="normal"
                inputProps={{ maxLength: 6 }}
                value={engine.csn}
                InputLabelProps={{shrink: true}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">FC</InputAdornment>
                }}
              />
            </Grid>
          }

        </Grid>
        <Grid container spacing={3}>
          { !enginesEdit ? <LabelValueCard xs={6} sm={6} md={4} label='Assumed Utilization FH' value={engine.hour_util} />:
            <Grid item xs={6} sm={6} md={4}>
              <TextField
                id="hour_util"
                required
                label="Assumed Utilization"
                fullWidth
                error={error.hour_util ? true : false}
                helperText={error.hour_util ? error.hour_util : ''}
                onFocus={(e) => error && error.hour_util ? updateErrorField('hour_util', '') : e.preventDefault()}
                margin="normal"
                value={engine.hour_util}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 6 }}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value < 732 ? onFieldChange(e, 'hour_util', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">FH</InputAdornment>
                }}
              />
            </Grid>
          }
          <LabelValueCard xs={6} sm={6} md={4} label='Assumed Utilization FC' value={engine.cycle_util} />
        </Grid>
        <Grid container spacing={3}>
          { !enginesEdit ? <LabelValueCard xs={6} sm={6} md={4} label='Last SV Date' value={engine.lsv_date ? moment(engine.lsv_date).format(displayDateFormatShort):'--'} />:
            <Grid item xs={6} sm={6} md={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  required={(engine.lsv_date || engine.tsn_at_lsv || engine.csn_at_lsv) ? true:false}
                   margin="normal"
                   id="lsv_date"
                   label="Last SV Date"
                   format={fieldDateFormat}
                   fullWidth
                   maxDate={engine.fund_date ? engine.fund_date : moment()}
                   error={error.lsv_date ? true: false}
                   helperText={ error.lsv_date ? error.lsv_date : ''}
                   InputLabelProps={{shrink: true}}
                   onOpen={(e) => error && error.lsv_date && updateErrorField('lsv_date', '')}
                   value={engine.lsv_date ? engine.lsv_date:null}
                   onChange={(data, value) => {onFieldChange(value, 'lsv_date', data ? moment(data).format(backendDateFormat):data, 'engines', engineIndex); }}
                   onFocus={(e) => error && error.lsv_date && updateErrorField('lsv_date', '')}
                   onOpen={(e) => error && error.lsv_date && updateErrorField('lsv_date', '')}
                 />
              </MuiPickersUtilsProvider>
            </Grid>
          }
          { !enginesEdit ?<LabelValueCard xs={6} sm={6} md={4} label='TSN @ Last SV' value={engine.tsn_at_lsv} />:
            <Grid item xs={6} sm={6} md={4}>
              <TextField
                required={(engine.lsv_date || engine.tsn_at_lsv || engine.csn_at_lsv) ? true:false}
                id="tsn_at_lsv"
                label="TSN @ Last SV"
                fullWidth
                error={error.tsn_at_lsv ? true: false}
                helperText={ error.tsn_at_lsv ? error.tsn_at_lsv : ''}
                onFocus={(e) => error && error.tsn_at_lsv ? updateErrorField('tsn_at_lsv', '') : e.preventDefault()}
                margin="normal"
                value={engine.tsn_at_lsv}
                InputLabelProps={{shrink: true}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn_at_lsv', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">FH</InputAdornment>
                }}
              />
            </Grid>
          }
          { !enginesEdit ?<LabelValueCard xs={6} sm={6} md={4} label='CSN @ Last SV' value={engine.csn_at_lsv} />:
            <Grid item xs={6} sm={6} md={4}>
              <TextField
                required={(engine.lsv_date || engine.tsn_at_lsv || engine.csn_at_lsv) ? true:false}
                id="csn_at_lsv"
                label="CSN @ Last SV"
                fullWidth
                error={error.csn_at_lsv ? true: false}
                helperText={ error.csn_at_lsv ? error.csn_at_lsv : ''}
                margin="normal"
                onFocus={(e) => error && error.csn_at_lsv ? updateErrorField('csn_at_lsv', '') : e.preventDefault()}
                value={engine.csn_at_lsv}
                InputLabelProps={{shrink: true}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn_at_lsv', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">FC</InputAdornment>
                }}
              />
            </Grid>
          }
        </Grid>
        { !enginesEdit ?
          <p className="llp-csn">Match LLPs to Engine CSN: <b>{engine.engineLLPStatus ? 'Yes':'No'}</b></p>
          :
          <FormControlLabel
            control={
              <Checkbox color="primary" onChange={(e) => {enginesEdit ? onFieldChange(e, 'engineLLPStatus', e.target.checked, 'engines', engineIndex): e.preventDefault()}} checked={engine.engineLLPStatus} value="Match LLPs to Engine CSN" />
            }
            label="Match LLPs to Engine CSN"
          />
        }

        <CustomzieLLPModal
          mrLogDetail={mrLogDetail}
          engineIndex={engineIndex}
          toggleEditFn={toggleEditFn}
          viewLlpFn={viewLlpFn}
          updateCustomLLPCyclesFn={updateCustomLLPCyclesFn}
          enginesEdit={enginesEdit}
          engine={engine}
          updateEngineUsageApi={updateEngineUsageApi}
          lsvDate={engine.lsv_date}
          isLocked={isLocked}
        />
        { !engine.workscopes[Object.keys(engine.workscopes)[0]].nsv ?
          <span className="engine-alert">Please Update engine usage details to Review MR Data
            {!enginesEdit ?
              <Fragment>,<span onClick={updateEngineUsageFlag}>Click Here</span></Fragment>:null
            }

          </span>:null
        }
      </div>
      <div className="event-info">
        <div className="event-card">
          <h4>PRSV</h4>
          <Grid container spacing={3}>
            { !enginesEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='Fund Balance As of Date' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.fund ? engine.fund:0}</NumericLabel>} />:
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="fund"
                  label="Fund Balance As of Date"
                  fullWidth
                  margin="normal"
                  value={engine.fund}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'fund', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
              </Grid>
            }

            { !enginesEdit ? <LabelValueCard xs={6} sm={6} md={4} label='MR Rate' value={<Fragment><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{ engine.actual_prsv_mr ? engine.actual_prsv_mr:0}</NumericLabel> / FH</Fragment>} />:
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="actual_prsv_mr"
                  label="MR Rate"
                  fullWidth
                  margin="normal"
                  value={engine.actual_prsv_mr}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'actual_prsv_mr', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: <InputAdornment position="end">/ FH</InputAdornment>
                  }}
                />
              </Grid>
            }

            { !enginesEdit ?
              <LabelValueCard
                xs={6} sm={6} md={4}
                label='MR Rate Esc.(Annual)'
                value={
                <Fragment>
                  <NumericLabel params={getNumberDecimalFormat()}>{engine.engine_escalation ? engine.engine_escalation :0}</NumericLabel>%
                </Fragment>
                }
              /> :
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="escalation"
                  label="MR Rate Esc.(Annual)"
                  fullWidth
                  margin="normal"
                  value={engine.engine_escalation}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'engine_escalation', e.target.value, 'engines', engineIndex):e.preventDefault()}}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </Grid>
            }

            { !enginesEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='Event Cost Esc.(Annual)'
                value={
                  <Fragment>
                    <NumericLabel params={getNumberDecimalFormat()}>{engine.prsv_cost_escalation ? engine.prsv_cost_escalation:0}</NumericLabel>%
                  </Fragment>
                }
              /> :
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="prsv_cost_escalation"
                  label="Event Cost Esc.(Annual)"
                  fullWidth
                  margin="normal"
                  value={engine.prsv_cost_escalation}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'prsv_cost_escalation', e.target.value, 'engines', engineIndex):e.preventDefault()}}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </Grid>
            }
          </Grid>
        </div>
        <div className="event-card">
          <h4>LLP Replacement</h4>
          <Grid container spacing={3}>
            { !enginesEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='Fund Balance As of Date' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.fund_llp ? engine.fund_llp:0}</NumericLabel>} />:
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="fund_llp"
                  label="Fund Balance As of Date"
                  fullWidth
                  margin="normal"
                  value={engine.fund_llp}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'fund_llp', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
              </Grid>
            }

            { !enginesEdit ? <LabelValueCard xs={6} sm={6} md={4} label='MR Rate' value={<Fragment><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.actual_llp_mr ? engine.actual_llp_mr:0}</NumericLabel> / FC</Fragment>} />:
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="actual_llp_mr"
                  label="MR Rate"
                  fullWidth
                  margin="normal"
                  value={engine.actual_llp_mr}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'actual_llp_mr', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: <InputAdornment position="end">/ FC</InputAdornment>
                  }}
                />
              </Grid>
            }

            { !enginesEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='MR Rate Esc.(Annual)'
                value={
                  <Fragment>
                    <NumericLabel params={getNumberDecimalFormat()}>{engine.llp_escalation ? engine.llp_escalation:0}</NumericLabel>%
                  </Fragment>
                }
              /> :
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="llp_escalation"
                  label="MR Rate Esc.(Annual)"
                  fullWidth
                  margin="normal"
                  value={engine.llp_escalation}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'llp_escalation', e.target.value, 'engines', engineIndex):e.preventDefault()}}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </Grid>
            }

            { !enginesEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='Event Cost Esc.(Annual)'
                value={
                  <Fragment>
                    <NumericLabel params={getNumberDecimalFormat()}>{engine.llp_cost_escalation ? engine.llp_cost_escalation:0}</NumericLabel>%
                  </Fragment>
                }
              />:
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="llp_cost_escalation"
                  label="Event Cost Esc.(Annual)"
                  fullWidth
                  margin="normal"
                  value={engine.llp_cost_escalation}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'llp_cost_escalation', e.target.value, 'engines', engineIndex):e.preventDefault()}}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </Grid>
            }
          </Grid>
        </div>
      </div>

      {engine.edited_sv_card && warningModal ?
        <Dialog
          open={editedSvCardFlag}
          onClose={closeEditedCard}
          aria-labelledby = "scroll-dialog-title"
        >
        <DialogContent>
          <div className="" style={isMobile ? {width:'auto'}:{minWidth:'400px'}}>
            <p className="" style={{alignItems:'center',marginTop:'50px' }} align="center" >
              Shop Visit related changes will reset. Confirm to proceed
            </p>
            <div className="" align="center" style={{marginTop:'20px'}}  >
              <Button variant="contained" onClick={shopVisitWarningUpdate} color="primary" style={{marginRight:'5px'}}>confirm</Button>
              <Button variant="outlined"  onClick={closeEditedCard} color={'primary'}>Cancel</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog> : null
      }

    </Paper>
  )
}
export default EngineCard;
