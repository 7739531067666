import React, { Fragment } from 'react';
import { Paper, Grid, TextField, Button, Tooltip, IconButton, InputAdornment, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import MomentUtils from '@date-io/moment';
import { isMobile } from 'react-device-detect';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { LabelValueCard } from '../../../shared_elements';
import { getCurrencyFormat, getNumberDecimalFormat, getLocalStorageInfo } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
const LGCard = ({landingGearEdit, toggleEditFn, lgUpdateUsageFn, cancelUsageUpdateFn, mrLogDetail, onFieldChange, genericError, updateGenericErrorField, openInfoPopupFlag, closeInfoPopup, lgInfoPopup, lgUpdateUsageApi, isLocked}) => {
  const fundDateValidation = () => {
    const csn =  mrLogDetail.landingGear.csn
    const utilizationFC = mrLogDetail.landingGear.cycleUtlization
    const minDate  = (csn/utilizationFC) * 30.44
    return moment().subtract(parseInt(minDate), 'd')
  }
  return(
    <Paper className="mr-intermediate-card">
      <div className="mr-intermediate-card-hd">
        <h4>Landing Gear</h4>
        <ul className="list-inline assembly-cta-list">
          { !landingGearEdit ?
            getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked  ?
            <Tooltip title="Edit Landing Gear" arrow>
              <IconButton color="primary" component="span" size="small">
                <EditIcon onClick={toggleEditFn} color="primary" />
              </IconButton>
            </Tooltip> : null
            :  !isLocked ?
              <Fragment>
                <li className="list-inline-item">
                  <Button size="small" color="primary" variant="contained" onClick={lgUpdateUsageFn}>Save</Button>
                </li>
                <li className="list-inline-item">
                  <Button size="small" color="primary" variant="outlined" onClick={cancelUsageUpdateFn}>Cancel</Button>
                </li>
              </Fragment> : null
          }
        </ul>
      </div>

      <div className="basic-info">
        <Grid container spacing={3}>
          <LabelValueCard xs={6} sm={6} md={mrLogDetail.landingGear.to_show ? 5 : 4}
             label={
               <div>
                 Cycles Since New {mrLogDetail.landingGear.to_show ? `(as of ${moment(mrLogDetail.generalInfo.created_at).format(displayDateFormatShort)})` : null}
               </div>
             }
             value={mrLogDetail.landingGear.to_show ? mrLogDetail.landingGear.csn : '--'} />
          {
            mrLogDetail.landingGear.dateOfManufacture ?
            <LabelValueCard xs={6} sm={6} md={mrLogDetail.landingGear.to_show ? 3 : 4} label='Date of Manufacture' value={mrLogDetail.landingGear.dateOfManufacture ? moment(mrLogDetail.landingGear.dateOfManufacture).format(displayDateFormatShort):'--'  } /> : null
          }
          <LabelValueCard xs={6} sm={6} md={4} label='Assumed Utilization FC' value={mrLogDetail.landingGear.cycleUtlization ? mrLogDetail.landingGear.cycleUtlization : '--' } />
        </Grid>
      </div>
      <div className="event-info">
        <div className="event-card">
          <h4>Landing Gear OH</h4>
          <Grid container spacing={3}>
            { !landingGearEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='Fund Balance' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{mrLogDetail.landingGear.currentFundValue ? mrLogDetail.landingGear.currentFundValue:0}</NumericLabel>} /> :
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="lg_currentFundValue"
                  label="Fund Balance"
                  fullWidth
                  margin="normal"
                  value={mrLogDetail.landingGear.currentFundValue}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'currentFundValue', e.target.value, 'lg'):e.preventDefault()}}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
              </Grid>
            }
            { !landingGearEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='Fund Balance as of' value={mrLogDetail.landingGear.fundDate ? moment(mrLogDetail.landingGear.fundDate).format(displayDateFormatShort):'--'} />:
              <Grid item xs={6} sm={6} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                     required
                     margin="normal"
                     id="lg_fundDate"
                     label="Fund Balance as of"
                     format={fieldDateFormat}
                     fullWidth
                     error={genericError && genericError.fundDate ? true : false }
                     helperText={genericError && genericError.fundDate ? genericError.fundDate : ''}
                     InputLabelProps={{shrink: true}}
                     maxDate={moment()}
                     minDate={mrLogDetail.landingGear.dateOfManufacture ? moment(mrLogDetail.landingGear.dateOfManufacture) : fundDateValidation()}
                     value={mrLogDetail.landingGear.fundDate ? mrLogDetail.landingGear.fundDate:null}
                     onChange={(data, value) => {onFieldChange(value, 'fundDate', data ? moment(data).format(backendDateFormat):data, 'lg'); updateGenericErrorField('fundDate', '')}}
                   />
                </MuiPickersUtilsProvider>
              </Grid>
            }

            { !landingGearEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='MR Rate' value={<Fragment><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{ mrLogDetail.landingGear.actualMR ?  mrLogDetail.landingGear.actualMR:0}</NumericLabel> / Month</Fragment>} />:
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  required
                  id="lg_actualMR"
                  label="MR Rate"
                  fullWidth
                  margin="normal"
                  error={genericError && genericError.actualMR ? true : false}
                  helperText={genericError && genericError.actualMR ? genericError.actualMR :''}
                  onFocus={() => updateGenericErrorField('actualMR', '')}
                  value={mrLogDetail.landingGear.actualMR}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'actualMR', e.target.value, 'lg'):e.preventDefault()}}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: <InputAdornment position="end">/ Month</InputAdornment>
                  }}
                />
              </Grid>
            }

            { !landingGearEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='MR Rate Esc.(Annual)'
                value={
                  <Fragment>
                    <NumericLabel params={getNumberDecimalFormat()}>{mrLogDetail.landingGear.escalation ? mrLogDetail.landingGear.escalation:0}</NumericLabel>%
                  </Fragment>
                }
              /> :
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="lg_escalation"
                  label="MR Rate Esc.(Annual)"
                  fullWidth
                  margin="normal"
                  value={mrLogDetail.landingGear.escalation}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'escalation', e.target.value, 'lg'):e.preventDefault()}}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </Grid>
            }
            { !landingGearEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='Event Cost Esc.(Annual)'
                value={
                  <Fragment>
                    <NumericLabel params={getNumberDecimalFormat()}>{mrLogDetail.landingGear.cost_escalation ? mrLogDetail.landingGear.cost_escalation:0}</NumericLabel>%
                  </Fragment>
                }
              /> :
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="lg_cost_escalation"
                  label="Event Cost Esc.(Annual)"
                  fullWidth
                  margin="normal"
                  value={mrLogDetail.landingGear.cost_escalation}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'cost_escalation', e.target.value, 'lg'):e.preventDefault()}}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </Grid>
            }
            { !landingGearEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='Next Qualified Event Date' value={mrLogDetail.landingGear.nextShopvisitDate ? moment(mrLogDetail.landingGear.nextShopvisitDate).format(displayDateFormatShort):'--'} />:
              <Grid item xs={6} sm={6} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                     margin="normal"
                     id="lg_nextShopvisitDate"
                     label="Next Qualified Event Date"
                     format={fieldDateFormat}
                     fullWidth
                     disablePast={true}
                     InputLabelProps={{shrink: true}}
                     value={mrLogDetail.landingGear.nextShopvisitDate ? mrLogDetail.landingGear.nextShopvisitDate:null}
                     onChange={(data, value) => {onFieldChange(value, 'nextShopvisitDate', data ? moment(data).format(backendDateFormat):data); }}
                   />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            { !landingGearEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='Last Qualified Event Date' value={mrLogDetail.landingGear.lastShopvisitDate ? moment(mrLogDetail.landingGear.lastShopvisitDate).format(displayDateFormatShort):'--'} />:
              <Grid item xs={6} sm={6} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                     margin="normal"
                     id="lg_lastShopvisitDate"
                     label="Last Qualified Event Date"
                     error={genericError && genericError.lastShopvisitDate ? true :false}
                     helperText={genericError && genericError.lastShopvisitDate ? genericError.lastShopvisitDate : ''}
                     format={fieldDateFormat}
                     fullWidth
                     maxDate={moment()}
                     InputLabelProps={{shrink: true}}
                     value={mrLogDetail.landingGear.lastShopvisitDate ? mrLogDetail.landingGear.lastShopvisitDate:null}
                     onChange={(data, value) => {onFieldChange(value, 'lastShopvisitDate', data ? moment(data).format(backendDateFormat):data); updateGenericErrorField('lastShopvisitDate', '')}}
                   />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            { !landingGearEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='CSN @ Last SV' value={mrLogDetail.landingGear.csn_at_lsv ? mrLogDetail.landingGear.csn_at_lsv:'--'} />:
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  required={mrLogDetail.landingGear.lastShopvisitDate ? true:false}
                  id="csn_at_lsv"
                  label="CSN @ Last SV"
                  error={genericError && genericError.csn_at_lsv ? true :false}
                  helperText={genericError && genericError.csn_at_lsv ? genericError.csn_at_lsv : ''}
                  onFocus={() =>  updateGenericErrorField('csn_at_lsv', '')}
                  fullWidth
                  margin="normal"
                  value={mrLogDetail.landingGear.csn_at_lsv}
                  InputLabelProps={{shrink: true}}
                  inputProps={{ maxLength: 6 }}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn_at_lsv', e.target.value, 'lg'):e.preventDefault()}}
                />
              </Grid>
            }
          </Grid>
        </div>
      </div>
      <div className="mr-info">
        <table>
          <thead>
            <tr>
              {['', 'Lower MR Rate', 'Target MR Rate', 'Upper MR Rate'].map((label, index) => <th align="left">{label}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>LG OH</td>
              <td className="lower-rate">
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{mrLogDetail.landingGear.minMR}</NumericLabel> / Month
              </td>
              <td className="target-rate">
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{mrLogDetail.landingGear.targetMR}</NumericLabel> / Month
              </td>
              <td className="upper-rate">
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{mrLogDetail.landingGear.maxMR}</NumericLabel> / Month
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {lgInfoPopup ?
        <Dialog
          open={lgInfoPopup}
          onClose={closeInfoPopup}
          aria-labelledby="scroll-dialog-title"
        >
        <DialogContent dividers={true}>
          <div className="" style={isMobile ? {width:'auto'}:{minWidth:'400px'}}>
            <p className="" style={{alignItems:'center',marginTop:'100px' }} align="center" >
              Last Shop Visit Date is in between <strong>Fund Balance as of the date</strong> and <strong>Next Qualified Event Date</strong>.
              {/*Fund Calculation will start from <strong>Last Shop Visit Date ({moment(mrLogDetail.landingGear.lastShopvisitDate).format(displayDateFormatShort)})</strong>.*/}
              Confirm to proceed.
            </p>
            <div className="" align="center" style={{marginTop:'20px'}}  >
              <Button variant="contained" onClick={lgUpdateUsageApi} color="primary" style={{marginRight:'5px'}}>confirm</Button>
              <Button variant="outlined" onClick={closeInfoPopup} color={'primary'}>Cancel</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog> : null
      }
    </Paper>
  )
}
export default LGCard;
