import React, { Fragment } from 'react';
import { Paper, Grid, TextField, Button, IconButton, Tooltip, InputAdornment, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import MomentUtils from '@date-io/moment';
import  { isMobile} from 'react-device-detect';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { LabelValueCard } from '../../../shared_elements';
import { getCurrencyFormat, getNumberDecimalFormat, getLocalStorageInfo } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
const APUCard = ({apuEdit, toggleEditFn, apuUpdateUsageFn, cancelUsageUpdateFn, mrLogDetail, onFieldChange, genericError, updateGenericErrorField, openInfoPopupFlag,closeInfoPopup,apuInfoPopup, apuUpdateUsageApi, isLocked}) => {
  const fundDateValidation = () => {
    const tsn =  mrLogDetail.apu.tsn
    const utilizationFH = mrLogDetail.apu.hourlyUtlization
    const minDate  = (tsn/utilizationFH) * 30.44
    return moment().subtract(parseInt(minDate), 'd')
  }
  return(
    <Paper className="mr-intermediate-card">
      <div className="mr-intermediate-card-hd">
        <h4>APU</h4>
        <ul className="list-inline assembly-cta-list">
          { !apuEdit ?
            getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked  ?
            <li className="list-inline-item">
              <Tooltip title="Edit APU" arrow>
                <IconButton color="primary" component="span" size="small">
                  <EditIcon onClick={toggleEditFn} color="primary" />
                </IconButton>
              </Tooltip>
            </li> : null
            :!isLocked ?
              <Fragment>
                <li className="list-inline-item">
                  <Button size="small" color="primary" variant="contained" onClick={apuUpdateUsageFn}>Save</Button>
                </li>
                <li className="list-inline-item">
                  <Button size="small" color="primary" variant="outlined" onClick={cancelUsageUpdateFn}>Cancel</Button>
                </li>
              </Fragment> : null
          }
        </ul>
      </div>

      <div className="basic-info">

        <Grid container spacing={3}>
          <LabelValueCard xs={6} sm={6} md={mrLogDetail.apu.to_show ? 3: 4} label='APU Type' value={mrLogDetail.apu.apu_type && mrLogDetail.apu.apu_type.name ? mrLogDetail.apu.apu_type.name :'--' } />
          <LabelValueCard xs={6} sm={6} md={mrLogDetail.apu.to_show ?  5 : 4}
            label={
              <div>
                Time Since New {mrLogDetail.apu.to_show ? `(as of ${moment(mrLogDetail.generalInfo.created_at).format(displayDateFormatShort)})` : null}
              </div>
            }
            value={mrLogDetail.apu.to_show ? mrLogDetail.apu.tsn : '--'}
          />
          {
            mrLogDetail.apu.dateOfManufacture  ?
            <LabelValueCard xs={6} sm={6} md={mrLogDetail.apu.to_show ? 3 : 4} label='Date of Manufacture' value={mrLogDetail.apu.dateOfManufacture ? moment(mrLogDetail.apu.dateOfManufacture).format(displayDateFormatShort):'--'} /> : null
          }
          <LabelValueCard xs={6} sm={6} md={4} label='Assumed Utilization APU Hour' value={mrLogDetail.apu.hourlyUtlization ? `${mrLogDetail.apu.hourlyUtlization} / Month` :'--'} />
        </Grid>
      </div>
      <div className="event-info">
        <div className="event-card">
          <h4>APU OH</h4>
          <Grid container spacing={3}>
            { !apuEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='Fund Balance' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{ mrLogDetail.apu.currentFundValue ? mrLogDetail.apu.currentFundValue:0}</NumericLabel>} />:
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="apu_currentFundValue"
                  label="Fund Balance"
                  fullWidth
                  margin="normal"
                  value={mrLogDetail.apu.currentFundValue}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'currentFundValue', e.target.value, 'apu'): e.preventDefault()}}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
              </Grid>
            }

            { !apuEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='Fund Balance as of' value={mrLogDetail.apu.fundDate ? moment(mrLogDetail.apu.fundDate).format(displayDateFormatShort) :'--'} />:
              <Grid item xs={6} sm={6} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    required
                    margin="normal"
                    id="apu_fundDate"
                    label="Fund Balance as of"
                    format={fieldDateFormat}
                    fullWidth
                    error={genericError && genericError.fundDate ? true : false}
                    helperText={genericError && genericError.fundDate ? genericError.fundDate :''}
                    InputLabelProps={{shrink: true}}
                    value={mrLogDetail.apu.fundDate ? mrLogDetail.apu.fundDate:null}
                    maxDate={moment()}
                    minDate={mrLogDetail.apu.dateOfManufacture ? moment(mrLogDetail.apu.dateOfManufacture): fundDateValidation()}
                    onChange={(data, value) => {onFieldChange(value, 'fundDate', data ? moment(data).format(backendDateFormat):data, 'apu'); updateGenericErrorField('fundDate', '') }}
                   />
                </MuiPickersUtilsProvider>
              </Grid>
            }

            { !apuEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='MR Rate'
                value={
                  <Fragment>
                    <NumericLabel params={getCurrencyFormat({shortFormat:false})}>
                      {mrLogDetail.apu.actualMR ? mrLogDetail.apu.actualMR:0}
                    </NumericLabel> / {mrLogDetail.apu.interval_type === 'hourly' ? 'APU H':'Month'}
                  </Fragment>
                }
              />:
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  required
                  id="apu_actualMR"
                  label="MR Rate"
                  fullWidth
                  margin="normal"
                  error={genericError && genericError.actualMR ? true : false}
                  helperText={ genericError && genericError.actualMR ? genericError.actualMR :''}
                  onFocus={() => updateGenericErrorField('actualMR', '') }
                  value={mrLogDetail.apu.actualMR}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'actualMR', e.target.value, 'apu'): e.preventDefault()}}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: <InputAdornment position="end">/ {mrLogDetail.apu.interval_type === 'hourly' ? 'APU H':'Month'}</InputAdornment>
                  }}
                />
              </Grid>
            }

            { !apuEdit ?
              <LabelValueCard xs={6} sm={6} md={4} label='MR Rate Esc.(Annual)'
                value={
                  <Fragment>
                    <NumericLabel params={getNumberDecimalFormat()}>{mrLogDetail.apu.escalation ? mrLogDetail.apu.escalation : 0}</NumericLabel>%
                  </Fragment>
                }
              /> :
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="apu_escalation"
                  label="MR Rate Esc.(Annual)"
                  fullWidth
                  margin="normal"
                  value={mrLogDetail.apu.escalation}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'escalation', e.target.value, 'apu'):e.preventDefault()}}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </Grid>
            }

            { !apuEdit ?
              <LabelValueCard
                xs={6} sm={6} md={4}
                label='Event Cost Esc.(Annual)'
                value={
                  <Fragment>
                    <NumericLabel params={getNumberDecimalFormat()}>{mrLogDetail.apu.cost_escalation ? mrLogDetail.apu.cost_escalation : 0}</NumericLabel>%
                  </Fragment>
                }
              /> :
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  id="apu_cost_escalation"
                  label="Event Cost Esc.(Annual)"
                  fullWidth
                  margin="normal"
                  value={mrLogDetail.apu.cost_escalation}
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'cost_escalation', e.target.value, 'apu'):e.preventDefault()}}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </Grid>
            }
            { !apuEdit ? <LabelValueCard xs={6} sm={6} md={4} label='Next Qualified Event Date' value={mrLogDetail.apu.nextShopvisitDate ? moment(mrLogDetail.apu.nextShopvisitDate).format(displayDateFormatShort):'--'} />:
              <Grid item xs={6} sm={6} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                     margin="normal"
                     id="apu_nextShopvisitDate"
                     label="Next Qualified Event Date"
                     format={fieldDateFormat}
                     fullWidth
                     disablePast={true}
                     InputLabelProps={{shrink: true}}
                     value={mrLogDetail.apu.nextShopvisitDate ? mrLogDetail.apu.nextShopvisitDate:null}
                     onChange={(data, value) => {onFieldChange(value, 'nextShopvisitDate', data ? moment(data).format(backendDateFormat):data);}}
                   />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            { !apuEdit ? <LabelValueCard xs={6} sm={6} md={4} label='Last Qualified Event Date' value={mrLogDetail.apu.lastShopvisitDate ? moment(mrLogDetail.apu.lastShopvisitDate).format(displayDateFormatShort):'--'} />:
              <Grid item xs={6} sm={6} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="apu_lastShopvisitDate"
                    label="Last Qualified Event Date"
                    format={fieldDateFormat}
                    error={genericError && genericError.lastShopvisitDate ? true :false}
                    helperText={genericError && genericError.lastShopvisitDate ? genericError.lastShopvisitDate : ''}
                    fullWidth
                    maxDate={moment()}
                    InputLabelProps={{shrink: true}}
                    value={mrLogDetail.apu.lastShopvisitDate ? mrLogDetail.apu.lastShopvisitDate:null}
                    onChange={(data, value) => {onFieldChange(value, 'lastShopvisitDate', data ? moment(data).format(backendDateFormat):data); updateGenericErrorField('lastShopvisitDate', '')}}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            { !apuEdit ? <LabelValueCard xs={6} sm={6} md={4} label='TSN @ Last SV' value={mrLogDetail.apu.tsn_at_lsv ? mrLogDetail.apu.tsn_at_lsv : '--' } />:
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  required={(mrLogDetail.apu.lastShopvisitDate || mrLogDetail.apu.tsn_at_lsv || mrLogDetail.apu.csn_at_lsv) ? true:false}
                  id="tsn_at_lsv"
                  label="TSN @ Last SV"
                  fullWidth
                  error={genericError && genericError.tsn_at_lsv ? true : false}
                  helperText={genericError && genericError.tsn_at_lsv ? genericError.tsn_at_lsv : ''}
                  onFocus={() =>  updateGenericErrorField('tsn_at_lsv', '')}
                  margin="normal"
                  value={mrLogDetail.apu.tsn_at_lsv}
                  InputLabelProps={{shrink: true}}
                  inputProps={{ maxLength: 6 }}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn_at_lsv', e.target.value, 'apu'):e.preventDefault()}}
                />
              </Grid>
            }
          </Grid>
        </div>
      </div>
      <div className="mr-info">
        <table>
          <thead>
            <tr>
              {['', 'Lower MR Rate', 'Target MR Rate', 'Upper MR Rate'].map((label, index) => <th align="left">{label}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>APU</td>
              <td className="lower-rate">
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{mrLogDetail.apu.minMR}</NumericLabel> / {mrLogDetail.apu.interval_type === 'hourly' ? 'APUH':'Month'}
              </td>
              <td className="target-rate">
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{mrLogDetail.apu.targetMR}</NumericLabel> / {mrLogDetail.apu.interval_type === 'hourly' ? 'APUH':'Month'}
              </td>
              <td className="upper-rate">
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{mrLogDetail.apu.maxMR}</NumericLabel> / {mrLogDetail.apu.interval_type === 'hourly' ? 'APUH':'Month'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {apuInfoPopup ?
        <Dialog
          open={apuInfoPopup}
          onClose={closeInfoPopup}
          aria-labelledby="scroll-dialog-title"
        >
        <DialogContent dividers={true}>
          <div className="" style={isMobile ? {width:'auto'}:{minWidth:'400px'}}>
            <p className="" style={{alignItems:'center',marginTop:'70px' }} align="center" >
            Last Shop Visit Date is in between <strong>Fund Balance as of the date</strong> and <strong>Next Qualified Event Date</strong>.
            {/*Fund Calculation will start from <strong>Last Shop Visit Date ({moment(mrLogDetail.apu.lastShopvisitDate).format(displayDateFormatShort)})</strong>.*/}
            Confirm to proceed.
            </p>
            <div className="" align="center" style={{marginTop:'20px'}}  >
              <Button variant="contained" onClick={apuUpdateUsageApi} color="primary" style={{marginRight:'5px'}}>confirm</Button>
              <Button variant="outlined" onClick={closeInfoPopup} color={'primary'}>Cancel</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog> : null
      }
    </Paper>
  )
}
export default APUCard;
