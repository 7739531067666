import React from 'react';
import { getCurrencyFormat } from '../../../utils';
import { Paper } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';

const EngineBlendedMr  = ({engine}) => {
  return (
    <Paper className="engine-blended_mr">
      <ul className="list-inline">
        <li className="list-inline-item">
          <p>PRSV Minimum MR Rate</p>
          <h6 style={{color:'#bd2238'}}>
            <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.blended_mr.min}</NumericLabel> / FH
          </h6>
        </li>
        <li className="list-inline-item">
          <p>PRSV Blended MR Rate</p>
          <h6 style={{color:'rgb(15 101 0)'}}>
            <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.blended_mr.value}</NumericLabel> / FH
          </h6>
        </li>
        <li className="list-inline-item">
          <p>PRSV Maximum MR Rate</p>
          <h6 style={{color:'rgb(173 136 5)'}}>
            <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.blended_mr.max}</NumericLabel> / FH
          </h6>
        </li>
      </ul>
    </Paper>
  )
}
export default EngineBlendedMr;
