import moment from 'moment';
import { globalGetService, globalPostService, globalDeleteService, globalExportService, globalPostExportService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, removeEmptyKey } from '../../utils';
import { fieldValidation } from '../../utils/formValidation';
import { trackActivity } from '../../utils/mixpanel'
import { errorCode, engineUsageErrorCode, genericErrorCode, deRateErrorCode, financialPlotsErrorCode, financialPlotsEngineErrorCode } from './';
const regex = /^[0-9]*$/;
export function geMRCalculatorUsageApi(props){
  globalGetService(`/console/license/mr/usage/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({mrCalculatorUsage: response.data.data})
    }else{
      this.props.enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
    }
  })
}
export function getApuLgLimitApi(aircraftId){
  globalGetService(`new-mr-calculator/get-apu-lg-limit/${aircraftId}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        apuLgLimit:response.data.data
      })
    }
  })
}
export function updateRegionMapsApi(props, data){
  return new Promise(
    function(resolve, reject){
      globalPostService(`console/regions_maps/`,data)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function getRegionCustomVariablesApi(query) {
  this.setState({pageLoader:true});
  globalGetService(`console/regions/`,query)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        regionCondition: response.data.data
      });
    }else {
      this.props.enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
    }
  })
}
export function getMntEventsApi(props={}, aircraftTypeId){
  globalGetService(`console/aircraft-type/${aircraftTypeId}/events/`)
  .then(response => {
    if(checkApiStatus(response)){
      let maintanenceList = [];
      const data = response.data.data;
      Object.keys(data).map((item,index) => {
        maintanenceList.push({label: data[item].replace(/,/g, '/') + '', value: item})
      });
      this.setState({mntGroups:maintanenceList});
    }else {
      this.props.enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
    }
  })
}
export function initiateMrLogApi(props={}, data={}){
  const { mrCalculatorUsage, radioCheck, radioCheckLg, apuLgLimit, stateFlagCheck } = this.state;
  /**
    Check if lessor is SAAS
    Check for license avialibility
  **/
  if(getLocalStorageInfo().user.permission.mr_calculator && getLocalStorageInfo().user.permission.mr_calculator.is_saas){
    if(data.metricsInfo.length === 4){
      if((mrCalculatorUsage.aircraft.available === mrCalculatorUsage.aircraft.used) && (mrCalculatorUsage.engine.available == mrCalculatorUsage.engine.used)){
        // return with Error ''
        this.setState({licenseModal:true, licenseDesc:'License for generating logs in Engine, Airframe, APUs and LGs is expired. Please upgrade your license to generate more logs.'})
        return
      }else if (mrCalculatorUsage.aircraft.available == mrCalculatorUsage.aircraft.used) {
        // return with Error ''
        this.setState({licenseModal:true, licenseDesc:'License for generating logs in Airframe, APUs and LGs is expired. Please upgrade your license to generate more logs.'})
        return
      }else if (mrCalculatorUsage.engine.available == mrCalculatorUsage.engine.used) {
        // return ''
        this.setState({licenseModal:true, licenseDesc:'License for generating logs in Engine is expired. Please upgrade your license to generate more logs.'})
        return
      }
    }else if (data.metricsInfo.includes('engine')) {
      if(mrCalculatorUsage.engine.available == mrCalculatorUsage.engine.used){
        // return ''
        this.setState({licenseModal:true, licenseDesc:'License for generating logs in Engine is expired. Please upgrade your license to generate more logs.'})
        return
      }
    }else{
      if(mrCalculatorUsage.aircraft.available == mrCalculatorUsage.aircraft.used){
        // return with Error ''
        this.setState({licenseModal:true, licenseDesc:'License for generating logs in Airframe, APUs and LGs is expired. Please upgrade your license to generate more logs.'})
        return
      }
    }
  }
  let payload = Object.assign({}, data)
  if([24].includes(getLocalStorageInfo().defaultLessor.id)){
    payload = {
      ...payload,
      use_acumen_algorithm: false
    }
  }
  let validationInputs = {};
  if(data.metricsInfo.includes('airframe')){
    validationInputs = {
      ...validationInputs,
      maintenanceProgram:errorCode['maintenanceProgram'][fieldValidation({...errorCode['maintenanceProgramObj'], fieldval: data.airframeAndGen.maintenanceProgram})],
      dateOfManufacture:errorCode['dateOfManufacture'][fieldValidation({...errorCode['dateOfManufactureObj'], fieldval: data.airframeAndGen.dateOfManufacture})],
      aircraftType:errorCode['aircraftType'][fieldValidation({...errorCode['aircraftTypeObj'], fieldval: data.airframeAndGen.aircraftType})],
      // af_hourlyUtlization:errorCode['hourlyUtlization'][fieldValidation({...errorCode['hourlyUtlizationObj'], fieldval: data.airframeAndGen.hourlyUtlization})],
      // af_cycleUtlization:errorCode['cycleUtlization'][fieldValidation({...errorCode['cycleUtlizationObj'], fieldval: data.airframeAndGen.cycleUtlization})],
    }
  }else{
    delete payload['airframeAndGen']
  }
  if(data.metricsInfo.includes('apu')){
    validationInputs = {
      ...validationInputs,
      apuType:errorCode['apuType'][fieldValidation({...errorCode['apuTypeObj'], fieldval: data.apuAndGen.apuType})],
      apu_hourlyUtlization: errorCode['hourlyUtlization'][fieldValidation({...errorCode['hourlyUtlizationObj'], fieldval: data.apuAndGen.hourlyUtlization})],
      tsnAtLsvDom: radioCheck == "dol" && !data.apuAndGen.tsn_at_lsv && !data.apuAndGen.dateOfLastOverhual ? "Please enter either Date of Last Overhaul or TSN at Last Shop Visit" :'',
      tsnAndDom:radioCheck == "dom" && !data.apuAndGen.tsn && !data.apuAndGen.dateOfLastOverhual ? "Please enter either Time Since New or Date of Manufacture" :''

    }
    if(Number(data.apuAndGen.hourlyUtlization) == 0 ) {
      validationInputs = {
        ...validationInputs,
        apu_hourlyUtlization:'Please enter Assumed Utilization greater than 0'
      }
    }
    if(radioCheck == "dol"){
      validationInputs = {
        ...validationInputs,
        dateOfLastOverhual:errorCode['dateOfLastOverhual'][fieldValidation({...errorCode['dateOfLastOverhualObj'], fieldval: data.apuAndGen.dateOfLastOverhual})],
        tsn_at_lsv:errorCode['tsn_at_lsv'][fieldValidation({...errorCode['tsn_at_lsvObj'], fieldval:data.apuAndGen.tsn_at_lsv})]
      }
      if(data.apuAndGen.tsn){
        if(parseFloat(data.apuAndGen.tsn) < parseFloat(data.apuAndGen.tsn_at_lsv)){
          validationInputs = {
            ...validationInputs,
            tsn_at_lsv:'Please enter TSN at Last Shop Visit Less than Time Since New'
          }
        }
        if(parseFloat(data.apuAndGen.tsn) > parseFloat(apuLgLimit.apu.limit) && parseFloat(data.apuAndGen.tsn_at_lsv) < parseFloat(data.apuAndGen.tsn - apuLgLimit.apu.limit)){
          validationInputs = {
            ...validationInputs,
            tsn_at_lsv:`TSN at Last Shop Visit should be between ${parseFloat(data.apuAndGen.tsn - apuLgLimit.apu.limit)} and ${data.apuAndGen.tsn}`
          }
        }
      }
    }
  }else{
    delete payload['apuAndGen']
  }
  if(data.metricsInfo.includes('lg')){
    validationInputs = {
      ...validationInputs,
      lg_cycleUtlization:errorCode['cycleUtlization'][fieldValidation({...errorCode['cycleUtlizationObj'], fieldval: data.lgAndGen.cycleUtlization})],
      csnAtLsvAndDOM:radioCheckLg == "dol" && !data.lgAndGen.csn_at_lsv && !data.lgAndGen.dateOfLastOverhual ? 'Please enter either Date of Last Overhaul or CSN at Last Shop Visit' :"",
      csnAndDOM:radioCheckLg == "dom" && !data.lgAndGen.csn && !data.lgAndGen.dateOfLastOverhual ? "Please enter either Cycles Since New or Date of Manufacture":'',
    }
    if(radioCheckLg == "dol"){
      validationInputs = {
        ...validationInputs,
        dateOfLastOverhualLg:errorCode['dateOfLastOverhual'][fieldValidation({...errorCode['dateOfLastOverhualObj'], fieldval: data.lgAndGen.dateOfLastOverhual})],
        csn_at_lsv:errorCode['csn_at_lsv'][fieldValidation({...errorCode['csn_at_lsvObj'], fieldval:data.lgAndGen.csn_at_lsv})]
      }
      if(data.lgAndGen.csn){
        if(Number(data.lgAndGen.csn) < Number(data.lgAndGen.csn_at_lsv)){
          validationInputs = {
            ...validationInputs,
            csn_at_lsv:"Please enter CSN at Last Shop Visit Less than Cycles Since New"
          }
        }
        if(Number(data.lgAndGen.csn) > Number(apuLgLimit.lg.limit) && Number(data.lgAndGen.csn_at_lsv) < Number(data.lgAndGen.csn - apuLgLimit.lg.limit)) {
          validationInputs = {
            ...validationInputs,
            csn_at_lsv:`CSN at Last Shop Visit should be between ${parseFloat(data.lgAndGen.csn - apuLgLimit.lg.limit)} and ${data.lgAndGen.csn}`
          }
        }
      }
    }
  }else{
    delete payload['lgAndGen']
  }
  if(data.metricsInfo.includes('engine')){
    validationInputs = {
      ...validationInputs,
      engineType:errorCode['engineType'][fieldValidation({...errorCode['engineTypeObj'], fieldval: data.engine.engineType})],
      regionOfOperation:data.metricsInfo.includes('engine') ? errorCode['regionOfOperation'][fieldValidation({...errorCode['regionOfOperationObj'], fieldval: data.engine.regionOfOperation})] :'',
      utlizationRatio:errorCode['utlizationRatio'][fieldValidation({...errorCode['utlizationRatioObj'], fieldval: data.engine.utlizationRatio})],
      utilization_hours:errorCode['utilization_hours'][fieldValidation({...errorCode['utilization_hoursObj'], fieldval: data.engine.utilization_hours})],
    }
    if(data.location){
      validationInputs = {
        ...validationInputs,
        country:errorCode['country'][fieldValidation({...errorCode['countryObj'], fieldval: data.location.country_name})],
        // state:errorCode['state'][fieldValidation({...errorCode['stateObj'], fieldval: data.location.state_name})],
      }
      if(stateFlagCheck){
        validationInputs = {
          ...validationInputs,
          state:errorCode['state'][fieldValidation({...errorCode['stateObj'], fieldval: data.location.state_name})]
        }
      }
    }
  }else{
    delete payload['engine']
  }
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
    if(data.metricsInfo.includes('airframe')){
      payload = {
        ...payload,
        airframeAndGen: {
          ...payload.airframeAndGen,
          maintenanceProgram:payload.airframeAndGen.maintenanceProgram.value,
          tsn:!payload.airframeAndGen.tsn ? '0' : payload.airframeAndGen.tsn,
          csn:!payload.airframeAndGen.csn ? '0' : payload.airframeAndGen.csn
        }
      }
    }
    if(data.metricsInfo.includes('apu')){
      payload = {
        ...payload,
        apuAndGen:{
          ...payload.apuAndGen,
          dom_or_dlo:radioCheck == 'dom' ? 1 :2,
          tsn: payload.apuAndGen.tsn && payload.apuAndGen.tsn !== '' ? payload.apuAndGen.tsn : 0
        }
      }
    }
    if(data.metricsInfo.includes('lg')){
      payload = {
        ...payload,
        lgAndGen:{
          ...payload.lgAndGen,
          dom_or_dlo: radioCheckLg == "dom" ? 1: 2,
          tsn: payload.apuAndGen.tsn && payload.apuAndGen.tsn !== '' ? payload.apuAndGen.tsn : 0
        }
      }
    }
    this.setState({pageLoader:true});
    globalPostService(`new-mr-calculator/initiate/`, payload)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        trackActivity('MR Log Generated', {log_id:response.data.data.log_id, page_title: 'Generate MR Log'})
        props.history.push(`/mr-calculator/intermediate/${response.data.data.log_id}`)
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        trackActivity(' MR Log Generation Failed', { page_title: 'Generate MR Log', source: 'backend'})
      }
    })
  }else{
    this.setState({error:validationInputs});
    trackActivity(' MR Log Generation Failed', {page_title: 'Generate MR Log', source: 'frontend'})
  }
}
export function getMrLogListApi(props={}, query={}, loaderType){
  this.setState({[loaderType]:true});
  globalGetService(`mr-calculator/logs/list/`,removeEmptyKey(query))
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      delete query.sort;
      delete query.sort_by;
      delete query.page;
      delete query.per_page;
      this.setState({
        mrLogsInfo:response.data.data,
        filter:query
      });
    }else {
      this.props.enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
    }
  })
}
export function getMrLogDetailApi(props={}){
  this.setState({pageLoader:true});
  globalGetService(`new-mr-calculator/log/${props.match.params.id}/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({mrLogDetail:response.data.data})
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function updateLockApi(props = {}, data = {}){
  this.setState({pageLoader:true})
  globalPostService(`new-mr-calculator/update-lock/`, data)
  .then(response => {
    this.setState({pageLoader:false})
    if(checkApiStatus(response)){
      if(props.location.pathname.includes('intermediate')){
        this.getMrLogDetailApi(props)
      }else {
        this.getMrLogListApi(props, {}, 'pageLoader' )
      }
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        this.setState({
          airframeEdit:false,
          enginesEdit:false,
          apuEdit:false,
          landingGearEdit:false,
          lockEdit:true
        })
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function exportLogInfoApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalExportService(`mr-calculator/logs/list/`, query)
  .then(response => {
    this.setState({pageLoader:false});
    trackActivity('Logs List Exported')
    downloadFileType(response.data, (`MR Log_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), query.download);
    this.props.enqueueSnackbar("Log List Exported successfully", {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function deleteMrLogApi(props={}, data={}){
   this.setState({pageLoader:true});
  globalDeleteService(`mr-calculator/delete-log/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.getMrLogListApi(this.props, {}, 'pageLoader');
      this.setState({deleteModal:false, deleteIds:[]});
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      trackActivity('Log Deleted', {log_id: data.log_id})
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function engineUsageCalFn(props={}, data={}, engine_no){
  let validationInputs = {}
  let errorObj={}
  validationInputs = intermediateEngineValidationFn(data)
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
    if(data.edited_sv_card){
      this.setState({editedSvCardFlag:true})
    }else {
      this.engineUsageCalApi(props, data, engine_no)
    }
  }else {
    this.setState({genericError:validationInputs})
  }
}
export function engineUsageCalApi(props = {}, data = {}, engine_no){
  let payload = Object.assign({}, data);
  delete payload['workscopes']
  payload = {
    ...payload,
    log_id:props.match.params.id,
    engine_no:engine_no,
    tsn:data && data.tsn ? data.tsn :0,
    csn:data && data.csn ? data.csn :0,
    tsn_at_lsv:data && data.tsn_at_lsv ? data.tsn_at_lsv :0,
    csn_at_lsv:data && data.csn_at_lsv ? data.csn_at_lsv :0,
    timeAndCycleStatus:false
  }
  if(data.lsv_date || data.tsn_at_lsv || data.csn_at_lsv){
    payload = {
      ...payload,
      timeAndCycleStatus:true
    }
  }
  this.setState({pageLoader:true});
  globalPostService(`new-mr-calculator/engine-usage/`, payload)
  .then(response => {
    if(checkApiStatus(response)){
      this.getMrLogDetailApi(this.props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState({enginesEdit:false, genericError:{}})
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    this.setState({pageLoader:false});
  })
}
export function updateLLpCycleLimitApi(props = {}, data = {}, engine_no, customizeLLP, check){
  let payload = Object.assign({}, data);
  delete payload['workscopes']
  payload = {
    ...payload,
    log_id:props.match.params.id,
    engine_no:engine_no,
    tsn:data && data.tsn ? data.tsn :0,
    csn:data && data.csn ? data.csn :0,
    tsn_at_lsv:data && data.tsn_at_lsv ? data.tsn_at_lsv :0,
    csn_at_lsv:data && data.csn_at_lsv ? data.csn_at_lsv :0,
    timeAndCycleStatus:false,
    llpLimits:customizeLLP,
    engineLLPStatus:check.every(item =>  item == false ? false : true)
  }
  if(data.lsv_date || data.tsn_at_lsv || data.csn_at_lsv){
    payload = {
      ...payload,
      timeAndCycleStatus:true
    }
  }
  this.setState({pageLoader:true});
  globalPostService(`new-mr-calculator/engine-usage/`, payload)
  .then(response => {
    if(checkApiStatus(response)){
      this.getMrLogDetailApi(this.props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState({enginesEdit:false, genericError:{}})
      trackActivity(`LLP Updted`,{page_title: 'Intermediate',  log_id: props.match.params.id})
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    this.setState({pageLoader:false});
  })
}


export function updateMrRateShopVisitApi(props = {},data = {},engine_no, mr="", mrKey="", shop_visit, name, type){
  let payload = Object.assign({}, data)
  payload = {
    ...payload,
    log_id:props.match.params.id,
    engine_no:engine_no,
    tsn:data && data.tsn ? data.tsn :0,
    csn:data && data.csn ? data.csn :0,
    tsn_at_lsv:data && data.tsn_at_lsv ? data.tsn_at_lsv :0,
    csn_at_lsv:data && data.csn_at_lsv ? data.csn_at_lsv :0,
    sv_update:true
  }
  if(mrKey == 'edited_engine_mr'){
    payload = {
      ...payload,
      prsv_mr:{shop_visit, mr:parseFloat(mr)}
    }
  }
  if(mrKey == "edited_llp_bulk_mr"){
    payload = {
      ...payload,
      llp_mr:{shop_visit, mr:parseFloat(mr)}
    }
  }
  this.setState({pageLoader:true});
  globalPostService(`new-mr-calculator/engine-usage-sv/`, payload)
  .then(response => {
    if(checkApiStatus(response)){
      this.getMrLogDetailApi(this.props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState({enginesEdit:false})
      trackActivity(`${type} MR Rate of ${name} Run Updated`,{page_title: 'Intermediate',  log_id: props.match.params.id})
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.getMrLogDetailApi(this.props)
    }
    this.setState({pageLoader:false});
  })
}
export function changeWorkScopeApi(props={}, data={}, type, selected, run){
  this.setState({pageLoader:true});
  globalPostService(`new-mr-calculator/workscope/modules/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.getMrLogDetailApi(this.props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      trackActivity(`${selected ? 'Selected' : 'UnSelected'} ${type} in ${run} Run`,{page_title: 'Intermediate',  log_id: data.log_id})
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    this.setState({pageLoader:false});
  })
}
export function carryOverNSVApi(props={}, data={}, name, type){
  this.setState({pageLoader:true});
  globalPostService(`mr-calculator/workscope/fund-carry-forward/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.getMrLogDetailApi(this.props);
      this.setState({
        workScopeInfo:{
          modal:false,
          engine_no:'',
          data:{}
        }
      });
      trackActivity(`${type} Carry Over NSV of ${name} Run ${data.carry_forward_llp_fund_status ? 'Selected' : 'UnSelected'}`,{page_title: 'Intermediate',  log_id: props.match.params.id})
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    this.setState({pageLoader:false});
  })
}
export function selectLLPToReplaceApi(props={}, query={}, workScopeInfo){
  this.setState({pageLoader:true});
  globalGetService(`mr-calculator/workscope/llps/`, query)
  .then(response => {
    this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          workScopeLLPInfo: {
            ...prevState.workScopeLLPInfo,
            modal:true,
            llps:response.data.data.map((item) => {return {...item,
              csn:item.max_limit-item.cycles_remaining,
              cycles_for_next_run:workScopeInfo.cycles_for_next_run ? workScopeInfo.cycles_for_next_run : workScopeInfo.life_limiter,
              module_sort: item.module  == "Fan" ? "Fan" : item.module == "LPT" ?  'LPT' : item.module == "Core" ? item.part_name.includes('HPT') ? "HPT" : "HPC" :''
            }}),
            cost_of_year:response.data.cost_of_years,
            query:query
          }
        }))
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }

  })
}
export function replacedTheLLPSelectedApi(props={}, data={}){
  let payload = {
    shop_visit_no:data.query.shop_visit_no,
    engine_no:data.query.engine_no,
    log_id:data.query.log_id,
    current_replaced_llps:data.llps.filter(llp => llp.replaced === 1).map(item => item.id)
  }
  this.setState({pageLoader:true});
  globalPostService(`new-mr-calculator/workscope/llps-update/`, payload)
  .then(response => {
    if(checkApiStatus(response)){
      this.getMrLogDetailApi(this.props);
      this.setState({
        workScopeLLPInfo:{
          modal:false,
          llps:[]
        }
      })
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    this.setState({pageLoader:false});
  })
}
export function customCycleRemainApi(props={}, engineTypeId, engineNo, mrLogDetail){
  this.setState({pageLoader:true});
  globalGetService(`mr-calculator/engine-type/${engineTypeId}/llps/`)
  .then(response => {
    if(checkApiStatus(response)){
      trackActivity(`LLP Viewed`,{page_title: 'Intermediate',  log_id: props.match.params.id})
      let engineCSN = parseInt(mrLogDetail.engines[engineNo].csn);
      let engineCsnAtLsv = parseInt(mrLogDetail.engines[engineNo].csn_at_lsv)
      let ids = (mrLogDetail.engines[engineNo].llpLimits ? mrLogDetail.engines[engineNo].llpLimits.map(llpLimit => llpLimit.id):[]);
      let resObj = Object.assign({}, response.data.data);
      let llpCustom = Object.keys(resObj).map(key => {
        if(ids.includes(parseInt(key))){
          let llpEdit =  mrLogDetail.engines[engineNo].llpLimits.filter(llpLimit => llpLimit.id == key)[0];
          if(llpEdit.editCycles){
            return llpEdit = { ...llpEdit, max_limit: parseInt(llpEdit.max_limit), remaining: parseInt(llpEdit.remaining), csn:parseInt(llpEdit.max_limit) - parseInt(llpEdit.remaining), module_sort:llpEdit.module  == "Fan" ? "Fan" : llpEdit.module == "LPT" ?  'LPT' : llpEdit.module == "Core" ? llpEdit.part_name.includes('HPT') ? "HPT" : "HPC" :'' }
          }else{
            llpEdit = {
              ...llpEdit,
              remaining: llpEdit.remaining - (engineCsnAtLsv ? parseInt(engineCSN - engineCsnAtLsv):regex.test(engineCSN) ? engineCSN:0),
              max_limit: parseInt(llpEdit.max_limit),
              csn: parseInt(llpEdit.max_limit) - parseInt(llpEdit.remaining - (engineCsnAtLsv ? parseInt(engineCSN - engineCsnAtLsv) : regex.test(engineCSN) ? engineCSN:0)),
              module_sort:llpEdit.module  == "Fan" ? "Fan" : llpEdit.module == "LPT" ?  'LPT' : llpEdit.module == "Core" ? llpEdit.part_name.includes('HPT') ? "HPT" : "HPC" :''
            }
            return llpEdit;
          }
        }else{
          let customCsn  = Object.assign({}, resObj[key]);
          customCsn = {
            ...customCsn,
            remaining: customCsn['remaining'] - ( engineCsnAtLsv ? parseInt(engineCSN - engineCsnAtLsv):regex.test(engineCSN) ? engineCSN : 0),
            csn: parseInt(customCsn.max_limit) - parseInt(customCsn['remaining'] - (engineCsnAtLsv ? parseInt(engineCSN - engineCsnAtLsv):regex.test(engineCSN) ? engineCSN : 0)),
            module_sort:customCsn.module  == "Fan" ? "Fan" : customCsn.module == "LPT" ?  'LPT' : customCsn.module == "Core" ? customCsn.part_name.includes('HPT') ? "HPT" : "HPC" :''
          }
          return customCsn
        }
      });
      llpCustom = llpCustom.reduce((obj, key) => {
        obj[key.id] = key;
        return obj;
      }, {});
      this.setState({
        modal:true,
        engineNo:engineNo,
        customCycleRemain:llpCustom,
        originalList:response.data.data
      })
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    this.setState({pageLoader:false});
  })
}
export function cancelUsageUpdateApi(props={}, assemblyType){
  this.setState({pageLoader:true});
  let assemblyEditFlag = assemblyType+'Edit'
  globalGetService(`new-mr-calculator/log/${props.match.params.id}/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        mrLogDetail: {
          ...prevState.mrLogDetail,
          [assemblyType]:response.data.data[assemblyType]
        },
        [assemblyEditFlag]:false
      }))
    }
  })
}
export function airframeUpdateUsageApi(props, data, mrLog){
  let validationInputs = {}
  let errorObj = {}
  let payload = Object.assign({}, data)

  Object.keys(data).map(label => {
    validationInputs = {
      ...validationInputs,
      [label]:intermediateFormValidationFn(data[label])
    }
    payload = {
      ...payload,
      [label]:{
        ...payload[label],
        currentFundValue:payload[label].currentFundValue ? payload[label].currentFundValue : '0',

      }
    }
    if(!data[label].nextShopvisitDate) {
      payload = {
        ...payload,
        [label]: {
          ...payload[label],
          nsv_update:false,
        }
      }
    }
  })
  if(Object.keys(removeEmptyKey(validationInputs)).every((k) => {return removeEmptyKey(validationInputs)[k] === ""})){
    this.setState({pageLoader:true});
    globalPostService(`new-mr-calculator/aircraft-usage/airframe/`, {airframe:payload , generalInfo: mrLog.generalInfo, log_id:this.props.match.params.id})
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState({airframeEdit:false});
        this.getMrLogDetailApi(props)
        this.props.enqueueSnackbar(response.data.message, { variant:'success',anchorOrigin:{vertical:'top', horizontal:'right'} })
      }else{
        this.props.enqueueSnackbar(response.data.message, { variant:'error',anchorOrigin:{vertical:'top', horizontal:'right'} })
      }
    })
  }else {
    this.setState({
      airEngineError:validationInputs
    })
  }
}
export function apuUpdateUsageFn(props, data){
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    fundDate:genericErrorCode['fundDate'][fieldValidation({...genericErrorCode['fundDateObj'], fieldval:data.fundDate})],
    actualMR:genericErrorCode['actualMR'][fieldValidation({...genericErrorCode['actualMRObj'], fieldval:data.actualMR})],
  }
  // if( data.tsn_at_lsv){
  //   validationInputs = {
  //     ...validationInputs,
  //     tsn_at_lsv:genericErrorCode['tsn_at_lsv'][fieldValidation({...genericErrorCode['tsn_at_lsvObj'], fieldval:data.tsn_at_lsv})],
  //   }
  // }
  if(data.lastShopvisitDate){
    validationInputs = {
      ...validationInputs,
      lastShopvisitDate:genericErrorCode['lastShopvisitDate'][fieldValidation({...genericErrorCode['lastShopvisitDateObj'], fieldval:data.lastShopvisitDate})],
      tsn_at_lsv:genericErrorCode['tsn_at_lsv'][fieldValidation({...genericErrorCode['tsn_at_lsvObj'], fieldval:data.tsn_at_lsv})],
    }
  }

  if(Object.keys(validationInputs).every((k) => {return validationInputs[k] === ""})){
    if(data.lastShopvisitDate > data.fundDate) {
      this.setState({apuInfoPopup:true})
    }else {
      this.apuUpdateUsageApi(props, data)
    }
  }else {
    this.setState({
      genericError: validationInputs
    })
  }
}
export function apuUpdateUsageApi(props ={} ,data = {}){
  let payload = Object.assign({}, data)
  payload = {
      ...payload,
      utilizationType:'tsn',
      dateOfLastOverhual: data  && data.lastShopvisitDate ?  data.lastShopvisitDate : null,
      tsn_at_lsv:data && data.tsn_at_lsv ? data.tsn_at_lsv:0,
      csn_at_lsv:data && data.csn_at_lsv ? data.csn_at_lsv:0,
      currentFundValue: data && data.currentFundValue ? data.currentFundValue : 0,
      cost_escalation: data && data.cost_escalation ? data.cost_escalation : 0,
      escalation: data &&  data.escalation ? data.escalation : 0,
    }
  if(data.tsn_at_lsv || data.csn_at_lsv || data.lastShopvisitDate){
    payload = {
      ...payload,
      utilizationType:'dateOfLastOverhual'
    }
  }
  if(!data.nextShopvisitDate){
    payload = {
      ...payload,
      nsv_update:false
    }
  }
  this.setState({pageLoader:true});
  globalPostService(`new-mr-calculator/aircraft-usage/apu/`, {apu:payload, log_id:this.props.match.params.id})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({apuEdit:false, apuInfoPopup:false});
      this.getMrLogDetailApi(props)
      this.props.enqueueSnackbar(response.data.message, { variant:'success',anchorOrigin:{vertical:'top', horizontal:'right'} })
    }else{
      this.props.enqueueSnackbar(response.data.message, { variant:'error',anchorOrigin:{vertical:'top', horizontal:'right'} })
    }
  })

}
export function lgUpdateUsageFn(props, data){
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    fundDate:genericErrorCode['fundDate'][fieldValidation({...genericErrorCode['fundDateObj'], fieldval:data.fundDate})],
    actualMR:genericErrorCode['actualMR'][fieldValidation({...genericErrorCode['actualMRObj'], fieldval:data.actualMR})],
  }
  // if(data.csn_at_lsv){
  //   validationInputs = {
  //     ...validationInputs,
  //     csn_at_lsv:genericErrorCode['csn_at_lsv'][fieldValidation({...genericErrorCode['csn_at_lsvObj'], fieldval:data.csn_at_lsv})],
  //   }
  // }
  if(data.lastShopvisitDate ){
    validationInputs = {
      ...validationInputs,
      csn_at_lsv:genericErrorCode['csn_at_lsv'][fieldValidation({...genericErrorCode['csn_at_lsvObj'], fieldval:data.csn_at_lsv})],
    }
  }
  if(Object.keys(validationInputs).every((k) => {return validationInputs[k] === ""})){
    if(data.lastShopvisitDate >data.fundDate ){
      this.setState({lgInfoPopup:true})
    }else {
      this.lgUpdateUsageApi(props, data)
    }
  }else {
    this.setState({
      genericError:validationInputs
    })
  }
}
export function lgUpdateUsageApi(props, data){
  let payload= Object.assign({}, data)
  payload = {
    ...payload,
    utilizationType:'tsn',
    dateOfLastOverhual: data  && data.lastShopvisitDate ?  data.lastShopvisitDate : null,
    tsn_at_lsv:data && data.tsn_at_lsv ? data.tsn_at_lsv:0,
    csn_at_lsv:data && data.csn_at_lsv ? data.csn_at_lsv:0,
    currentFundValue: data && data.currentFundValue ? data.currentFundValue : 0,
    cost_escalation: data && data.cost_escalation ? data.cost_escalation : 0,
    escalation: data &&  data.escalation ? data.escalation : 0,
  }
  if(data.lastShopvisitDate || data.tsn_at_lsv || data.csn_at_lsv){
    payload = {
      ...payload,
      utilizationType:'dateOfLastOverhual'
    }
  }
  if(!data.nextShopvisitDate) {
    payload = {
      ...payload,
      nsv_update:false
    }
  }
  this.setState({pageLoader:true});
  globalPostService(`new-mr-calculator/aircraft-usage/landing_gear/`, {landingGear:payload, log_id:this.props.match.params.id})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({landingGearEdit:false, lgInfoPopup:false});
      this.getMrLogDetailApi(props)
      this.props.enqueueSnackbar(response.data.message, { variant:'success',anchorOrigin:{vertical:'top', horizontal:'right'} })
    }else{
      this.props.enqueueSnackbar(response.data.message, { variant:'error',anchorOrigin:{vertical:'top', horizontal:'right'} })
    }
  })
}


export function exportLLPListApi(props={}, query={}, name){
  this.setState({pageLoader:true});
  globalExportService(`mr-calculator/workscope/llps/`, query)
  .then(response => {
    this.props.enqueueSnackbar('LLP List exported successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    trackActivity(`LLPs Exported`,{page_title: 'Intermediate',  log_id: props.match.params.id})
    downloadFileType(response.data, (`LLPs_${moment().format('YYYY-MM-DD')}`), query.download);
    this.setState({pageLoader:false});
  })
}
export function getCustomVariableApi(params={}, query={}){
  globalGetService(`new-mr-calculator/log/${params.id}/variable-conditions/`, query)
  .then(response => {
    if(checkApiStatus(response)){

    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function getMrUsageInfoApi(params={}, query={}){
  globalGetService(`console/license/mr/usage/`, query)
  .then(response => {
    if(checkApiStatus(response)){

    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
// Graph api
export function mrFinanceGraphApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`new-mr-calculator/plot-mr-projection/`, {log_id:props.match.params.id})
  .then(response => {
    if(checkApiStatus(response)){
      let widgetSum = graphWidgetSum(response.data.data, moment().format('YYYYMM'));
      let address = '';
      if(response.data.data.engines){
        if(response.data.data.location && response.data.data.location.address){
          address = response.data.data.location.address
          this.setState({regionDetails: response.data.data.location})
        }else{
          address = response.data.data.engines.general_info.region.name
          this.setState({regionDetails: response.data.data.engines.general_info.region})
        }
      }
      this.setState({
        financeRptInfo:response.data.data,
        copyfinanceRptInfo:response.data.data,
        totalFundWidget:widgetSum,
        address:address
      });
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    this.setState({pageLoader:false});
  })
}
export function mrFinanceLifeOfWingsApi(props={}, query={}){
  globalGetService(`new-mr-calculator/plot-engine-life-on-wing/${props.match.params.id}/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({lifeOnWings: Object.keys(response.data.data).length ? response.data.data : {}})
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function generateSimulateResultApi(props, data){
  const { address } = this.state
  let payload = Object.assign({}, data)
  let financeError = {}, apuErrorFlag=false, lgErrorFlag=false, airframeErrorFlag=false, engineErrorFlag=false;
  // airframe validation
  if(payload.airframe){
    let error = [], mrError = []
    Object.keys(payload.airframe).map(label => {
      payload = {
        ...payload,
        airframe:{
          ...payload.airframe,
          [label]:{
            ...payload.airframe[label],
            escalation_percentage:payload && payload.airframe && payload.airframe[label] && payload.airframe[label].escalation_percentage ? payload.airframe[label].escalation_percentage :0,
            cost_escalation: payload && payload.airframe && payload.airframe[label] && payload.airframe[label].cost_escalation ? payload.airframe[label].cost_escalation : 0
          }
        }
      }
      let errorInfo = financialPlotsValidation(payload.airframe[label])
      financeError = {
        ...financeError,
        airframe:{
          ...financeError.airframe,
          [label]:errorInfo
        }
      }
      if(errorInfo.events && Object.keys(errorInfo.events).length || errorInfo.mr !== ""){
        Object.keys(errorInfo.events).map(label => {
          if(Object.keys(removeEmptyKey(errorInfo.events[label])).length){
            error.push(true);
          }else{
            error.push(false)
          }
        });
        if(errorInfo.mr !== ''){
          mrError.push(true)
        }
      }
    });
    if(error.includes(true) || mrError.includes(true)){
      airframeErrorFlag = true
    }
  }
  // engines validation
  if(payload.engines){
    let engineMrError=[],llpMrError=[],shopVisitError=[], generalInfoError=[];
    Object.keys(payload.engines.engine_data).map(label => {
      payload = {
        ...payload,
        engines:{
          ...payload.engines,
          engine_data:{
            ...payload.engines.engine_data,
            [label]:{
              ...payload.engines.engine_data[label],
              llp_cost_escalation:payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label] && payload.engines.engine_data[label].llp_cost_escalation ? payload.engines.engine_data[label].llp_cost_escalation : 0 ,
              llp_escalation:payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label] && payload.engines.engine_data[label].llp_escalation ? payload.engines.engine_data[label].llp_escalation : 0 ,
              engine_escalation:payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label] && payload.engines.engine_data[label].engine_escalation ? payload.engines.engine_data[label].engine_escalation : 0 ,
              prsv_cost_escalation:payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label] && payload.engines.engine_data[label].prsv_cost_escalation ? payload.engines.engine_data[label].prsv_cost_escalation : 0 ,
            }
          }
        }
      }
      if(payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label].shop_visits){
        Object.keys(payload.engines.engine_data[label].shop_visits).map(secondaryChild => {
          if(payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label].shop_visits && payload.engines.engine_data[label].shop_visits ){
            Object.keys(payload.engines.engine_data[label].shop_visits[secondaryChild]).map(tertiaryChild => {
              payload = {
                ...payload,
                engines:{
                  ...payload.engines,
                  engine_data:{
                    ...payload.engines.engine_data,
                    [label]:{
                      ...payload.engines.engine_data[label],
                      shop_visits:{
                        ...payload.engines.engine_data[label].shop_visits,
                        [secondaryChild]:{
                          ...payload.engines.engine_data[label].shop_visits[secondaryChild],
                          [tertiaryChild]:{
                            ...payload.engines.engine_data[label].shop_visits[secondaryChild][tertiaryChild],
                            eventCost:payload.engines.engine_data[label].shop_visits[secondaryChild][tertiaryChild].eventCost ? payload.engines.engine_data[label].shop_visits[secondaryChild][tertiaryChild].eventCost :0
                          }

                        }
                      }
                    }
                  }
                }
              }
            })
          }
        })
      }
      let errorInfo = financialPlotsEngineValidation(payload.engines.engine_data[label])
      financeError = {
        ...financeError,
        engines:{
          ...financeError.engines,
          [label]: errorInfo,
        },
        general_info:{
          ...financeError.general_info,
          cycle_utilization:financialPlotsEngineErrorCode['cycle_utilization'][fieldValidation({...financialPlotsEngineErrorCode['cycle_utilizationObj'], fieldval:data.engines.general_info.cycle_utilization})],
          hourly_utilization:financialPlotsEngineErrorCode['hourly_utilization'][fieldValidation({...financialPlotsEngineErrorCode['hourly_utilizationObj'], fieldval:data.engines.general_info.hourly_utilization})],
          utilization_ratio:data.engines.general_info.utilization_ratio > data.engines.general_info.utilization_max_ratio || data.engines.general_info.utilization_ratio < data.engines.general_info.utilization_min_ratio ? 'please enter valid utilization ratio' : '',
          regionOfOperation:address ? '' : 'Please enter the Region of Operation'

        }
      }
      if(errorInfo.shop_visits && Object.keys(errorInfo.shop_visits).length || errorInfo.engine_mr !== "" || errorInfo.llp_mr !== ""){
        Object.keys(errorInfo.shop_visits).map(label => {
          Object.keys(errorInfo.shop_visits[label]).map(labelChild => {
            if(Object.keys(removeEmptyKey(errorInfo.shop_visits[label][labelChild])).length){
              shopVisitError.push(true)
            }else {
              shopVisitError.push(false)
            }
          })
        })
        if(errorInfo.engine_mr !== ''){
          engineMrError.push(true)
        }else {
          engineMrError.push(false)
        }
        if(errorInfo.llp_mr !== ""){
          llpMrError.push(true)
        }else {
          llpMrError.push(false)
        }
      }
      if(financeError.general_info){
        if(financeError.general_info.cycle_utilization !== "" || financeError.general_info.hourly_utilization !== "" || financeError.general_info.utilization_ratio || financeError.general_info.regionOfOperation){
          generalInfoError.push(true)
        }else {
          generalInfoError.push(false)
        }
      }
      if(shopVisitError.includes(true)|| engineMrError.includes(true) || llpMrError.includes(true) || generalInfoError.includes(true)){
        engineErrorFlag= true
      }
    })
  }
  //apu validation
  if(payload.apu){
    payload = {
      ...payload,
      apu:{
        ...payload.apu,
        cost_escalation: payload && payload.apu && payload.apu.cost_escalation ? payload.apu.cost_escalation : 0,
        escalation_percentage: payload && payload.apu && payload.apu.escalation_percentage ? payload.apu.escalation_percentage : 0,
      }
    }
    let errorInfo = financialPlotsValidation(payload.apu);
    financeError = {
      ...financeError,
      apu: errorInfo
    }
    if((errorInfo.events && Object.keys(errorInfo.events).length) || errorInfo.mr != ''){
      let error = [];
      Object.keys(errorInfo.events).map(label => {
        if(Object.keys(removeEmptyKey(errorInfo.events[label])).length){
          error.push(true);
        }else{
          error.push(false)
        }
      });
      if(error.includes(true) || errorInfo.mr != ''){
        apuErrorFlag = true;
      }
    }
  }
  // landing Gear validation
  if(payload.landing_gear){
    payload = {
      ...payload,
      lg:{
        ...payload.lg,
        cost_escalation: payload && payload.lg && payload.lg.cost_escalation ? payload.lg.cost_escalation : 0,
        escalation_percentage: payload && payload.lg && payload.lg.escalation_percentage ? payload.lg.escalation_percentage : 0,
      }
    }
    let errorInfo = financialPlotsValidation(payload.landing_gear);
    financeError = {
      ...financeError,
      landing_gear:financialPlotsValidation(payload.landing_gear)
    }
    if((errorInfo.events && Object.keys(errorInfo.events).length) || errorInfo.mr != ''){
      let error = [];
      Object.keys(errorInfo.events).map(label => {
        if(Object.keys(removeEmptyKey(errorInfo.events[label])).length){
          error.push(true);
        }else{
          error.push(false)
        }
      });
      if(error.includes(true) || errorInfo.mr != ''){
        lgErrorFlag=true
      }
    }
  }
  if(apuErrorFlag || lgErrorFlag || airframeErrorFlag || engineErrorFlag){
    this.setState({financeError, apuErrorFlag, lgErrorFlag, airframeErrorFlag, engineErrorFlag});
  }else{
    this.setState({pageLoader:true });
    globalPostService(`new-mr-calculator/simulate/${props.match.params.id}/`, payload)
    .then(response => {
      this.setState({pageLoader:false, apuErrorFlag:false,lgErrorFlag:false, airframeErrorFlag:false, engineErrorFlag:false });
      if(checkApiStatus(response)){
        this.setState({
          financeRptInfo:this.state.copyfinanceRptInfo,
          simulateFinanceRptInfo:response.data.data,
          simulationType:'3'
        });
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
}
export function getCashflowListApi(props={}, data={}, loaderType){
  this.setState({pageLoader:true});
  globalPostService(`new-mr-calculator/simulate/${props.match.params.id}/`, {...data, monthly_cash_flow:true})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      trackActivity('Monthly Cashflow Generated',{page_title: 'Analyze MR Cashflow',  log_id: props.match.params.id})
      let res = JSON.parse(JSON.stringify(response.data.data));
      res = res.sort((a,b) => parseInt(a.month) - parseInt(b.month)).slice(0,40);
      let mrAccounts=[], years=[];
      let startYear = parseInt(response.data.data[0].month.slice(0,4));
      let endYear = parseInt(response.data.data[response.data.data.length -1].month.slice(0,4));
      const kvArray = response.data.data.map(entry => {
        const key = ['name'].map(k => entry[k]).join('|');
        return [key, entry];
      });
      let arrs = response.data.data.map(item => item.month.slice(0,4)).filter((item, i, ar) => ar.indexOf(item) === i).sort((a,b) => a-b).map(item => { return{label:item, value:item}});
      const map = new Map(kvArray);
      let filter =[];
      Array.from(map.values()).map(data=>{
        mrAccounts.push({label: data.name, value: data.name});
      });
      this.setState({
        cashflowList:res,
        resultCopy:response.data.data,
        originalCashflowList:response.data.data,
        totalCount: response.data.data.length,
        years:arrs,
        nameFilter:mrAccounts,
        modal:true
      });
    }
    this.setState({[loaderType]:false});
  })
}
export function exportCashflowListApi(props={}, data={}, fileType="", filter={}){
  this.setState({pageLoader:true});
  globalPostExportService(`new-mr-calculator/simulate/${props.match.params.id}/`, {...data, download:true, monthly_cash_flow:true, year: filter.month , reserve_account:filter.name})
  .then(response => {
    this.setState({pageLoader:false});
    trackActivity('Monthly Cashflow Exported',{page_title: 'Analyze MR Cashflow',  log_id: props.match.params.id})
    downloadFileType(response.data, (`Cashflow_LogID_${props.match.params.id}_${moment().format('YYYY-MM-DD')}`), fileType );
    this.props.enqueueSnackbar('Cashflow List exported successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function deRateVariableApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`mr-calculator/variable-conditions/1/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        deRateList: response.data.data
      });
      trackActivity('LOI Table Viewed',{page_title: props.location.pathname.includes('financial-plots') ? 'Analyze MR Cashflow' : 'Intermediate',  log_id: props.match.params.id})
    }
    this.setState({pageLoader:false});
  })
}
export function calculateDeRateApi(props={}, data={}){
  let payload = Object.assign({}, data);
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    incRatio:deRateErrorCode['incRatio'][fieldValidation({...deRateErrorCode['incRatioObj'], fieldval:data.incRatio})],
  }
  if(!data.derates.length){
    validationInputs = {
      ...validationInputs,
      derates:'Please select De-rate values for calculation'
    }
  }
  delete payload['incRatio'];
  payload = {
    ...payload,
    derates: data.derates.sort(function(a, b){return a - b})
  }
  if(Object.keys(validationInputs).every((k) => {return validationInputs[k] === ''})){
    this.setState({pageLoader:true});
    globalPostService(`new-mr-calculator/loi-mr/${props.match.params.id}/`, payload)
    .then(response => {
      if(checkApiStatus(response)){
        this.setState({deRateMatrix:response.data.data, deRateMatrixModal:true});
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        trackActivity('MR Rates Calculated in LOI',{page_title: props.location.pathname.includes('financial-plots') ? 'Analyze MR Cashflow' : 'Intermediate',  log_id: props.match.params.id})
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
      this.setState({pageLoader:false});
    })
  }else {
    this.setState({
      error:validationInputs
    })
  }
}
export function exportDeRateApi(props={}, data={}){
  this.setState({pageLoader:true});
  globalPostExportService(`new-mr-calculator/loi-mr/${props.match.params.id}/`, data)
  .then(response => {
    this.setState({pageLoader:false});
    trackActivity('MR Rates Exported in LOI',{page_title: props.location.pathname.includes('financial-plots') ? 'Analyze MR Cashflow' : 'Intermediate',  log_id: props.match.params.id})
    downloadFileType(response.data, (`LOI_Table_${props.match.params.id}_${moment().format('YYYY-MM-DD')}`), data.download);
    this.props.enqueueSnackbar('LOI Table exported successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function exportGraphSummaryApi(params={}, data={}){
  globalExportService(``, data)
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}
export function graphWidgetSum(component, dateType){
  let landingGearSum = 0;
  let apuSum = 0;
  let totalSum = 0;
  let airframeSumArray =[];
  let enginesSumArray = [];
  let checkFlag=false;
  let monthlyModal=false;
  let graphSumObj = {
    landingGearSum: 0,
    apuSum: 0,
    totalSum: 0,
    airframeSumArray: [],
    enginesSumArray: []
  };
  if(component.apu){
    Object.keys(component.apu.plots).map(label => {
      if(label == dateType){
        apuSum += component.apu.plots[label];
        totalSum += component.apu.plots[label];
      }
    });
    graphSumObj['apuSum'] = apuSum;
  }
  if(component.landing_gear){
    Object.keys(component.landing_gear.plots).map(label => {
      if(label == dateType){
        landingGearSum += component.landing_gear.plots[label];
        totalSum += component.landing_gear.plots[label];
      }
    });
    graphSumObj['landingGearSum'] = landingGearSum;
  }
  if(component.airframe){
    Object.keys(component.airframe).map(air => {
      let airframeSum = {};
      let sum = 0;
      Object.keys(component.airframe[air].plots).map(label => {
        if(label == dateType){
          sum += component.airframe[air].plots[label];
          totalSum += component.airframe[air].plots[label];
        }
      });
      airframeSum['name'] = air;
      airframeSum['amount'] = sum;
      airframeSumArray.push(airframeSum);
    });
    graphSumObj['airframeSumArray'] = airframeSumArray;
  }
  if(component.engines){
    Object.keys(component.engines.engine_data).map(engine => {
      let engineSum = 0;
      Object.keys(component.engines.engine_data[engine].plots).map(label => {
        if(label == dateType){
          engineSum += component.engines.engine_data[engine].plots[label];
          totalSum += component.engines.engine_data[engine].plots[label];
        }
      });
      enginesSumArray.push(engineSum);

    });
    graphSumObj['enginesSumArray'] = enginesSumArray;
  }
  graphSumObj['totalSum'] = totalSum;
  return graphSumObj;
}
export function intermediateFormValidationFn(data){
  let validationInputs = {};
  validationInputs = {
    ...validationInputs,
    fundDate:genericErrorCode['fundDate'][fieldValidation({...genericErrorCode['fundDateObj'], fieldval:data.fundDate})],
    actualMR:genericErrorCode['actualMR'][fieldValidation({...genericErrorCode['actualMRObj'], fieldval:data.actualMR})],
  };
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
    return null
  }else{
    return validationInputs;
  }
}
export function intermediateEngineValidationFn(payload, tableType=""){
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    hour_util: payload.hour_util <= 0 || payload.hour_util == ""  || payload.hour_util == null || payload.hour_util == undefined ? 'Please enter Utilization':'',
    fund_date:engineUsageErrorCode['fund_date'][fieldValidation({...engineUsageErrorCode['fund_dateObj'], fieldval:payload.fund_date})],
  }
  if(payload.lsv_date || payload.tsn_at_lsv || payload.csn_at_lsv){
    validationInputs = {
      ...validationInputs,
      lsv_date:payload.lsv_date > payload.fund_date ? "Please enter Last Shop Visit Date less than Fund Date" : engineUsageErrorCode['lsv_date'][fieldValidation({...engineUsageErrorCode['lsv_dateObj'], fieldval:payload.lsv_date})],
      tsn_at_lsv:engineUsageErrorCode['tsn_at_lsv'][fieldValidation({...engineUsageErrorCode['tsn_at_lsvObj'], fieldval:payload.tsn_at_lsv})],
      csn_at_lsv:engineUsageErrorCode['csn_at_lsv'][fieldValidation({...engineUsageErrorCode['csn_at_lsvObj'], fieldval:payload.csn_at_lsv})],
      tsn:engineUsageErrorCode['tsn'][fieldValidation({...engineUsageErrorCode['tsnObj'], fieldval:payload.tsn})],
      csn:engineUsageErrorCode['csn'][fieldValidation({...engineUsageErrorCode['csnObj'], fieldval:payload.csn})],
    }
    if(payload.tsn_at_lsv && payload.tsn){
      if(Number(payload.tsn_at_lsv) > Number(payload.tsn)){
        validationInputs={
          ...validationInputs,
          tsn_at_lsv:'Entered TSN should be greater than TSN at Last SV'
        }
      }
    }

    if(payload.csn_at_lsv && payload.csn){
      if(Number(payload.csn_at_lsv) > Number(payload.csn)){
        validationInputs={
          ...validationInputs,
          csn_at_lsv:'Entered CSN should be greater than CSN at Last SV'
        }
      }
    }
  }
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ""})){
    return {}
  }else {
    return validationInputs
  }
}
export function validateUsageApi(props = {}){
  this.setState({
    pageLoader:true
  })
  globalGetService(`new-mr-calculator/validate-usage/${props.match.params.id}/`)
  .then(response => {
    this.setState({
      pageLoader:false
    })
    if(checkApiStatus(response)){
      props.history.push(`/mr-calculator/financial-plots/${props.match.params.id}`)
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
    }
  })
}
export function financialPlotsValidation(data){
  let validationInputs = {}, eventErrorObj = {};
  validationInputs = {
    ...validationInputs,
    mr:financialPlotsErrorCode['mr'][fieldValidation({...financialPlotsErrorCode['mrObj'], fieldval:data.mr})],
  }
    Object.keys(data.events).map(label => {
      eventErrorObj = {
        ...eventErrorObj,
        [label]:{
          ...eventErrorObj[label],
          eventCost:financialPlotsErrorCode['eventCost'][fieldValidation({...financialPlotsErrorCode['eventCostObj'], fieldval:data.events[label].eventCost})],
          eventDate:financialPlotsErrorCode['eventDate'][fieldValidation({...financialPlotsErrorCode['eventDateObj'], fieldval:data.events[label].eventDate})]
        }
      }

      if(data.events){
        validationInputs = {
          ...validationInputs,
          events:eventErrorObj
        }
      }
    })
  if(Object.keys(validationInputs).every((k) => {return validationInputs[k] === ""})){
    return null
  }else {
    return validationInputs
  }
}
export function financialPlotsEngineValidation(data){
let validationInputs = {}
let shopVisitErrorObj = {}
validationInputs = {
  ...validationInputs,
  engine_mr:financialPlotsEngineErrorCode['engine_mr'][fieldValidation({...financialPlotsEngineErrorCode['engine_mrObj'], fieldval:data.engine_mr})],
  llp_mr:financialPlotsEngineErrorCode['llp_mr'][fieldValidation({...financialPlotsEngineErrorCode['llp_mrObj'], fieldval:data.llp_mr})]
}
Object.keys(data.shop_visits).map(label => {
  Object.keys(data.shop_visits[label]).map(labelChild => {
    shopVisitErrorObj = {
      ...shopVisitErrorObj,
      [labelChild]:{
        ...shopVisitErrorObj[labelChild],
        mr:financialPlotsEngineErrorCode['mr'][fieldValidation({...financialPlotsEngineErrorCode['mrObj'], fieldval:data.shop_visits[label][labelChild].mr})],
        // eventCost:financialPlotsEngineErrorCode['eventCost'][fieldValidation({...financialPlotsEngineErrorCode['eventCostObj'], fieldval:data.shop_visits[label][labelChild].eventCost})],
      }
    }
    })
  if(data.shop_visits[label]){
    validationInputs = {
      ...validationInputs,
      shop_visits:{
        ...validationInputs.shop_visits,
        [label]:shopVisitErrorObj
      }
    }
  }

})
if(Object.keys(validationInputs).every((k) => {return validationInputs[k] === ""})){
  return null
}else {
  return validationInputs
}
}
