import React, { Fragment } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { TableRow, TableCell, TextField, InputAdornment } from '@material-ui/core';
import moment from 'moment';
import { isMobile, isTablet } from 'react-device-detect';
import { imgStoragePath, displayDateFormatShort } from '../../../constants';
import { getCurrencyFormat } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const EngineEventList = ({shopVisit, simulateShopVisit, simulationType, onFieldChange, engineIndex, shopVisitIndex, financeError, updateErrorEngineField}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <span className="wk-title">{shopVisit[Object.keys(shopVisit)[0]].workscopes}</span>
        <div>
          <img className="shop-visit-icon" width="27" src={imgStoragePath+"shop_visit_icn.png"} alt="img" />
          <ul className="list-unstyled" style={{marginLeft:'26px'}}>
            { Object.keys(shopVisit).map((sv,index) => {
              return(
                <li key={index} className="wk-type-name" style={isMobile ? {height: simulationType == 1 || simulationType == 3  ? '30px' : "40px"}:{height: simulationType == 1 || simulationType == 3  ? '38px' : "50px"}}>
                  {shopVisit[sv].name}
                </li>
              )
            })}
          </ul>
        </div>
      </TableCell>
      <TableCell>
        <span className="workscope-type" style={{paddingLeft: '10px'}}></span>
        <ul className="list-unstyled">
          { Object.keys(shopVisit).map((sv,index) => {
            return(
              <li key={index} style={isMobile ? {height: simulationType == 1 || simulationType == 3  ? '30px' : "40px", whiteSpace:'nowrap'}:{height: simulationType == 1 || simulationType == 3  ? '38px' : "50px"}}>
                { simulationType === '1' || simulationType === '2' ?
                  <Fragment>{ moment(shopVisit[sv].eventDate).format(displayDateFormatShort) }</Fragment>:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    { shopVisit[sv].eventDate === simulateShopVisit[sv].eventDate ?
                      moment(shopVisit[sv].eventDate).format(displayDateFormatShort) :
                      <div className="differentiation-values">
                        <h6>{ moment(simulateShopVisit[sv].eventDate).format(displayDateFormatShort) }</h6>
                        <p>{ moment(shopVisit[sv].eventDate).format(displayDateFormatShort) }</p>
                      </div>
                    }
                  </Fragment>:null
                }
              </li>
            )
          })}
        </ul>
      </TableCell>
      <TableCell>
        <span className="workscope-type" style={{paddingLeft: '10px'}}></span>
        <ul className="list-unstyled">
          { Object.keys(shopVisit).map((sv,index) => {
            return(
              <li key={index} style={isMobile ? {height: simulationType == 1 || simulationType == 3  ? '30px' : "50px", whiteSpace:'nowrap'}:{height: simulationType == 1 || simulationType == 3  ? '38px' : "60px"}}>
                { simulationType === '1'  ?
                  <Fragment><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].mr}</NumericLabel></Fragment>:null
                }
                {
                  simulationType === "2" ?
                  <TextField
                    id="mr"
                    fullWidth
                    margin="none"
                    value={shopVisit[sv].mr ? shopVisit[sv].mr:''}
                    InputLabelProps={{shrink: true}}
                    error={financeError && financeError[sv] && financeError[sv].mr ? true : false}
                    helperText={financeError && financeError[sv] && financeError[sv].mr ? financeError[sv].mr  : '' }
                    onFocus={(e) => financeError && financeError[sv] && financeError[sv].mr ? updateErrorEngineField('mr','',engineIndex,shopVisitIndex,sv) : e.preventDefault()}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'mr', e.target.value, 'engines',  engineIndex, shopVisitIndex, sv): e.preventDefault()}}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                  />:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    { shopVisit[sv].mr === simulateShopVisit[sv].mr ?
                      <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].mr}</NumericLabel> :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{simulateShopVisit[sv].mr}</NumericLabel></h6>
                        <p><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].mr}</NumericLabel></p>
                      </div>
                    }
                  </Fragment>:null
                }
              </li>
            )
          })}
        </ul>
      </TableCell>
      <TableCell>
        <span className="workscope-type" style={{paddingLeft: '10px'}}></span>
        <ul className="list-unstyled">
          { Object.keys(shopVisit).map((sv,index) => {
            return(
              <li key={index} style={isMobile ? {height: simulationType == 1 || simulationType == 3  ? '30px' : "50px", whiteSpace:'nowrap'}:{height: simulationType == 1 || simulationType == 3  ? '38px' : "60px"}}>
                { simulationType === '1'?
                  <Fragment><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].eventCost}</NumericLabel></Fragment>:null
                }
                {  simulationType === '2' ?
                  <TextField
                    id="eventCost"
                    fullWidth
                    margin="none"
                    value={shopVisit[sv].eventCost ? shopVisit[sv].eventCost:''}
                    InputLabelProps={{shrink: true}}
                    error={financeError && financeError[sv] && financeError[sv].eventCost ? true : false}
                    helperText={financeError && financeError[sv] && financeError[sv].eventCost ? financeError[sv].eventCost : ''}
                    onFocus={(e) => financeError && financeError[sv] && financeError[sv].eventCost ?  updateErrorEngineField('eventCost', '',engineIndex, shopVisitIndex, sv) : e.preventDefault()}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'eventCost', e.target.value, 'engines',  engineIndex, shopVisitIndex, sv): e.preventDefault()}}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                  />:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    { shopVisit[sv].eventCost === simulateShopVisit[sv].eventCost ?
                      <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].eventCost}</NumericLabel> :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{simulateShopVisit[sv].eventCost}</NumericLabel></h6>
                        <p><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].eventCost}</NumericLabel></p>
                      </div>
                    }
                  </Fragment>:null
                }
              </li>
            )
          })}
        </ul>
      </TableCell>
      <TableCell>
        <span className="workscope-type" style={{paddingLeft: '10px'}}></span>
        <ul className="list-unstyled">
          { Object.keys(shopVisit).map((sv,index) => {
            return(
              <li key={index} style={isMobile ? {height: simulationType == 1 || simulationType == 3  ? '30px' : "50px", whiteSpace:'nowrap'}:{height: simulationType == 1 || simulationType == 3  ? '38px' : "60px"}}>
                { simulationType === '1' || simulationType === '2' ?
                  <Fragment><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].fundCollected}</NumericLabel></Fragment>:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    { shopVisit[sv].fundCollected === simulateShopVisit[sv].fundCollected ?
                      <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].fundCollected}</NumericLabel> :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{simulateShopVisit[sv].fundCollected}</NumericLabel></h6>
                        <p><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].fundCollected}</NumericLabel></p>
                      </div>
                    }
                  </Fragment>:null
                }
              </li>
            )
          })}
        </ul>
      </TableCell>
      <TableCell>
        <span className="workscope-type" style={{paddingLeft: '10px'}}></span>
        <ul className="list-unstyled">
          { Object.keys(shopVisit).map((sv,index) => {
            return(
              <li key={index} style={isMobile ? {height: simulationType == 1 || simulationType == 3  ? '30px' : "50px", whiteSpace:'nowrap'}:{height: simulationType == 1 || simulationType == 3  ? '38px' : "50px"}}>
                { simulationType === '1' || simulationType === '2' ?
                  <Fragment><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].difference}</NumericLabel></Fragment>:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    { shopVisit[sv].difference === simulateShopVisit[sv].difference ?
                      <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].difference}</NumericLabel> :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{simulateShopVisit[sv].difference}</NumericLabel></h6>
                        <p><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].difference}</NumericLabel></p>
                      </div>
                    }
                  </Fragment>:null
                }
              </li>
            )
          })}
        </ul>
      </TableCell>
    </TableRow>
  )
}
export default EngineEventList;
