import React, { useState, Fragment } from 'react';
import moment from 'moment';
import { Grid, Paper, Tooltip, FormControlLabel, Checkbox, Button, TextField, InputAdornment } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InfoIcon from '@material-ui/icons/Info';
import { isMobile, isTablet } from 'react-device-detect';
import { displayDateFormatShort } from '../../../constants';
import { getCurrencyFormat, getLocalStorageInfo } from '../../../utils';
import EditIcon from '@material-ui/icons/Edit';
import { regexConstants } from '../../../constants/regEx';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
const ShopVisitCard = ({workScope, showWorkScopeInfoFn, showLLPReplaceFn, changeWorkScopeFn, carryOverNSVFn, wkIndex, type, toggleEditShopVisitFn, toggleCancelFn, onFieldChange, engineIndex, shopVisit,engine, updateMrRate, cancelUsageUpdateFn, isLocked, lockEdit}) => {
  const [flag, setFlag] = useState(false);
  const [mrEdit, setMrEdit] = useState(false);
  const [mrLLpEdit, setMrLLpEdit] = useState(false)
  return(
    <Paper className={flag ? "shop-visit-card shop-visit-card-down" : "shop-visit-card" }>
      <h2 >{workScope.name} RUN</h2>
      <div className="">
        <div className="shop-visit-head">
          <Grid container spacing={ isMobile ? 2 : 2} alignItems="center">
            <Grid item xs={6} sm={6} md={2}>
              <div style={workScope.life_limited_by_llp ? {marginTop:'20px'}:{}}>
                <p className="label-title">Life on Wing</p>
                <h6 style={workScope.life_limited_by_llp ? {color:'#f44337'} : {}}>{Math.ceil(workScope.life_of_wings)} Hrs</h6>
                {workScope.life_limited_by_llp ? <span className="restricted-llp"> {workScope.life_limited_by_llp} </span> : null}
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <p className="label-title">SV Date</p>
              <h6>{workScope.nsv ? moment(workScope.nsv).format(displayDateFormatShort):'--'}</h6>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <p className="label-title">Workscope Module</p>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <FormControlLabel
                    control={
                      <Checkbox size="small" disabled={isLocked}  color="primary" checked={true} value="CORE" />
                    }
                    label="CORE"
                  />
                </li>
                <li className="list-inline-item">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        disabled={isLocked}
                        checked={workScope.workscope.includes(2)}
                        onChange={(e) => getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1  ?
                            changeWorkScopeFn(e.target.checked ? [...workScope.workscope, 2]: workScope.workscope.filter(id => id !== 2), 'LPT', e.target.checked) : e.preventDefault()
                       }
                        value="LPT" />
                    }
                    label="LPT"
                  />
                </li>
                <li className="list-inline-item">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        disabled={isLocked}
                        checked={workScope.workscope.includes(3)}
                        onChange={(e) => getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1  ?
                          changeWorkScopeFn(e.target.checked ? [...workScope.workscope, 3] : workScope.workscope.filter(id => id !== 3), 'FAN', e.target.checked) : e.preventDefault()
                        }
                        value="FAN"
                      />
                    }
                    label="FAN"
                  />
                </li>

              </ul>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <p className="flex-centered label-title">LLPs to be replaced in SV  { isMobile || isTablet ? null : <Tooltip title="Life On Wing Collectively Limited by LLPs Life" arrow><InfoIcon fontSize="small" /></Tooltip>}</p>
              <h6 className="flex-centered">
                {Object.keys(workScope.LLP).length} LLPs Added
                <span style={{paddingLeft:'5px', cursor:'pointer'}} onClick={showLLPReplaceFn}><AddCircleIcon color="primary" /></span>
              </h6>
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <p className="label-title">PRSV Cost</p>
              <h6 className="flex-centered">
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.engine_cost}</NumericLabel>
                { isMobile || isTablet ? null :<Tooltip arrow title={
                  <Fragment>
                    <p>
                      LLP Replacement Cost:
                      <br/>
                      <b><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.llp_replacement_cost}</NumericLabel></b>
                    </p>
                  </Fragment>
                  }>
                    <InfoIcon fontSize="small" />
                </Tooltip>}
              </h6>

            </Grid>
          </Grid>
          <span className={flag ? "sv-arrow-icon sv-arrow-icon-down" :'sv-arrow-icon' } onClick={() => setFlag(flag ? false:true)}>
            <ArrowForwardIosIcon color="primary"  />
          </span>
        </div>
        { flag ?
          <div className="shop-visit-body sv-other-info">
            <Grid container spacing={isTablet ? 2: 0} alignItems="center">
              <Grid item xs={12} sm={12} md={6}>
                <h4>Performance Restoration</h4>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    {mrEdit ?
                        <ul className="list-inline mr-edit">
                          <li className="list-inline-item">
                            { workScope.edited_engine_mr || workScope.edited_engine_mr == ""  ?
                              <TextField
                                id="engine_mr"
                                label="MR Rate"
                                margin="normal"
                                value={workScope.edited_engine_mr}
                                InputLabelProps={{shrink:true}}
                                inputProps={{ maxLength: 10 }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  endAdornment: <InputAdornment position="start">/ FH </InputAdornment>
                                }}
                                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e,'mr',e.target.value, engineIndex, 'prsv_mr', shopVisit, wkIndex,'edited_engine_mr'): e.preventDefault()}}
                              /> :
                              <TextField
                                id="engine_mr"
                                label="MR Rate"
                                margin="normal"
                                value={ workScope.engine_mr }
                                InputLabelProps={{shrink:true}}
                                inputProps={{ maxLength: 10 }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e,'mr',e.target.value, engineIndex, 'prsv_mr', shopVisit, wkIndex,'engine_mr'): e.preventDefault()}}
                              />
                            }
                          </li>
                          {
                            !isLocked ?
                            <li className="list-inline-item" style={{marginTop:'35px', cursor:'pointer'}}>
                              <CancelIcon color="secondary" className="sv-card-action" fontSize="small" onClick={() => {setMrEdit(mrEdit ? false : true);cancelUsageUpdateFn('engines')}}/>
                            </li> : null

                          }
                          {
                            !isLocked ?
                            <li className="list-inline-item" style={{marginTop:'35px', cursor:'pointer'}}>
                              <DoneIcon color="primary" className="sv-card-action" fontSize="small" onClick={() =>{updateMrRate(engine,engineIndex,workScope.edited_engine_mr,'edited_engine_mr',shopVisit, 'Performance Restoration');setMrEdit(mrEdit ? false : true)}} />
                            </li> : null

                          }
                        </ul>
                       :
                      <Fragment>
                        <p className="label-title">
                          MR Rate
                        </p>
                        {!(workScope.edited_engine_mr !== undefined && workScope.edited_engine_mr !== null) || (workScope.engine_mr == workScope.edited_engine_mr) ?
                          <h6>
                            <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.engine_mr}</NumericLabel> / FH
                              <span style={{marginLeft:'4px', cursor:'pointer'}}>
                                {workScope.nsv ?
                                  getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked  ?
                                  <EditIcon color="primary" fontSize="small" className="sv-card-action" onClick={() => setMrEdit(mrEdit ? false:true)} /> : null
                                  : null
                                }
                              </span>
                          </h6> :
                          <div className="differentiation-values">
                            <h6>
                              <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.edited_engine_mr}</NumericLabel> / FH
                                <span style={{marginLeft:'4px', cursor:'pointer'}}>
                                  {!isLocked ?
                                    <EditIcon color="primary" fontSize="small" className="sv-card-action" onClick={() => setMrEdit(mrEdit ? false:true)} /> : null
                                  }

                                </span>
                            </h6>
                            <p><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.engine_mr}</NumericLabel></p>
                          </div>

                        }
                      </Fragment>
                    }
                  </Grid>
                  {
                    workScope.engine_mr_for_run ?
                    <Grid item xs={6} sm={6} md={3}>
                      <p className="label-title">MR Rate for Run</p>
                      <h6>
                        <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.engine_mr_for_run}</NumericLabel>
                      </h6>
                    </Grid> : null
                  }
                  <Grid item xs={6} sm={6} md={3}>
                    <p className="label-title">Shop Visit Cost</p>
                    <h6>
                      <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.engine_cost}</NumericLabel>
                    </h6>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <p className="label-title">Funds Collected</p>
                    <h6>
                      <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.engine_fund}</NumericLabel>
                    </h6>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <p className="label-title">Funds Difference</p>
                    <h6>
                      <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.remaining_engine_fund}</NumericLabel>
                    </h6>
                    { wkIndex !== 2 ?
                      getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1  ?
                      <FormControlLabel
                        control={
                          <Checkbox color="primary" disabled={isLocked} checked={workScope.carry_forward_engine_fund_status} onChange={(e) => carryOverNSVFn({carry_forward_engine_fund_status:e.target.checked}, 'Performance Restoration')} value="Carry Over NSV" />
                        }
                        label="Carry over to NSV"
                      />:null
                      :null
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} style={isMobile?{marginTop:'10px'}:{}}>
                <h4>LLP Replacement</h4>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    {mrLLpEdit ?
                      <ul className="list-inline mr-edit">
                        <li className="list-inline-item">
                          {workScope.edited_llp_mr || workScope.edited_llp_mr == "" ?
                            <TextField
                              id="edited_llp_bulk_mr"
                              label="MR Rate"
                              margin="normal"
                              value={workScope.edited_llp_mr}
                              inputProps={{ maxLength: 10 }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                endAdornment: <InputAdornment position="start">/ FC</InputAdornment>
                              }}
                              InputLabelProps={{shrink:true}}
                              onChange={(e) =>  regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'mr', e.target.value, engineIndex, 'llp_mr', shopVisit, wkIndex,'edited_llp_mr'): e.preventDefault()}
                            />:
                            <TextField
                              id="engine_mr"
                              label="MR Rate"
                              margin="normal"
                              value={workScope.llp_bulk_mr}
                              inputProps={{ maxLength: 10 }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                              }}
                              InputLabelProps={{shrink:true}}
                              onChange={(e) =>  {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e,'mr',e.target.value,engineIndex,'llp_mr',shopVisit,wkIndex, 'llp_bulk_mr'):e.preventDefault()}}
                            />
                          }
                        </li>
                        {
                          !isLocked ?
                          <li className="list-inline-item" style={{marginTop:'35px', cursor:'pointer'}}>
                            <CancelIcon color="secondary" className="sv-card-action" fontSize="small" onClick={() => {setMrLLpEdit(mrLLpEdit ? false : true);cancelUsageUpdateFn('engines')}}/>
                          </li> : null
                        }
                        {
                          !isLocked  ?
                          <li className="list-inline-item" style={{marginTop:'35px', cursor:'pointer'}}>
                            <DoneIcon color="primary" className="sv-card-action" fontSize="small" onClick={()=>{updateMrRate(engine,engineIndex, workScope.edited_llp_mr, 'edited_llp_bulk_mr', shopVisit, 'LLP Replacement' ); setMrLLpEdit(mrLLpEdit? false : true)}} />
                          </li> : null
                        }
                      </ul>
                       :
                      <Fragment>
                        <p className="label-title">
                          MR Rate
                        </p>
                        {!(workScope.edited_llp_mr !== undefined && workScope.edited_llp_mr !== null) || (workScope.llp_bulk_mr == workScope.edited_llp_mr) ?
                          <h6>
                            <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.llp_bulk_mr}</NumericLabel> / FC
                            <span style={{marginLeft:'4px', cursor:'pointer'}}>
                               {workScope.nsv ?
                                 getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked ?
                                 <EditIcon color="primary" className="sv-card-action" fontSize="small" onClick={() => setMrLLpEdit(mrLLpEdit ? false:true)} />  : null
                                 : null
                               }
                            </span>
                          </h6> :
                          <div className="differentiation-values">
                            <h6>
                              <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.edited_llp_mr}</NumericLabel> / FC
                                <span style={{marginLeft:'4px', cursor:'pointer'}}>
                                  { !isLocked ?
                                    <EditIcon color="primary" fontSize="small" className="sv-card-action" onClick={() => setMrLLpEdit(mrLLpEdit ? false:true)} /> : null
                                  }

                                </span>
                            </h6>
                            <p><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.llp_bulk_mr}</NumericLabel></p>
                          </div>
                        }

                      </Fragment>
                    }

                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <p className="label-title">Shop Visit Cost</p>
                    <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.llp_replacement_cost}</NumericLabel></h6>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <p className="label-title">Funds Collected</p>
                    <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.llp_replacement_fund}</NumericLabel></h6>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <p className="label-title">Funds Difference</p>
                    <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.llp_replacement_fund_remaining}</NumericLabel></h6>
                    { wkIndex !== 2 ?
                      getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 ?
                      <FormControlLabel
                        control={
                          <Checkbox color="primary" disabled={isLocked} checked={workScope.carry_forward_llp_fund_status} onChange={(e) => carryOverNSVFn({carry_forward_llp_fund_status: e.target.checked}, 'LLP Replacement')} value="Carry Over NSV" />
                        }
                        label="Carry over to NSV"
                      />:null
                      :null
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/*<div className="total-sv"> // commenting this as of now because total shop visit cost not required - shabeeb
              <ul className="list-inline">
                <li className="list-inline-item">
                  <p className="label-title">Total Funds Collected</p>
                  <h6>
                    <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.total_fund}</NumericLabel>
                  </h6>
                </li>
                <li className="list-inline-item">
                  <p className="label-title">Total Shop Visit Cost</p>
                  <h6>
                    <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScope.total_cost}</NumericLabel>
                  </h6>
                </li>
              </ul>
            </div> */}
          </div>:null
        }
      </div>
    </Paper>
  )
}
export default ShopVisitCard;
