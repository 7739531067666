import Inititate from './containers/Inititate';
import IntermediateMrCal from './containers/IntermediateMrCal';
import LogListing from './containers/LogListing';
import MrGraphicalReps from './containers/MrGraphicalReps';
import PageLayout from '../../hocs/PageLayout';
import UserRoleAccess from '../../hocs/UserRoleAccess';
export const mrCalculatorRoutes = [
  {
    path: '/mr-calculator/initiate',
    component: PageLayout(UserRoleAccess(Inititate,['mr_calculator', 'dashboard','R']), {apps: 'MRCalculator', layoutPhase: 1}),
    key: 'Inititate'
  },
  {
    path: '/mr-calculator/intermediate/:id',
    component: PageLayout(UserRoleAccess(IntermediateMrCal,['mr_calculator', 'dashboard','R']), {apps: 'MRCalculator', layoutPhase: 1}),
    key: 'IntermediateMrCal'
  },
  {
    path: '/mr-calculator/logs',
    component: PageLayout(UserRoleAccess(LogListing,['mr_calculator', 'dashboard','R']), {apps: 'MRCalculator', layoutPhase: 1}),
    key: 'LogListing'
  },
  {
    path: '/mr-calculator/log/:id',
    component: PageLayout(UserRoleAccess(IntermediateMrCal,['mr_calculator', 'dashboard','R']), {apps: 'MRCalculator', layoutPhase: 1}),
    key: 'LogView'
  },
  {
    path: '/mr-calculator/financial-plots/:id',
    component: PageLayout(UserRoleAccess(MrGraphicalReps, ['mr_calculator', 'analyze_mr_cash_flow', 'R']), {apps: 'MRCalculator', layoutPhase: 1}),
    key: 'MrGraphicalReps'
  }
]
