import React from 'react';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
const AssemblyEventHD = () => {
  return(
    <div className="mnt-event-head">
      {
        isMobile ? null :
        <Grid container spacing={1}>
          <Grid item md={3}>Maintenance Events</Grid>
          <Grid item md={3}>MR Rate</Grid>
          <Grid item md={3}>MR Rate Esc.(Annual)</Grid>
          <Grid item md={3}>Event Cost Esc.(Annual)</Grid>
        </Grid>
      }
    </div>
  )
}
export default AssemblyEventHD;
