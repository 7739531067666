import React, { Fragment } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { isMobile, isTablet } from 'react-device-detect';
import { LabelValueCard } from '../../../shared_elements';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const LOWGraph = ({lowInfo, lowInfoSimulated, simulationType}) => {
  let data = [];
  let newData = [];
  if(lowInfo.plots){
    Object.keys(lowInfo.plots).map( year => {
      let d = [];
      d.push(new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime());
      d.push(lowInfo.plots[year]);
      data.push(d);
    });
  }
  if(simulationType === '3' && lowInfoSimulated && lowInfoSimulated.plots){
    Object.keys(lowInfoSimulated.plots).map( year => {
      let d = [];
      d.push(new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime());
      d.push(lowInfoSimulated.plots[year]);
      newData.push(d);
    });
  }
  const options = {
    chart: {
      type: 'area',
      zoomType: 'x'
    },
    title: {
      text: 'Life On - Wing Analysis',
      style: {
            fontSize: '11px',
        },
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Engine Life ( Year )'
      }
    },
    yAxis:{
      title: {
        text: 'Life of Engine on Wing ( Hours )',
        style: {
              fontSize: '11px'
          }
      },
      labels: {
        formatter: function () {
          return this.value;
        }
      },
      gridLineDashStyle: 'longdash'
    },
    tooltip: {
      formatter: function () {
          return 'Date <b>' + moment(this.x).format('MMM YYYY') +
              '<br/></b>Hours <b>' + this.y + '</b>';
      }
    },
    credits: {
      enabled: false
    },
    series:  [
      {
        showInLegend: true,
        name: 'Calculated Hours',
        data: data,
        color: '#FEF7D1',
        lineColor: '#F8D41C',
        marker: {
          fillColor: '#F8D41C'
        }
      },{
        showInLegend: newData.length ? true:false,
        name: 'Simulated Hours',
        data: newData,
        color: '#BAB9D3',
        lineColor: '#150F78',
        marker: {
          fillColor: '#150F78'
        }
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          text: 'Export',
          menuItems: [
            {
              text: 'Export as PNG',
              onclick: function () {
                this.exportChart();
              }
            },
            {
              text: 'Export as SVG',
              onclick: function () {
                this.exportChart({ type: 'image/svg+xml' });
              }
            },
            {
              text: 'Export as PDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' });
              }
            }
          ],
        }
      }
    }
  };
  return(
    <Fragment>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <Grid container spacing={1}>
        { Object.keys(lowInfo.shop_visits).map((label) =>
            simulationType === '1' || simulationType === '2'  ?
          <LabelValueCard xs={6} sm={6} md={3} label={`Life On Wing: ${lowInfo.shop_visits[label].name}`} value={`${Math.ceil(lowInfo.shop_visits[label].life_on_wings)} Hours`} />
          : simulationType === '3' ?
          <Grid item xs={6} sm={6} md={3}>
            <label className="simulate-label">Life On Wing: {lowInfo.shop_visits[label].name}</label>
            <h6>{Math.ceil(lowInfoSimulated.shop_visits[label].life_on_wings)} Hours</h6>
            {
              lowInfoSimulated.shop_visits[label].life_on_wings !== lowInfo.shop_visits[label].life_on_wings ?
              <h6 style={{textDecoration: 'line-through'}}>{Math.ceil(lowInfo.shop_visits[label].life_on_wings)} Hours</h6> : null
            }
          </Grid> : null

        )}
      </Grid>
    </Fragment>
  )
}
export default LOWGraph;

// <Grid item xs={6} sm={6} md={3}>
//   <label className="simulate-label">Life On Wing: {lowInfo.shop_visits[label].name}</label>
//   { simulationType !== '3' ? <h6>{lowInfo.shop_visits[label].life_on_wings} Hours</h6> : null }
//   { simulationType === '3' && lowInfoSimulated.shop_visits[label].life_on_wings !== lowInfo.shop_visits[label].life_on_wings ?
//     <Fragment>
//       <h6>{lowInfoSimulated.shop_visits[label].life_on_wings} Hours</h6>
//       <h6 style={{textDecoration: 'line-through'}}>{lowInfo.shop_visits[label].life_on_wings} Hours</h6>
//     </Fragment>
//     :null
//   }
// </Grid>
