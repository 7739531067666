
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const FAQsList = ({modal, faqsList, toggleModalFn, medialLink}) => {
  return(
    <Dialog
      open={modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        FAQs
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="faqs-list">
              <video width="320" height="240" controls autoPlay style={{width: '100%'}}>
                <source src={medialLink.link} type="video/mp4"/>
              </video>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="faqs-list">
              { faqsList.map((item, index) =>
                item.answer ?
                <ExpansionPanel className="working-groups-section" key={item.id}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id={`faq${item.id}`}
                  >
                  {item.question}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="content" dangerouslySetInnerHTML={{ __html: item.answer}}></div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                :<h2 className="title">{item.question}</h2>
              )}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={toggleModalFn}>Okay</Button>
      </DialogActions>
    </Dialog>
  )
}
export default FAQsList;
