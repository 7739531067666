import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { TableRow, TableCell, Chip, Tooltip } from '@material-ui/core';
import { displayDateFormatShort } from '../../../constants';
import DeleteIcon from '@material-ui/icons/Delete';
import mixpanel from 'mixpanel-browser';
import { getLocalStorageInfo } from '../../../utils';
import InfoIcon from '@material-ui/icons/Info';
import lockIcon from '../../../assets/images/NewLockIcon.png';
import unlockIcon from '../../../assets/images/unlockicon.png';
const Log = ({item, history, deleteLogFn,updateLockfn, props}) => {
  const redirectLog = () => {
    history.push(`/mr-calculator/intermediate/${item.id}`)
  }
  return(
    <TableRow hover tabIndex={-1}  style={{cursor:'pointer'}} >
       <TableCell onClick={(e) => redirectLog()} >{item.id}</TableCell>
       <TableCell onClick={(e) => redirectLog()}>{moment(item.updated_at).format(displayDateFormatShort)}</TableCell>
       <TableCell onClick={(e) => redirectLog()}>{item.created_by.name}</TableCell>
       <TableCell onClick={(e) => redirectLog()}>
         {item.region ?
           <div>
             { item.region_address !== undefined && item.region_address !== null && item.region_address.trim() !== '' ?
               <Tooltip title={item.region_address} >
                 <span style={{display: 'inline-block', wordBreak: 'break-word'}}>{item.region}</span>
               </Tooltip>:<span style={{display: 'inline-block', wordBreak: 'break-word'}}>{item.region}</span>
             }
           </div>
           :'--'}
       </TableCell>
       <TableCell onClick={(e) => redirectLog()}>{item.utilization_ratio ? item.utilization_ratio+':1':'--'}</TableCell>
       <TableCell style={{width:'300px'}} onClick={(e) => redirectLog()}>
         { item.is_airframe ?
           <Chip size="small" label={`Airframe: ${item.aircraft_type}`} style={{cursor:'pointer', margin: "3px"}} />:null
         }
         { item.is_engine ?
           <Chip size="small" label={`Engine: ${item.engine_type}`} style={{cursor:'pointer', margin: "3px"}} />:null
         }
         { item.is_apu ?
           <Chip size="small" label={`APU ${item.apu_type ? `:${item.apu_type.name}`:''}`} style={{cursor:'pointer', margin: "3px"}} />:null
         }
         { item.is_lg ?
           <Chip size="small" label="Landing Gear" style={{cursor:'pointer',margin: "3px"}}/>:null
         }
       </TableCell>
       <TableCell>
         {item.is_locked ?
           <Tooltip title="Click to resume editing." placement="top" arrow>
              <img src={lockIcon} width="30" onClick={() => updateLockfn(props, {log_id:item.id, is_locked:false }) } />
           </Tooltip>

           :
           <Tooltip title="Click to lock editing." placement="top" arrow>
             <img src={unlockIcon} width="35" onClick={() => updateLockfn(props, {log_id:item.id, is_locked:true }) } />
           </Tooltip>

         }
       </TableCell>
     </TableRow>
  )
}
export default withRouter(Log);
