import React, { Fragment, useState } from 'react';
import { Grid, TextField, Tooltip, InputAdornment, IconButton } from '@material-ui/core';
import { LabelValueCard, CountryStateSelector } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const EngineBasicInfo = ({simulationType, financeRptInfo, simulateFinanceRptInfo, onFieldChange, handleSelect, setRegionModal, onAddressChange, address,financeError, updateErrorEngineField}) => {
  return(
    <Grid container spacing={2}>
      <LabelValueCard xs={6} md={2} sm={6} label='Engine Type' value={financeRptInfo.engines.general_info.engine_type.name} />
      { simulationType === '1' ?
        <LabelValueCard xs={6} md={3} sm={6} label='Region Of Operation' value={
            financeRptInfo.location && financeRptInfo.location.address ? financeRptInfo.location.address.length > 20 ? <Tooltip title={financeRptInfo.location.address}><span>{`${financeRptInfo.location.address.substr(0, 20)}...`}</span></Tooltip>: financeRptInfo.location.address:
            financeRptInfo.engines.general_info.region.name
          }
        />
        :
        simulationType === '2' || simulationType === '3' ?
          <Grid item xs={6} md={3} sm={6}>
            <label style={{color: 'rgba(0, 0, 0, 0.54)', fontSize:'16px', paddingBottom:'5px'}}>Region of Operation</label>
            { simulationType === '2' ?
              window.google !== undefined && window.google !== null ?
              <PlacesAutocomplete
                value={address}
                onChange={onAddressChange}
                onSelect={(address) => {handleSelect(address); updateErrorEngineField('regionOfOperation')} }
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div style={{position:'relative'}}>
                    <input
                      {...getInputProps({
                        placeholder: 'Search Airports...',
                        className: 'location-search-input',
                        style: {padding:'7px', borderTop:'none', borderLeft:'none', borderRight:'none', borderBottom:'1px solid rgba(0, 0, 0, 0.87)', background:'transparent', width:'70%'}
                      })}
                    />
                  <div className="autocomplete-dropdown-container" style={{position:'absolute', left:'0px', top:'30px', zIndex:'999', width:'63%'}}>
                      {loading && <div>Loading...</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>:
              <div className="label-value-card">
                <h6>
                  {
                    financeRptInfo.location && financeRptInfo.location.address ? financeRptInfo.location.address.length > 20 ? <Tooltip title={financeRptInfo.location.address}><span>{`${financeRptInfo.location.address.substr(0, 20)}...`}</span></Tooltip>: financeRptInfo.location.address:
                    financeRptInfo.engines.general_info.region.name
                  }
                  <IconButton color="primary" onClick={() => setRegionModal(true)} component="span" size="small">
                    <EditIcon color="primary" />
                  </IconButton>
                </h6>
              </div>:null
            }
            { simulationType === '3' ?
              <Fragment>
                <div>
                  { financeRptInfo.location && financeRptInfo.location.address ?
                    <div>
                      { financeRptInfo.location.address ===  simulateFinanceRptInfo.location.address ?
                        <Tooltip title={financeRptInfo.location.address}><span>{ financeRptInfo.location.address.length > 20 ? financeRptInfo.location.address.substr(0,20)+'...': financeRptInfo.location.address}</span></Tooltip>:
                        <div>
                          <Tooltip title={simulateFinanceRptInfo.location.address}><span>{ simulateFinanceRptInfo.location.address.length > 20 ? simulateFinanceRptInfo.location.address.substr(0,20)+'...': simulateFinanceRptInfo.location.address}</span></Tooltip><br/>
                          <Tooltip title={financeRptInfo.location.address}><span style={{textDecoration:'line-through'}}>{ financeRptInfo.location.address.length > 20 ? financeRptInfo.location.address.substr(0,20)+'...': financeRptInfo.location.address}</span></Tooltip>

                        </div>
                      }
                    </div>:
                    <div>
                      { financeRptInfo.engines.general_info.region.name === simulateFinanceRptInfo.location.address ?
                        <Tooltip title={financeRptInfo.engines.general_info.region.name}><span>{ financeRptInfo.engines.general_info.region.name.length > 20 ? financeRptInfo.engines.general_info.region.name.substr(0,20)+'...': financeRptInfo.engines.general_info.region.name}</span></Tooltip>:
                        <div>
                          <Tooltip title={simulateFinanceRptInfo.location.address}><span>{ simulateFinanceRptInfo.location.address.length > 20 ? simulateFinanceRptInfo.location.address.substr(0,20)+'...': simulateFinanceRptInfo.location.address}</span></Tooltip><br/>
                          <Tooltip title={financeRptInfo.engines.general_info.region.name}><span style={{textDecoration:'line-through'}}>{ financeRptInfo.engines.general_info.region.name.length > 20 ? financeRptInfo.engines.general_info.region.name.substr(0,20)+'...': financeRptInfo.engines.general_info.region.name}</span></Tooltip>

                        </div>
                      }
                    </div>
                  }
                </div>
              </Fragment>:null
            }
            {financeError && financeError.regionOfOperation ?
              <h6 style={{color:'#f44336'}}>{financeError.regionOfOperation}</h6> : null
            }

          </Grid>
          :null
       }
      { simulationType === '1' ? <LabelValueCard xs={6} md={2} sm={6} label='Monthly Utilization in Hours' value={financeRptInfo.engines.general_info.hourly_utilization} />:null}
      { simulationType === '2' || simulationType === '3' ?
        <Grid item md={2} sm={6} xs={12}>
          { simulationType === '2' ?
            <TextField
              required
              id="hourly_utilization"
              label="Monthly Utilization in Hours"
              fullWidth
              margin="none"
              error={financeError && financeError.hourly_utilization ? true : false}
              helperText={financeError && financeError.hourly_utilization ? financeError.hourly_utilization : ''}
              value={financeRptInfo.engines.general_info.hourly_utilization}
              onFocus={() =>  {updateErrorEngineField('hourly_utilization'); updateErrorEngineField('utilization_ratio')}}
              InputLabelProps={{shrink: true}}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'hourly_utilization', e.target.value, 'engines', 'general_info'):e.preventDefault()}}
              InputProps={{
                endAdornment: <InputAdornment position="end">FH</InputAdornment>
              }}
            />:null
          }
          { simulationType === '3' ?
            <Fragment>
              <label className="simulate-label">Monthly Utilization in Hours</label><br/>
              { financeRptInfo.engines.general_info.hourly_utilization ===  simulateFinanceRptInfo.engines.general_info.hourly_utilization ?
                financeRptInfo.engines.general_info.hourly_utilization :
                <div className="differentiation-values">
                  <h6>{simulateFinanceRptInfo.engines.general_info.hourly_utilization}</h6>
                  <p>{financeRptInfo.engines.general_info.hourly_utilization}</p>
                </div>
              }
            </Fragment>:null
          }
        </Grid>:null
      }
      { simulationType === '1' ? <LabelValueCard xs={6} md={2} sm={6} label='Monthly Utilization in Cycle' value={financeRptInfo.engines.general_info.cycle_utilization} />:null}
      { simulationType === '2' || simulationType === '3'  ?
        <Grid item md={2} sm={6} xs={12}>
          { simulationType === '2' ?
            <TextField
              required
              id="cycle_utilization"
              label="Monthly Utilization in Cycle"
              fullWidth
              margin="none"
              error={financeError && financeError.cycle_utilization ? true : false}
              helperText={financeError && financeError.cycle_utilization ? financeError.cycle_utilization : ''}
              value={financeRptInfo.engines.general_info.cycle_utilization}
              onFocus={() => {updateErrorEngineField('cycle_utilization'); updateErrorEngineField('utilization_ratio')}}
              InputLabelProps={{shrink: true}}
              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cycle_utilization', e.target.value, 'engines', 'general_info'): e.preventDefault()}}
              InputProps={{
                endAdornment: <InputAdornment position="end">FC</InputAdornment>
              }}
            />:null
          }
          { simulationType === '3' ?
            <Fragment>
              <label className="simulate-label">Monthly Utilization in Cycles</label><br/>
              { financeRptInfo.engines.general_info.cycle_utilization ===  simulateFinanceRptInfo.engines.general_info.cycle_utilization ?
                financeRptInfo.engines.general_info.cycle_utilization :
                <div className="differentiation-values">
                  <h6>{simulateFinanceRptInfo.engines.general_info.cycle_utilization}</h6>
                  <p>{financeRptInfo.engines.general_info.cycle_utilization}</p>
                </div>
              }
            </Fragment>:null
          }
        </Grid>:null
      }
      {simulationType === '1' ?
        <LabelValueCard xs={6} md={2} sm={6} label='Utilization Ratio' value={`${financeRptInfo.engines.general_info.utilization_ratio}:1`} /> :null
      }
      {simulationType === '2' ?
        <Grid item xs={6} md={2} sm={6}>
          <TextField
            disabled
            id="utilization_ratio"
            label="Utilization Ratio"
            fullWidth
            margin="none"
            error={financeRptInfo.engines.general_info.utilization_ratio > financeRptInfo.engines.general_info.utilization_max_ratio || financeRptInfo.engines.general_info.utilization_ratio < financeRptInfo.engines.general_info.utilization_min_ratio ? true :false}
            helperText={financeRptInfo.engines.general_info.utilization_ratio > financeRptInfo.engines.general_info.utilization_max_ratio || financeRptInfo.engines.general_info.utilization_ratio < financeRptInfo.engines.general_info.utilization_min_ratio  ? `Utilization Ratio should be between ${financeRptInfo.engines.general_info.utilization_min_ratio}:1 and ${financeRptInfo.engines.general_info.utilization_max_ratio}:1` : '' }
            value={`${financeRptInfo.engines.general_info.utilization_ratio}:1`}
            InputLabelProps={{shrink: true}}
          />
        </Grid> : null
      }
      {
        simulationType === '3' ?
         financeRptInfo.engines.general_info.utilization_ratio !==  simulateFinanceRptInfo.engines.general_info.utilization_ratio  ?
        <Grid item xs={6} md={2} sm={6}>
          <label className="simulate-label">Utilization Ratio</label>
          <div className="label-value-card">
            <Fragment>
              <div className="differentiation-values">
                <h6>{`${simulateFinanceRptInfo.engines.general_info.utilization_ratio}:1`}</h6>
                <p>{`${financeRptInfo.engines.general_info.utilization_ratio}:1`}</p>
              </div>
            </Fragment>
          </div>
        </Grid>:<LabelValueCard xs={6} md={2} sm={6} label='Utilization Ratio' value={`${financeRptInfo.engines.general_info.utilization_ratio}:1`} />
      : null
      }
    </Grid>
  )
}
export default EngineBasicInfo;
