import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {getGlobalCookie, getLocalStorageInfo, authorizeAutoLogin, getLessorsList} from '../utils'
import { lessorUpdateApi } from '../application/auth/apiServices';
import config from '../config';
window.addEventListener('visibilitychange', checkLogin)
function checkLogin() {
  if(document.visibilityState || document.hasFocus){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let location = window.location.href
    let lessorAccess =JSON.parse(getGlobalCookie('lessorAccess'))
    if(!lessorAccess ){
        localStorage.clear();
        if(!location.includes('login')){
         // window.location.reload()
        }
      }else{
        if(lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment) ){
          if(!getLocalStorageInfo()){
             authorizeAutoLogin({lessor_id:lessorAccess.id})
           }else if(!location.includes('select-lessor') && !location.includes('otp')){
             if(lessorAccess.id !== userInfo.defaultLessor.id){
               authorizeAutoLogin({lessor_id:lessorAccess.id})
             }else{
               // do nothing
             }
           }
        }else {
          if(lessorAccess.access && userInfo && !userInfo.lessor_list){
            // getLessorsList()
          }
        }
      }
  }
}
export default function UserLogged(HocComponent){
  return class extends Component{
    constructor(props){
      super(props)
      this.lessorUpdateApi = lessorUpdateApi.bind(this)
    }
    componentDidMount(){
      checkLogin()
    }
    render(){
      const lessorAccess =  JSON.parse(getGlobalCookie('lessorAccess'))
      if(lessorAccess && lessorAccess.access && lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment) ){
        return(
          <Redirect to="/mr-calculator/logs" />
        )
      }else {
        return(
           <HocComponent {...this.props} />
        )
      }
    }
  }
}
