import React, { useState } from 'react';
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	toolbar: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	}
});

const CustomToolbar = function (props) {
	const { date, openView, setOpenView } = props;

	const handleChangeViewClick = (view) => (e) => {
		setOpenView(view);
	}
	const classes = useStyles();
	return (
		<PickerToolbar className={classes.toolbar}>
			<ToolbarButton
				onClick={handleChangeViewClick("year")}
				variant="h6"
				label={date.format("YYYY")}
				selected={openView === "year"}
			/>
			<ToolbarButton
				onClick={handleChangeViewClick("date")}
				variant="h4"
				selected={openView === "date"}
				label={date.format("MMMM DD")}
			/>
		</PickerToolbar>
	);

}

export default CustomToolbar;
